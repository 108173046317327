<template>
  <transition name="fade" mode="out-in" appear>
    <div class="standard-modal-wrapper">
      <div class="standard-modal-top-section">
        <span class="close-button">
          <BaseIcon
            :ref="'close-' + standardModalName"
            :width="16"
            :height="16"
            icon-file="close-white"
            class="close"
            @click="handleSlideDownModal"
          />
        </span>
      </div>
      <transition
        :name="slideUpTransition"
        mode="out-in"
        appear
        @after-leave="handleCloseModal"
      >
        <div v-if="showModal" class="standard-modal">
          <div
            v-if="showHeader"
            :class="[
              'header',
              { 'no-shadow': noShadow },
              { 'with-border': withBorder }
            ]"
          >
            <div v-if="title" class="title">{{ title }}</div>
          </div>
          <div
            v-if="actionTitle && showHeader"
            class="action-button"
            @click="actionFunction"
          >
            {{ actionTitle }}
          </div>
          <div class="standard-content" :style="{ height: contentHeight }">
            <slot name="content" />
          </div></div
      ></transition>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { OVERLAY_TYPES } from '../../store/modules/overlay';

export default {
  name: 'StandardModal',

  props: {
    closeFunction: {
      type: Function,
      default: () => {}
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: ''
    },

    noShadow: {
      type: Boolean,
      default: false
    },

    actionTitle: {
      type: String,
      default: ''
    },

    withBorder: {
      type: Boolean,
      default: false
    },

    actionFunction: {
      type: Function,
      default: () => {}
    },

    locked: {
      type: Boolean,
      default: false
    },

    escClose: {
      type: Boolean,
      default: false,
      required: false
    },

    closeOutside: {
      type: Boolean,
      default: true,
      required: false
    },

    contentHeight: {
      type: String,
      default: '100vh',
      required: false
    },

    standardModalName: {
      type: String,
      default: '',
      required: true
    },

    overlayType: {
      type: String,
      default: OVERLAY_TYPES.BLUR,
      required: false
    },

    slideUpTransition: {
      type: String,
      default: 'slide-up',
      required: false
    }
  },

  data() {
    return {
      OVERLAY_TYPES
    };
  },

  computed: {
    ...mapGetters({
      activeModals: 'ui/activeModals',
      currentModal: 'ui/currentModal',
      usedModals: 'ui/usedModals'
    }),

    showModal() {
      return this.activeModals.includes(this.standardModalName);
    },

    lastModal() {
      return this.activeModals[this.activeModals.length - 1];
    },

    allModalsClosed() {
      return this.activeModals.length === 0;
    }
  },

  created() {
    this.addUsedModals(this.standardModalName);
    this.addActiveModal(this.standardModalName);
    this.setCurrentModal(this.standardModalName);
  },

  mounted() {
    this.showOverlay({ show: true, type: this.overlayType });
    this.closeOutside && this.setUpOverlayCloseListener();
    this.addDisableScroll(this.standardModalName);
  },

  beforeMount() {
    if (this.escClose) {
      window.addEventListener('keyup', event => {
        this.onEscapeKeyUp(event);
      });
    }
  },

  beforeDestroy() {
    this.removeActiveModal(this.standardModalName);
  },

  destroyed() {
    this.removeDisableScroll(this.standardModalName);
    if (this.allModalsClosed) {
      this.hideOverlay();
      this.clearModalEvents();
      this.clearUsedModals();
    }

    if (this.escClose) {
      window.removeEventListener('keyup', event => {
        this.onEscapeKeyUp(event);
      });
    }
  },

  methods: {
    ...mapActions({
      showOverlay: 'overlay/show',
      hideOverlay: 'overlay/hide'
    }),

    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll',
      addActiveModal: 'ui/addActiveModal',
      removeActiveModal: 'ui/removeActiveModal',
      addUsedModals: 'ui/addUsedModals',
      setCurrentModal: 'ui/setCurrentModal',
      clearUsedModals: 'ui/clearUsedModals'
    }),

    closeCurrentModal() {
      this.$refs['close-' + this.lastModal]?.$emit('click');
    },

    clearModalEvents() {
      this.usedModals.forEach(modal => {
        this.$root.$off(modal);
        delete this.$root._events[modal];
      });
    },

    setUpOverlayCloseListener: function () {
      this.closeOutside &&
        this.$root.$on(this.currentModal, () => {
          !this.locked && this.removeActiveModal(this.lastModal);
        });
    },

    onEscapeKeyUp(event) {
      if (event.which === 27) {
        if (this.escClose && !this.locked) {
          this.closeCurrentModal();
        }
      }
    },

    handleSlideDownModal() {
      this.removeActiveModal(this.standardModalName);
    },

    handleCloseModal() {
      this.closeFunction();
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-modal-top-section {
  position: fixed;
  top: 0;
  right: 0;
  margin: 24px;
  z-index: $modal-z-index + 1;

  .close-button {
    cursor: pointer;
  }
}

.standard-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: $modal-z-index + 1;
}

.standard-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 90%;
  overflow-y: hidden;
  background-color: #fff;
  color: $text-primary;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: $modal-z-index + 1;

  @media ($desktop) {
    top: 50%;
    height: max-content;
    max-height: 740px;
    left: calc(50% - 350px);
    width: 700px;
    border-radius: 32px;
    transform: translateY(-50%);
    box-shadow: 0 -12px 24px 0 rgba(0, 0, 0, 0.1);
  }

  @media ($mobile) and (orientation: landscape) {
    position: fixed;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -0%);
    height: 100%;
    max-height: 100%;
  }

  // IE11 hack
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-y: scroll;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 60px;
  width: 100%;
  z-index: 1000;

  &.with-border {
    border-bottom: 1px solid #ddd;
  }

  .on-prop-page & {
    margin-bottom: 0;
  }

  @media ($desktop) {
    height: max-content;
    box-shadow: none;

    &.no-shadow {
      box-shadow: none;
    }
  }

  @media ($mobile) {
    height: max-content;

    &.no-shadow {
      box-shadow: none;
    }
  }
}

.title {
  position: relative;
  padding: 2rem;
  font-weight: 600;
  color: #0b182c;
  font-size: 1.5rem;
  line-height: 20px;
}

.standard-content {
  overflow-y: auto;
  max-height: 100vh;
  height: 100vh;
  outline: none;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0;
}

.action-button {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.176px;
  text-decoration-line: underline;
  margin: 0 0 12px 12px;
  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
}
</style>
