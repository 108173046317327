<template>
  <div class="full-swap-modal-content">
    <transition name="tut-slide-up" appear>
      <img
        src="../../assets/svg/teamwork.svg"
        alt="Team effort, powerful statement"
      />
    </transition>
    <transition name="bounce-tab" appear>
      <p class="modal-heading">{{ $t('full_swap_modal_title') }}</p>
    </transition>
    <div class="text-container">
      <p>
        {{ $t('full_swap_modal_body') }}
      </p>
    </div>
    <BaseButton
      icon-file="icon-chat-white"
      class="primary-action full-width rounded smaller"
      :icon-last="false"
      @click="handleClickCtaButton"
    >
      {{ $t('full_swap_modal_cta') }}</BaseButton
    >
    <div class="fireworks-container">
      <Fireworks />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Fireworks from './Fireworks.vue';

export default {
  name: 'FullSwapModalContent',

  components: {
    Fireworks
  },

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },

  methods: {
    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    handleClickCtaButton() {
      if (this.matchesViewABTest()) {
        this.$router.push(`${this.$t('path_matches', this.$routeLocale)}`);
      } else {
        this.$router.push(`${this.$t('path_swaps', this.$routeLocale)}`);
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.fireworks-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.full-swap-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  padding: 32px;

  @media ($desktop) {
    padding-bottom: 48px;
  }

  img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 256px;
    height: 256px;
    min-width: 200px;
    min-height: 200px;

    @media ($desktop) {
      margin-top: 20px;
      width: 269px;
      height: 269px;
    }
  }

  .modal-heading {
    font-size: 20px;
    font-weight: 600;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;

    p {
      margin: 0;
      line-height: 24px;
    }
  }

  .end-text {
    margin: 16px 0 20px 0;
    color: var(--RC-Text-2-485261, #485261);
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    @media ($desktop) {
      margin: 32px;
    }
  }

  & .button {
    font-size: 18px;
    line-height: 28px;
    max-width: 320px;
    min-width: max-content;
  }
}
</style>
