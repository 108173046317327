<template>
  <div class="accordion">
    <slot :is-open="isOpen"></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseAccordion',

  props: {
    saveLastOpen: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: []
    };
  },

  created() {
    this.$on('toggle', this.handleToggleItem);

    if (this.$route.query.accordion) {
      this.isOpen.push(this.$route.query.accordion);
    }
  },

  methods: {
    handleToggleItem(itemId) {
      if (this.isOpen.includes(itemId)) {
        this.isOpen = this.isOpen.filter(id => id !== itemId);
      } else {
        this.isOpen.push(itemId);
      }

      // add query param with lastest clicked accordion item
      if (this.saveLastOpen) {
        this.$router.replace({ query: { accordion: itemId } });
      }
    }
  }
};
</script>
