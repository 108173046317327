<template>
  <div class="help-page">
    <div class="page-header">
      <div class="page-container">
        <h1 class="page-title">{{ content.PageTitle }}</h1>
        <p class="lead" v-html="content.PageDescription"></p>
      </div>
    </div>

    <AccordionGroup
      v-for="(accordionGroup, index) in content.Accordion"
      :key="index"
      :content="accordionGroup"
      :accordion-id="index"
      :scroll-to-open-accordion="true"
    />

    <div v-if="content.BottomPanel" class="page-container">
      <div
        class="contact-customer-service editor-content"
        v-html="content.BottomPanel"
      ></div>
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';
import AccordionGroup from '@/components/ContentBlocks/AccordionGroup';

export default {
  name: 'Help',

  components: {
    TheFooter,
    AccordionGroup
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.PageTitle || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    window.scrollTo(0, 0);

    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/help?_locale=${this.$routeLocale}`
      );
      this.content = response.data;
      this.$emit('updateHead');
    } catch (error) {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
.help-page {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  img {
    width: 100%;
  }
}

.page-header {
  padding: 40px 0;
  background-color: #009cec;
  color: #fff;
  min-height: 218px;

  @media ($desktop) {
    padding: 50px 0;
  }
}

.page-title {
  margin-bottom: -10px;
  margin-top: 0;
  color: #fff;
}

.lead {
  max-width: 900px;
  font-weight: 600;
}

.contact-customer-service {
  padding: 20px;
  margin-top: 50px;
  background-color: #f1f2f6;
  border-radius: 10px;

  @media ($desktop) {
    padding: 30px 40px;
    margin-top: 60px;
  }
}

.contact-customer-service ::v-deep > *:not(hr) {
  max-width: 800px;
}
</style>
