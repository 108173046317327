<template>
  <div>
    <component
      :is="blockType(block.__component)"
      v-for="(block, index) in blocks"
      :key="index"
      :content="block"
    />
  </div>
</template>

<script>
import OneImage from '@/components/ContentBlocks/OneImage';
import TwoImages from '@/components/ContentBlocks/TwoImages';
import ThreeImages from '@/components/ContentBlocks/ThreeImages';
import Hero from '@/components/ContentBlocks/Hero';
import ImageAndText from '@/components/ContentBlocks/ImageAndText';
import TextBlock from '@/components/ContentBlocks/TextBlock';
import StatisticsBlock from '@/components/ContentBlocks/StatisticsBlock';
import HeadingsAndText from '@/components/ContentBlocks/HeadingsAndText';
import Reviews from '@/components/ContentBlocks/Reviews';
import Employees from '@/components/ContentBlocks/Employees';
import AccordionGroup from '@/components/ContentBlocks/AccordionGroup';
import Spacing from '@/components/ContentBlocks/Spacing';
import Propositions from '@/components/ContentBlocks/Propositions';
import StickyButton from '@/components/ContentBlocks/StickyButton';

export default {
  name: 'DynamicZone',

  components: {
    OneImage,
    TwoImages,
    ThreeImages,
    Hero,
    ImageAndText,
    TextBlock,
    StatisticsBlock,
    HeadingsAndText,
    Reviews,
    Employees,
    AccordionGroup,
    Spacing,
    Propositions,
    StickyButton
  },

  props: {
    blocks: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      content: {}
    };
  },

  methods: {
    blockType(type) {
      switch (type) {
        case 'general.image':
          return 'OneImage';
        case 'general.two-images':
          return 'TwoImages';
        case 'general.three-images':
          return 'ThreeImages';
        case 'general.hero':
          return 'Hero';
        case 'general.image-and-text':
          return 'ImageAndText';
        case 'general.richtext2':
          return 'TextBlock';
        case 'general.headings-and-text':
          return 'HeadingsAndText';
        case 'general.statistics':
          return 'StatisticsBlock';
        case 'general.user-reviews':
          return 'Reviews';
        case 'general.employees':
          return 'Employees';
        case 'general.accordion':
          return 'AccordionGroup';
        case 'general.spacing':
          return 'Spacing';
        case 'general.propositions':
          return 'Propositions';
        case 'general.sticky-button':
          return 'StickyButton';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
