<template>
  <div ref="propositionSwitcher" class="proposition-switcher-wrapper">
    <div class="proposition-switcher" @click.exact.stop="toggleSwitcher">
      <Spinner v-if="loading" />
      <div ref="propositionImg" class="proposition-image" />
      <div class="inner">
        <p class="text">
          {{ $t('proposition_switcher_uses_as') }}
          <span class="bold">{{ address }}</span>
        </p>
        <button class="switcher text bold">
          {{ $t('proposition_switcher_switch') }}
          <Icon
            icon="chevron"
            direction="down"
            color="$transfer-blue"
            :stroke-width="3"
          />
        </button>
      </div>
    </div>
    <PropositionList
      v-if="!isMobile && open && propositions"
      :close="closeList"
      :propositions="activePropositions"
      used-in="my-propositions-page"
    />
    <transition name="slide-up" mode="out-in">
      <StandardModal
        v-if="isMobile && open && propositions"
        standard-modal-name="PropositionSwitcherModal"
        :close-function="closeList"
        :title="$t('proposition_switcher_pick')"
      >
        <PropositionList
          slot="content"
          :close="closeList"
          :propositions="activePropositions"
        />
      </StandardModal>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from '../Icons/Icon';
import StandardModal from '@/components/Modals/Standard';
import PropositionList from './PropositionList';
import Spinner from '../Loading/Spinner';
import handleClickOutside from '../../utils/handleClickOutside';

export default {
  name: 'PropositionSwitcher',

  components: {
    Icon,
    StandardModal,
    PropositionList,
    Spinner
  },

  data() {
    return {
      open: false,
      address: ''
    };
  },

  computed: {
    ...mapGetters({
      propositions: 'myPropositions/propositions',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryProposition: 'myPropositions/primaryProposition',
      loading: 'propositionSwitcher/loading',
      isMobile: 'screenSize/isMobile'
    }),

    activePropositions: function () {
      if (!this.propositions) return [];
      const active = this.propositions.filter(p => p.active);

      return active;
    }
  },

  watch: {
    primaryPropositionId() {
      const proposition = this.propositions.find(
        p => p.propositionId.toString() === this.primaryPropositionId.toString()
      );

      this.setPropositionImage(proposition);
      this.getPropositionAddress(proposition);
    }
  },

  created() {
    this.setPropositionImage(this.primaryProposition);
    this.getPropositionAddress(this.primaryProposition);
  },

  beforeDestroy() {
    !this.isMobile &&
      document.removeEventListener('mouseup', this.closeOnClickOutside);
  },

  methods: {
    setPropositionImage(proposition) {
      if (!proposition) return;

      let { propositionImg } = this.$refs;

      if (!propositionImg) {
        this.$nextTick(() => {
          this.setPropositionImage(this.primaryPropositionId);
        });
        return;
      }

      if (
        !proposition.residences ||
        !proposition.residences[0] ||
        !proposition.residences[0].images
      ) {
        propositionImg.classList.add('no-image');
        return;
      }

      const fileName =
        proposition.residences[0].images.length > 0
          ? proposition.residences[0].images[0].fileName
          : null;

      const imgUrl = `${fileName}`;

      // handle empty image
      if (imgUrl === '' || imgUrl == 'null') {
        propositionImg.style.backgroundImage = '';

        propositionImg.classList.add('no-image');
        return;
      } else {
        propositionImg.classList.remove('no-image');
      }

      propositionImg.style.backgroundImage = `url(${imgUrl})`;
    },

    toggleSwitcher() {
      this.open ? this.closeList() : this.openList();
    },

    openList() {
      this.open = true;
      !this.isMobile &&
        document.addEventListener('mouseup', this.closeOnClickOutside);
    },

    closeList() {
      this.open = false;
      !this.isMobile &&
        document.removeEventListener('mouseup', this.closeOnClickOutside);
    },

    getPropositionAddress(proposition) {
      if (!proposition || !proposition.residences) return;
      this.address = `${proposition.residences[0].streetAddress}${
        proposition.residences[1] ? ', ' : ''
      }${
        proposition.residences[1] ? proposition.residences[1].streetAddress : ''
      }`;
    },

    closeOnClickOutside(e) {
      const { propositionSwitcher } = this.$refs;
      handleClickOutside({
        event: e,
        elements: [propositionSwitcher],
        action: this.closeList
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-switcher-wrapper {
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.proposition-switcher {
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  margin-bottom: 20px;
  padding: 14px;
  width: 100%;
  position: relative;

  @media ($mobile) {
    border-radius: 8px;
    padding: 10px;

    &-wrapper {
      @include content();
      order: 2;
    }
  }
}

.proposition-image {
  background-color: gray;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px;
}

.no-image {
  background-image: url('../../assets/svg/placeholderImage/placeholder-image-medium.svg');
  background-color: #f7f7f9;
  background-size: 80%;
}

.inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-around;
  padding-left: 10px;
  width: 100%; //IE
}

.text {
  margin: 0;
  padding: 0;
  width: 100%; //IE
  text-align: left; //IE
}

.switcher {
  background-color: white;
  border: none;
  color: $transfer-blue;
  cursor: pointer;
  outline: none;
  position: relative;
  white-space: nowrap;

  &:active {
    background-color: white;
  }
}

.bold {
  font-weight: 600;
}
</style>
