<template>
  <transition
    name="bounce-in-notification"
    mode="out-in"
    appear
    @after-leave="$emit('onAnimationEnd')"
  >
    <div
      v-if="shouldShowNotification"
      v-outside-click="handleOutsideClick"
      class="full-swap-notification"
    >
      {{ $t('full_swap_notification_label') }}
      <div class="fireworks-container">
        <FireworksSmall />
      </div>
    </div>
  </transition>
</template>

<script>
import FireworksSmall from './FireworksSmall';
import outsideClick from '@/directives/outside-click';

export default {
  name: 'FullSwapNotification',

  components: {
    FireworksSmall
  },

  directives: { outsideClick },

  data() {
    return {
      timeout: null,
      timeoutDuration: 6000,
      shouldShowNotification: true,
      timeoutClickOutside: null,
      durationUntilAllowClickOutside: 2000,
      shouldClickOutside: false
    };
  },

  mounted() {
    this.shouldShowNotification = true;
    this.shouldClickOutside = false;

    this.timeout = setTimeout(() => {
      this.shouldShowNotification = false;
    }, this.timeoutDuration);

    this.timeoutClickOutside = setTimeout(() => {
      this.shouldClickOutside = true;
    }, this.durationUntilAllowClickOutside);
  },

  destroyed() {
    this.clearTimeouts();
  },

  methods: {
    handleOutsideClick() {
      if (this.shouldClickOutside) {
        this.shouldShowNotification = false;
        this.clearTimeouts();
      }
    },

    clearTimeouts() {
      clearTimeout(this.timeout);
      clearTimeout(this.timeoutClickOutside);
    }
  }
};
</script>

<style lang="scss" scoped>
.full-swap-notification {
  display: flex;
  position: absolute;
  top: calc(-3.4rem + env(safe-area-inset-top));
  z-index: 100;
  padding: 8px 12px;
  border-radius: 8px;
  background: #409fff;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;

  &:before {
    content: '';
    position: absolute;
    top: 98%;
    left: 50%;
    margin-top: -0.5px;
    margin-left: -8px;
    border-width: 8px;
    border: 8px solid;
    border-color: #409fff transparent transparent transparent;
  }

  .fireworks-container {
    position: absolute;
    width: 100%;
    left: 20px;
    top: calc(10px + env(safe-area-inset-top));
  }
}
</style>
