<template>
  <img
    v-bind="$attrs"
    :src="url || (isPlaceholderSmall ? NO_IMAGE_SMALL : NO_IMAGE)"
    v-on="$listeners"
    :class="{ 'placeholder-img-small': !url && isPlaceholderSmall }"
  />
</template>

<script>
import { NO_IMAGE, NO_IMAGE_SMALL } from '@/utils/helpers/default-values';

export default {
  name: 'BaseImage',

  props: {
    url: {
      type: String,
      default: null
    },
    isPlaceholderSmall: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      NO_IMAGE,
      NO_IMAGE_SMALL
    };
  },

  watch: {
    url(newUrl, oldUrl) {
      if (newUrl !== oldUrl && newUrl !== null) {
        this.load();
      }
    }
  },

  mounted() {
    // With browser cache, the image will only be downloaded once
    this.load();
  },

  methods: {
    load() {
      this.$nextTick(() => {
        const image = new Image();

        image.onload = () => {
          // Notify that the image is now visible in dom
          this.$emit('done');
        };

        image.src = this.url;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.placeholder-img-small {
  background-color: #f7f7f9;
  object-fit: contain !important;
}
</style>
