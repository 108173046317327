<template>
  <div v-if="propositions.length > 0" class="block-container">
    <h1 class="block-title">
      {{ usedIn === 'seo-landing-page' ? customTitle : content.BlockTitle }}
    </h1>
    <div class="propositions">
      <router-link
        v-for="proposition in propositions"
        :key="proposition.ad_id"
        :to="$routes.PROPOSITION + '/' + proposition.ad_id"
        class="proposition"
      >
        <div class="proposition-image">
          <img v-if="proposition.filename" :src="proposition.filename" alt="" />
          <div v-else class="no-image"></div>
        </div>
        <div class="proposition-content">
          <h1 class="address">{{ proposition.streetAddress }}</h1>
          <h2 class="area">{{ proposition.areaName }}</h2>
          <div class="footer">
            <span class="rooms"
              >{{ proposition.num_rooms }} {{ $tc('general_rooms_short') }} ∙
            </span>
            <span class="sqm"
              >{{ proposition.square_meter }}
              {{ $t('general_sqm_short') }}</span
            >
            <div class="rent">
              <span class="price">{{ proposition.rent }}</span>
              {{ $t('general_currency_per_month_short') }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { lbApiBeta } from '../../utils/axiosConfig';

export default {
  name: 'Propositions',

  props: {
    content: {
      type: [Object, Array],
      default: () => {}
    },
    usedIn: {
      type: String,
      default: ''
    },
    areaName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      propositions: []
    };
  },

  computed: {
    customTitle() {
      return this.$t('sitemap_properties_in', { area: this.areaName });
    }
  },

  created() {
    if (this.usedIn === 'seo-landing-page') {
      this.propositions = this.content;
    } else {
      this.getPropositions();
    }
  },

  methods: {
    async getPropositions() {
      if (!this.content.AreaID) return;

      try {
        const result = await lbApiBeta.get(
          `/api/Inspiration/GetStartPageInspirationPictures?areaId=${this.content.AreaID}&count=8`
        );

        this.propositions = result.data.Object;
        return result.data;
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.block-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 16px;

  @media ($large-screen) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.block-title {
  font-size: 24px;
  margin-bottom: 10px;

  @media ($large-screen) {
    font-size: 30px;
    margin-bottom: 14px;
  }
}

.propositions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  font-family: $font-body;

  @media ($large-screen) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
  }
}

.proposition {
  margin-bottom: 10px;
  color: $text-primary;
}

.proposition-image {
  aspect-ratio: 16/9;
  border-radius: 6px;
  margin-bottom: 6px;
  overflow: hidden;

  @media ($large-screen) {
    border-radius: 8px;
    margin-bottom: 10px;
  }
}

.proposition-image img {
  width: 100%;
  object-fit: cover;
}

.proposition-image .no-image {
  background-color: #dddcdb;
  background-size: 50%;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
  height: 100%;
  background-position: 50%;
}

.address {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 1px;

  @media ($large-screen) {
    font-size: 17px;
    margin-bottom: 3px;
  }
}

.area {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  margin-bottom: 2px;
  margin: 0;

  @media ($large-screen) {
    font-size: 13px;
  }
}

.footer {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;

  @media ($large-screen) {
    font-size: 14px;
    margin-top: 6px;
  }
}

.price {
  font-weight: 600;
}
</style>
