import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const PREMIUM_MODAL_CONTENT_TYPES = {
  REVERSE_INTEREST: 'REVERSE_INTEREST',
  INTEREST_MARKS: 'INTEREST_MARKS',
  NO_INTEREST_MARKS: 'NO_INTEREST_MARKS'
};

const state = {
  showMobileHeader: true,
  showDesktopHeader: true,
  showGlobalModal: '',
  showCookiesModal: false,
  propositionViewModalId: null,
  propositionType: null,
  pageModalActivePage: 'proposition',
  pageModalZIndex: null,
  disableScroll: [],
  freemiumBannerTopMargin: '0px',
  activeModals: [],
  usedModals: [],
  currentModal: '',
  tabAnsweredClicks: 0
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
