var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap-header"},[(_vm.showFilters)?_c('div',{staticClass:"swap-list-info"},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header"},[(_vm.isSwapList)?_c('div',[_vm._v(" "+_vm._s(_vm.isUserList ? _vm.$t('swaplist_header_your_swap_suggestions') : _vm.address ? (_vm.address + " " + (_vm.$t('swaplist_header_swap_suggestions'))) : _vm.$t('swaplist_header_loading'))+" ")]):_c('div',{staticClass:"results-and-toggle"},[_c('div',{staticClass:"results-info"},[_c('div',{staticClass:"results-title"},[_vm._v(_vm._s(_vm.pageTitle))])])])]),(
          _vm.showFilters && _vm.swapFilterType !== _vm.SWAP_FILTER_TYPES.UNANSWERED_NEW
        )?_c('filter-container',{attrs:{"is-swap-list":_vm.isSwapList}}):_vm._e()],1),(_vm.showPropositionSwitcher & _vm.isSwapList)?_c('div',{staticClass:"desktop-proposition-switcher-wrapper"},[(_vm.isUserList)?_c('proposition-switcher'):_vm._e()],1):_vm._e(),_c('div',{staticClass:"center-section-wrapper"},[(_vm.loading)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_loading_swaps'))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ALL)?_c('div',{staticClass:"swap-list-count"},[(_vm.isUserList)?_c('span',[_vm._v(_vm._s(_vm.$t('swaplist_header_you_have'))+" ")]):_vm._e(),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.preFilterCountTotal))]),_vm._v(" "+_vm._s(_vm.$t('swaplist_header_possible_swaps'))+" "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.preFilterCountUnmarked))]),_vm._v(" "+_vm._s(_vm.$t('swaplist_header_unanswered'))+" "),(_vm.filterCount > 0)?_c('div',{staticClass:"swap-list-count small"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_after_filtering', { total: _vm.postFilterCountTotal }))+" ")]):_vm._e()]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.FULL_INTEREST)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_full_interest', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.NEW)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_last_24', { total: _vm.postFilterCountTotal }))+" ")]):(
          _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED &&
          _vm.distributedSwapsEnabled &&
          _vm.newSwapsCount > 0
        )?_c('div',{staticClass:"swap-list-count"},[(_vm.newSwapsCount === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_new_count_single_header')))]):_vm._e(),(_vm.newSwapsCount > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_new_count_header', { new: _vm.newSwapsCount }))+" ")]):_vm._e()]):(
          _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED ||
          _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ANSWERED ||
          _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.REMOVED
        )?_c('div',{staticClass:"swap-list-count"},[(_vm.totalFilterCount > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing'))+" "+_vm._s(_vm.totalFilterCount)+" "+_vm._s(_vm.$t('swaplist_header_unanswered_suggestions'))+" ")]):_vm._e()]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ONLY_FULL_INTEREST)?_c('div',{staticClass:"swap-list-count"},[[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_full_interest_only', { total: _vm.postFilterCountTotal }))+" ")]],2):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.LAST_PART)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_last_part', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.INTEREST_MARKED)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_interest_marked', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.SWAPLIST_MARKED)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_own_interest_marked', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED_NEW)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_unanswered', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.MARKED)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_marked', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.FAVOURITES)?_c('div',{staticClass:"swap-list-count"},[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_showing_favourites', { total: _vm.postFilterCountTotal }))+" ")]):_vm._e(),_c('toggle-button',{attrs:{"is-on":_vm.swapItemMode === _vm.SWAP_ITEM_TYPES.EXCEL,"on-toggle":_vm.swapItemMode === _vm.SWAP_ITEM_TYPES.CARDS
            ? function () { return _vm.toggleSwapItemMode({ mode: _vm.SWAP_ITEM_TYPES.EXCEL }); }
            : function () { return _vm.toggleSwapItemMode({ mode: _vm.SWAP_ITEM_TYPES.CARDS }); },"title":_vm.$t('swaplist_header_show_as_list')}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }