<template>
  <div class="image-count-container">
    <div class="image-count-indicator">
      {{ activeIndex + 1 }}
      <span style="color: rgba(240, 240, 240, 0.5)">· {{ imageCount }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCounter',

  props: {
    primaryProposition: {
      type: Object,
      default: () => {}
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    imageCount: {
      type: Number,
      default: 0
    }
  },

  computed: {
    primaryPropositionImages() {
      return this.primaryProposition.residences[0].images;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-count-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  right: 8px;
  .image-count-indicator {
    background-color: #2c3e50b7;
    color: #fff;
    font-size: 11px;
    line-height: 10px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
}
</style>
