<template>
  <div :class="['toggle-wrapper']">
    <button
      :disabled="isLoading"
      :class="['toggle-option', { 'selected-no': interested === false }]"
      @click.stop="() => attemptToggleInterest(false)"
    >
      <BaseIcon
        :icon-file="interested === false ? 'close-new-white' : 'close-new'"
        :width="20"
        :height="20"
        class="button-icon"
      />
      {{ $t('interest_toggle_no') }}
    </button>
    <button
      :disabled="isLoading"
      :class="[
        'toggle-option',
        {
          'selected-yes': interested === true,
          'background-fill-rl':
            !noInterestMarksLeft &&
            shouldAnimateYesButton &&
            interested === true
        }
      ]"
      @click.stop="() => attemptToggleInterest(true)"
    >
      <div class="button-content">
        <YesClickAnimation
          v-if="
            !noInterestMarksLeft &&
            shouldAnimateYesButton &&
            interested === true
          "
        />
        <BaseIcon
          :icon-file="interested === true ? 'icon-check3-white' : 'icon-check3'"
          :width="20"
          :height="20"
          class="button-icon"
        />
        {{ $t('interest_toggle_yes') }}
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TUTORIAL_STATUS_TYPES } from '../../store/modules/tutorial';
import YesClickAnimation from '@/components/SwapList/Gratification/YesClickAnimation';
import events from '@/utils/helpers/events';

export default {
  name: 'InterestToggle',

  components: {
    YesClickAnimation
  },

  props: {
    interested: {
      type: Boolean,
      default: null
    },
    toggleInterest: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tutHasMarkedInterest: false,
    shouldAnimateYesButton: false,
    TUTORIAL_STATUS_TYPES
  }),

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      isAuthenticated: 'app/isAuthenticated',
      tutStatus: 'tutorial/status',
      tutInterestMarks: 'tutorial/tutInterestMarks',
      noInterestMarksLeft: 'interests/noInterestMarksLeft',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },

    newPremiumPaywallABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  methods: {
    ...mapActions({
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    attemptToggleInterest(interest) {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (!this.isAuthenticated) {
        this.$emit('toggleConversionModal');
        return;
      }

      if (!this.primaryPropositionActive) {
        this.$router.push(
          `${this.$routes.PAYMENT_PROPOSITION}/${this.primaryPropositionId}`
        );
        return;
      }

      if (
        this.interested === false &&
        this.primaryPropositionIsLimited &&
        this.newPremiumPaywallABTest
      ) {
        this.setGlobalModal('ReverseInterestActionPaywall');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'reverse-no-interest-action',
          src: 'interest-mark'
        });
        return;
      }

      if (this.interested !== interest) {
        this.toggleInterest(interest);
      }
      if (!interest) {
        this.shouldAnimateYesButton = false;
      } else {
        this.shouldAnimateYesButton = !this.interested;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$interest-red: #ff3843;
$interest-green: #2ec973;

@keyframes background-fill {
  0% {
    background-position: -100% 0;
  }
  33%,
  100% {
    background-position: 0 0;
  }
}

.toggle-wrapper {
  display: flex;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

  .button-content {
    display: flex;
    position: relative;
    align-items: center;

    .yes-click-animation-wrapper {
      position: absolute;
      top: -24px;
      left: 1px;
      @media ($desktop) {
        top: -28px;
        left: 3px;
      }
    }
  }
}

.background-fill-rl {
  background-size: 200% 100%;
  background-image: linear-gradient(to left, white 50%, $transfer-green 50%);
  animation: background-fill 4s ease forwards;
}

.toggle-option {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background-color: #fff;
  color: $text-primary;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:first-of-type {
    border-radius: 5px 0px 0px 5px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #ccc;

    &.background-fill-rl {
      color: white;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: #435261;
  }
}

.selected-no {
  background-color: $interest-red;
  border-color: $interest-red !important;
  color: #fff;
}

.selected-yes {
  // background-color: $interest-green;
  // border-color: $interest-green !important;
  // color: #fff;

  background-image: linear-gradient(
    to left,
    $transfer-green 50%,
    $transfer-green 50%
  );
  border-color: $interest-green !important;
  color: white;
}

.button-icon {
  margin-right: 5px;
}
</style>
