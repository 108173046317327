<template>
  <div class="yes-click-animation-wrapper">
    <div class="inner" />
    <div class="dots-wrapper slide-up">
      <span class="dot" />
      <span class="dot1" />
      <span class="dot2" />
    </div>
    <span class="checkmark draw" />
  </div>
</template>

<script>
export default {
  name: 'YesClickAnimation',
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
$brand-success: #2ec973;
$size: 7em;
$check-height: calc($size / 3.2);
$check-width: calc($check-height / 3.2);
$check-top: -3px;
$check-left: -10px;
$check-thickness: 4px;
$check-color: $brand-success;

.yes-click-animation-wrapper {
  position: absolute;
  left: 0;
  height: 65px;
  width: 65px;

  .inner {
    z-index: 5000;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -22px;
    border-radius: 100%;
    opacity: 0.4;
    animation: pulse 0.8s ease-in;
    animation-delay: 0s;

    @media ($mobile) {
      top: 8px;
      left: -14px;
      width: 40px;
      height: 40px;
    }
  }

  .slide-up {
    animation: slide-up 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .dots-wrapper {
    position: relative;
    left: -35px;
    top: -10px;
    width: 100%;
    height: 40px;

    @media ($mobile) {
      left: -28px;
      top: -10px;
    }

    .dot {
      position: absolute;
      height: 8px;
      width: 8px;
      background-color: $brand-success;
      border-radius: 50%;
      display: inline-block;
      animation: grow 1.2s linear forwards;
      animation-delay: 0.2s;
      opacity: 0;
    }

    .dot1 {
      position: absolute;
      height: 8px;
      width: 8px;
      right: 4px;
      top: 4px;
      background-color: $brand-success;
      border-radius: 50%;
      display: inline-block;
      animation: grow 0.8s linear forwards;
      animation-delay: 0.3s;
      opacity: 0;
    }

    .dot2 {
      position: absolute;
      height: 7px;
      width: 7px;
      left: 12px;
      top: 4px;
      background-color: $brand-success;
      border-radius: 50%;
      display: inline-block;
      animation: grow 0.8s linear forwards;
      animation-delay: 0.2s;
      opacity: 0;
    }
  }

  .checkmark {
    &.draw:after {
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out both;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);

      @media ($desktop) {
        animation-duration: 1s;
      }
    }

    &:after {
      opacity: 0;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: 0.8px;
      top: $check-height + 0.35;
      position: absolute;
      visibility: visible;

      @media ($desktop) {
        left: 0.8px;
        top: $check-height + 0.6;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  75% {
    opacity: 1;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  90% {
    opacity: 1;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes dot {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  75% {
    opacity: 1;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  90% {
    opacity: 1;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.4);
    box-shadow: 0 0 0 0 $brand-success;
    background-color: #fff;
  }

  70% {
    opacity: 0.3;
    transform: scale(1.4);
    box-shadow: 0 0 0 10px $brand-success;
  }

  100% {
    opacity: 0;
    transform: scale(0.25);
    box-shadow: 0 0 0 0 $brand-success;
    background-color: transparent;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 0.5;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 0;
  }
}
</style>
