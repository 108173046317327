var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TabNavigation',{attrs:{"bg-color":_vm.tabBgColor}},[(_vm.showTab('unanswered'))?_c('TabNavigationItem',{attrs:{"active":_vm.activeTab === _vm.MATCH_STATUS_TYPES.UNANSWERED,"show-count":true,"count":_vm.matchStatusCounts.unanswered,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return _vm.setActiveTab({
        activeTab: _vm.MATCH_STATUS_TYPES.UNANSWERED,
        refetchStatusCounts: true
      })}}},[_vm._v(" "+_vm._s(_vm.$t('matches_nav_unanswered'))+" ")]):_vm._e(),(_vm.showTab('unread'))?_c('TabNavigationItem',{attrs:{"active":_vm.activeTab === _vm.MATCH_STATUS_TYPES.UNREAD,"show-count":true,"count":_vm.matchStatusCounts.unread,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return _vm.setActiveTab({
        activeTab: _vm.MATCH_STATUS_TYPES.UNREAD,
        refetchStatusCounts: true
      })}}},[_vm._v(" "+_vm._s(_vm.$t('matches_nav_unread'))+" ")]):_vm._e(),(_vm.showTab('updated'))?_c('TabNavigationItem',{attrs:{"active":_vm.activeTab === _vm.MATCH_STATUS_TYPES.UPDATED,"show-count":true,"count":_vm.matchStatusCounts.updated,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return _vm.setActiveTab({
        activeTab: _vm.MATCH_STATUS_TYPES.UPDATED,
        refetchStatusCounts: true
      })}}},[_vm._v(" "+_vm._s(_vm.$t('matches_nav_updated'))+" ")]):_vm._e(),_c('TabNavigationItem',{attrs:{"active":_vm.activeTab === _vm.MATCH_STATUS_TYPES.ACTIVE,"show-count":true,"count":_vm.matchStatusCounts.active,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return _vm.setActiveTab({
        activeTab: _vm.MATCH_STATUS_TYPES.ACTIVE,
        refetchStatusCounts: true
      })}}},[_vm._v(" "+_vm._s(_vm.$t('matches_nav_active'))+" ")]),_c('TabNavigationItem',{attrs:{"active":_vm.activeTab === _vm.MATCH_STATUS_TYPES.INACTIVE,"show-count":true,"count":_vm.matchStatusCounts.inactive,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return _vm.setActiveTab({
        activeTab: _vm.MATCH_STATUS_TYPES.INACTIVE,
        refetchStatusCounts: true
      })}}},[_vm._v(" "+_vm._s(_vm.$t('matches_nav_inactive'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }