var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-wrapper"},[(_vm.showConversionModal)?_c('BaseModal',{attrs:{"title":_vm.$t('proposition_conversion_modal_title')},on:{"close":_vm.toggleConversionModal}},[_c('ConversionModalContent')],1):_vm._e(),(_vm.displayMode === 'PLACEHOLDER')?_c('div',{class:[
      'image-area',
      _vm.displayMode === 'PLACEHOLDER' ? 'no-pictures' : ''
    ]},[_c('div',{staticClass:"gallery"},_vm._l(([1, 2, 3]),function(i){return _c('div',{key:i,staticClass:"gallery-placeholder"},[_c('div',{staticClass:"placeholder-image"})])}),0),_c('div',{staticClass:"large-image-area placeholder"},[_c('div',{staticClass:"placeholder-image large"}),_c('div',[_vm._v(_vm._s(_vm.$t('proposition_missing_image_text')))])])]):_c('div',{staticClass:"image-area",attrs:{"to":_vm.imageUrl},on:{"click":function($event){return _vm.handleImageClick()}}},[(_vm.galleryImages.length)?_c('div',{staticClass:"gallery"},_vm._l((_vm.galleryImages),function(img,index){return _c('div',{key:img.index,class:[
          'gallery-image-wrapper',
          { 'half-size': _vm.galleryImages.length <= 2 }
        ]},[_c('img',{staticClass:"gallery-image",attrs:{"src":img.fileName}}),(_vm.hasAccess && index === _vm.galleryImages.length - 1)?_c('div',{staticClass:"gallery-image-overlay"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('desktop_proposition_hero_show_more_images', { images: _vm.images.length }))+" ")])]):_vm._e(),(!_vm.hasAccess && index === _vm.galleryImages.length - 1)?_c('PropositionGalleryCTA'):_vm._e()],1)}),0):_vm._e(),(_vm.displayMode === 'DUO_STANDING')?_c('div',{key:_vm.propositionId,class:['large-image-area', { 'fill-width': !_vm.galleryImages.length }]},[(_vm.sortedMeasured[0])?_c('img',{staticClass:"standing",attrs:{"src":_vm.sortedMeasured[0].fileName}}):_vm._e(),(_vm.sortedMeasured[1])?_c('img',{staticClass:"standing",attrs:{"src":_vm.sortedMeasured[1].fileName}}):_vm._e()]):_vm._e(),(_vm.displayMode === 'SOLO_STANDING')?_c('div',{class:['large-image-area', { 'fill-width': !_vm.galleryImages.length }]},[_c('div',{staticClass:"standing-wrapper"},[_c('div',{staticClass:"blurred-background",style:([
            {
              backgroundImage: _vm.sortedMeasured[0]
                ? ("url(" + (_vm.sortedMeasured[0].fileName) + ")")
                : '',
              opacity: 0.2
            }
          ])}),(_vm.sortedMeasured[0])?_c('img',{staticClass:"standing sharp-edges",attrs:{"src":_vm.sortedMeasured[0].fileName}}):_vm._e()])]):_vm._e(),(_vm.displayMode === 'SOLO_LAYING')?_c('div',{class:['large-image-area', { 'fill-width': !_vm.galleryImages.length }]},[(_vm.sortedMeasured[0])?_c('img',{staticClass:"laying",attrs:{"src":_vm.sortedMeasured[0].fileName}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"map-area"},[(!_vm.coords)?_c('div',{staticClass:"map-loading-area block-pulse"}):(_vm.coords)?_c('MapAndStreetView',{attrs:{"coords":_vm.coords,"residence":_vm.residence}}):_c('div',{staticClass:"missing-map"},[_c('div',{staticClass:"missing-map-icon"}),_c('div',[_vm._v(_vm._s(_vm.$t('desktop_proposition_hero_missing_map_info')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }