<template>
  <div class="rich-content-page">
    <div class="page-container">
      <DynamicZone v-if="objectHasValue(content)" :blocks="content.Blocks" />
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';
import DynamicZone from '@/components/ContentBlocks/DynamicZone.vue';

export default {
  name: 'RichContentPage',

  components: {
    TheFooter,
    DynamicZone
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.Title || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    const slug = this.$route.params.slug;
    window.scrollTo(0, 0);

    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/rich-content-pages/${slug}?_locale=${this.$routeLocale}`
      );
      this.content = response.data;
      this.$emit('updateHead');
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    objectHasValue(obj) {
      return Object.keys(obj).length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.rich-content-page {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 79px;
    padding-bottom: 0;
  }
}

.page-container {
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
  }
}
</style>
