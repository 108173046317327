import Vue from 'vue';

function setUnseenNotificationsCount(state, unseenNotificationsCount) {
  state.unseenNotificationsCount = unseenNotificationsCount;
}

function setUnseenDetails(state, unseenDetails) {
  state.unseenDetails = unseenDetails;
}

function setNotifications(state, { notifications }) {
  Vue.set(state, 'notifications', notifications);
}

function setLoading(state, { loading }) {
  state.loading = loading;
}

function setFetchNotificationsFailed(state, success) {
  state.fetchNotificationsFailed = success;
}

export default {
  setFetchNotificationsFailed,
  setUnseenNotificationsCount,
  setUnseenDetails,
  setNotifications,
  setLoading
};
