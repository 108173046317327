<template>
  <div v-if="!isAuthenticated" class="menu-items">
    <MenuItem
      :href="$routes.HELP"
      icon-class="help"
      :title="$t('menu_items_link_5')"
    />
    <MenuItem
      :external="!$country.isFeatureEnabled('SEARCH')"
      :href="$routes.SEARCH"
      icon-class="search"
      :title="$t('menu_items_link_4')"
    />
    <MenuItem
      v-if="$country.isFeatureEnabled('SWAP_APPLICATION')"
      :external="true"
      :href="$routes.SWAP_APPLICATION"
      icon-class="contract"
      :title="$t('menu_items_link_7')"
    />
    <MenuItem
      :href="$routes.LOGIN"
      icon-class="login"
      :title="$t('menu_items_link_8')"
    />

    <div class="menu-bottom-wrapper">
      <div>
        <a :href="$routes.GENERAL_TERMS">{{ $t('menu_items_link_10') }}</a>
        <a :href="$routes.COOKIE_POLICY">{{ $t('menu_items_link_11') }}</a>
      </div>
    </div>
  </div>

  <div v-else class="menu-items">
    <MenuItem
      v-if="matchesViewABTest"
      :href="$routes.MESSAGES"
      icon-class="messages"
      :title="$t('tab_bar_other_messages')"
      :badge="notifications.unreadMessages"
    />
    <MenuItem
      v-if="$country.isFeatureEnabled('PAYMENT') && !matchesViewABTest"
      :href="$routes.MY_PROPOSITIONS"
      icon-class="realEstate"
      :title="$t('tab_bar_my_ads')"
    />
    <MenuItem
      :href="$routes.EDIT_PROFILE"
      icon-class="edit"
      :title="$t('popup_menu_item_2')"
    />
    <MenuItem
      :href="$routes.HELP"
      icon-class="help"
      :title="$t('popup_menu_item_7')"
    />
    <MenuItem
      v-if="matchesViewABTest"
      :href="$routes.FAVOURITES"
      icon-class="heart"
      :title="$t('popup_menu_item_4')"
    />
    <MenuItem
      :href="$routes.SEARCH"
      icon-class="search"
      :title="$t('popup_menu_item_5')"
    />
    <MenuItem
      :href="$routes.NEWS"
      icon-class="lightbulb"
      :title="$t('the_footer_news')"
    />
    <MenuItem
      v-if="$country.isFeatureEnabled('SWAP_APPLICATION')"
      :external="true"
      :href="$routes.SWAP_APPLICATION"
      icon-class="contract"
      :title="$t('popup_menu_item_6')"
    />
    <MenuItem
      :href="$routes.LOGOUT"
      icon-class="logout"
      :title="$t('popup_menu_item_8')"
    />
    <ImpersonateBanner v-if="isImpersonated && isMobile"></ImpersonateBanner>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuItem from './MenuItem';
import ImpersonateBanner from '@/components/Banners/ImpersonateBanner.vue';

export default {
  name: 'MenuItems',
  components: {
    MenuItem,
    ImpersonateBanner
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isAuthenticated: 'app/isAuthenticated',
      isImpersonated: 'app/isImpersonated',
      notifications: 'menu/getNotifications'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-items {
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 15px 0;
  width: 100%;
  overflow-y: auto;
}

.menu-bottom-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-bottom-wrapper > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  border-top: 1px solid #eee;
  font-weight: 600;
  font-size: 12px;
}

.menu-bottom-wrapper > div > a {
  text-decoration: none;
  color: #2c3e50;
}
</style>
