<template>
  <div class="image-block">
    <img :src="image" alt="" />
  </div>
</template>

<script>
export default {
  name: 'OneImage',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    image() {
      return this.content.Image.formats?.large?.url || this.content.Image.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-block {
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  @media ($large-screen) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
  }
}
</style>
