<template>
  <StandardModal
    standard-modal-name="ReportModal"
    :show-header="false"
    :esc-close="true"
    content-height="max-content"
    @close="handleCloseModal"
  >
    <ReportScreen
      slot="content"
      :screen-info="currentScreenInfo"
      @next="handleClickNext($event)"
      @back="handleClickBack($event)"
      @send="handleClickSend($event)"
      @done="handleClickDone($event)"
    />
  </StandardModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ReportScreen from '@/components/Proposition/Report/Screens/Screen';
import StandardModal from '@/components/Modals/Standard';
import { constants } from '@/plugins/country/module';
import { getCountry, getCountryUpperCase } from '@/plugins/country/module';

export default {
  name: 'ReportModalWrapper',

  components: {
    ReportScreen,
    StandardModal
  },

  data() {
    return {
      visitedScreens: [],
      direction: '',
      shouldResetSelectedOnClose: true,
      takenPathWay: [],
      initialSelection: '',
      returnToSwaplistOnClose: false,
      pathwayLabelLang: 'sv'
    };
  },

  computed: {
    ...mapGetters({
      getScreenInfo: 'reportProposition/getScreenInfo',
      currentScreen: 'reportProposition/currentScreen',
      getOptionState: 'reportProposition/getOptionState',
      SCREENS: 'reportProposition/SCREENS',
      allowContact: 'reportProposition/allowContact',
      blockProposition: 'reportProposition/blockProposition',
      textareaReportDescription: 'reportProposition/textareaReportDescription',
      currentProposition: 'propositions/currentProposition',
      user: 'app/user'
    }),
    selectedRadioOption() {
      return this.getOptionState(this.currentScreen)?.selected;
    },
    currentScreenInfo() {
      return this.getScreenInfo(this.currentScreen);
    }
  },

  watch: {
    selectedRadioOption(current) {
      if (this.initialSelection === '') {
        this.initialSelection = current;
      }
      // clear textarea if user changes selection
      if (current && this.initialSelection !== current) {
        this.initialSelection = current;
        this.setTextareaReportDescription('');
      }
    },
    currentScreen(_current, previous) {
      if (this.direction === 'forwards') {
        this.visitedScreens.push(previous);
        this.setPreviousScreen(previous);

        // record the users pathway
        const pathWayData = {
          step: previous,
          label: this.$t(this.SCREENS[previous].label, this.pathwayLabelLang),
          type: this.SCREENS[previous].type
        };

        const selectedOption = this.getOptionState(previous)?.selected;
        if (selectedOption) {
          pathWayData.selectedOption = selectedOption;
          pathWayData.optionLabel = this.$t(
            this.SCREENS[previous].options.find(
              option => option.id === selectedOption
            ).label,
            this.pathwayLabelLang
          );
          this.takenPathWay.push(Object.assign({}, pathWayData));
        }
      } else {
        this.setPreviousScreen(this.visitedScreens[0]);
        this.takenPathWay.pop();
      }
    }
  },

  async mounted() {
    this.setCurrentScreen('1.0');
  },

  destroyed() {
    // reset selected if modal closes
    this.shouldResetSelectedOnClose && this.resetSelected();
  },

  methods: {
    ...mapActions({
      setCurrentScreen: 'reportProposition/setCurrentScreen',
      setPreviousScreen: 'reportProposition/setPreviousScreen',
      setTextareaReportDescription:
        'reportProposition/setTextareaReportDescription',
      resetSelected: 'reportProposition/resetSelected',
      sendReportDataToSlack: 'reportProposition/sendReportDataToSlack'
    }),
    handleCloseModal() {
      this.$emit('close');
    },

    handleClickNext(event) {
      this.setCurrentScreen(`${parseFloat(this.currentScreen)}.${event.value}`);
      this.direction = 'forwards';
    },
    handleClickBack(event) {
      this.setCurrentScreen(event.value);
      this.direction = 'backwards';
    },
    async handleClickSend() {
      this.setCurrentScreen('success');

      this.$nextTick(async () => {
        const saveData = {
          takenPathWay: this.takenPathWay,
          allowContact: this.allowContact,
          reportDescription: this.textareaReportDescription ?? ''
        };

        const country = getCountry();

        // fetch domain by country
        const domain = constants.KEY_VALUES.DOMAIN[country];
        const localePathToProposition = `https://${domain}${this.$t(
          'path_swap_list',
          this.$routeLocale
        )}?propositionPageId=${this.currentProposition.propositionId}`;

        const localePathToUser = `${this.$t(
          'url_admin2_api',
          'sv'
        )}/${getCountryUpperCase()}/user/${this.user.userId}`;

        await this.sendReportDataToSlack({
          userId: this.user.userId,
          route: this.$router.currentRoute.path,
          propositionUrl: localePathToProposition,
          userAdminUrl: localePathToUser,
          locale: country,
          propositionId: this.currentProposition.propositionId,
          saveData
        });
      });
    },
    handleClickDone() {
      this.$emit('close');
      if (this.returnToSwaplistOnClose) {
        this.$router.push(`${this.$t('path_swap_list', this.$routeLocale)}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
