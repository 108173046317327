var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-swap-header"},[_c('div',{class:_vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.STARTED &&
      _vm.tutActiveStep === _vm.TUTORIAL_STEPS_TYPES.FIRST_INTEREST_MODAL
        ? 'animate-slide-down-height'
        : ''},[_c('InfoBanner',{staticClass:"info-banner"}),(_vm.isUserList && _vm.showPropositionSwitcher)?_c('PropositionSwitcher'):_vm._e(),(
        _vm.shouldShowOnboardingBanner &&
        _vm.tutStatus !== _vm.TUTORIAL_STATUS_TYPES.STARTED &&
        _vm.accountType !== 'trial'
      )?_c('div',{staticClass:"onboarding-wrapper",class:{
        'onboarding-fade-grow-animation':
          _vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.COMPLETED
      }},[_c('OnboardingBanner')],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }