<template>
  <div class="filter-modal-wrapper">
    <StandardModal
      v-if="isMobile"
      standard-modal-name="FilterModal"
      content-height="max-content"
      :esc-close="true"
      :show-header="true"
      :action-function="handleClearFilters"
      :title="$t('swap_list_filter_title')"
      :action-title="showActionTitle ? $t('filter_modal_clear') : null"
      @close="handleApplyFilters"
    >
      <Filters slot="content" @apply-filters="handleApplyFilters" />
    </StandardModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StandardModal from '@/components/Modals/Standard';
import Filters from '@/components/SwapList/Filter/Filters';

export default {
  name: 'FilterModalWrapper',

  components: {
    StandardModal,
    Filters
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      selectedFilters: 'swapList/selectedFilters'
    }),
    showActionTitle() {
      return this.selectedFilters.length > 0;
    }
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal',
      resetFilters: 'swapList/resetFilters',
      getSwaps: 'swapList/getSwaps',
      clearSelectedFilters: 'swapList/clearSelectedFilters'
    }),

    handleApplyFilters() {
      if (this.selectedFilters.length > 0) {
        this.$root.$emit('apply-filters-map');
        this.getSwaps({ propositionId: this.currentPropositionId });
      }
      this.setGlobalModal(false);
    },

    handleClearFilters() {
      this.clearSelectedFilters();
      this.resetFilters({ onPageLoad: true });
      this.$root.$emit('apply-filters-map');
      this.getSwaps({ propositionId: this.currentPropositionId });
    }
  }
};
</script>

<style lang="scss" scoped></style>
