<template>
  <div class="seo-landing-page">
    <div v-if="objectHasValue(content)" class="page-container">
      <Hero :content="content.Hero" />
      <Propositions
        :content="propositions"
        :area-name="areaName"
        :used-in="'seo-landing-page'"
      />
      <OneImage :content="content.Image" />
      <TextBlock :content="content.RichText" />
      <StickyButton :content="content.StickyButton" />
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';
import OneImage from '@/components/ContentBlocks/OneImage';
import Hero from '@/components/ContentBlocks/Hero';
import TextBlock from '@/components/ContentBlocks/TextBlock';
import Propositions from '@/components/ContentBlocks/Propositions';
import StickyButton from '@/components/ContentBlocks/StickyButton';

export default {
  name: 'SeoLandingPage',

  components: {
    TheFooter,
    OneImage,
    Hero,
    TextBlock,
    Propositions,
    StickyButton
  },

  props: {
    replacements: {
      type: Object,
      required: false,
      default: null
    },
    propositions: {
      type: Array,
      default: () => []
    },
    areaName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.Title || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    window.scrollTo(0, 0);

    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/seo-landing-page?_locale=${this.$routeLocale}`
      );

      let content = JSON.stringify(response.data);
      if (this.replacements) {
        for (const key of Object.keys(this.replacements)) {
          content = content.replace(
            new RegExp(key, 'g'),
            this.replacements[key]
          );
        }
      }
      this.content = JSON.parse(content);
      this.$emit('updateHead');
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    objectHasValue(obj) {
      return Object.keys(obj).length > 0;
    }
  }
};
</script>

<style lang="scss" scoped></style>
