<template>
  <div class="sort-options">
    <div
      v-for="sortOption in Object.keys(ACTIVE_SORT_TYPES)"
      :key="sortOption"
      class="sort-option"
    >
      <BaseRadioButtonNew
        :reverse="true"
        :value="sortOption"
        :selected="sortType"
        :padding-y="isMobile ? 2 : 4"
        :disabled="false"
        @change="$emit('change', sortOption)"
        >{{ getReadableSortType(sortOption) }}
        <div v-if="showDescription" class="sort-description">
          {{ getDescription(sortOption) }}
        </div></BaseRadioButtonNew
      >
    </div>
    <div class="action-button-wrapper">
      <BaseButton
        :disabled="!isSelectedOption"
        type="button"
        class="full-width grey larger rounded"
        style="width: 100%"
        @click="$emit('submit', sortType)"
      >
        {{ $t('filter_modal_action') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SORT_TYPES,
  ACTIVE_SORT_TYPES,
  SORT_DIRECTIONS
} from '../../store/modules/swapList';
import BaseRadioButtonNew from '@/components/BaseRadioButtonNew.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'SortOptions',

  components: {
    BaseRadioButtonNew,
    BaseButton
  },

  props: {
    showDescription: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      SORT_TYPES: SORT_TYPES,
      ACTIVE_SORT_TYPES: ACTIVE_SORT_TYPES
    };
  },

  computed: {
    ...mapGetters({
      sortType: 'swapList/sortType',
      sortDirection: 'swapList/sortDirection',
      isMobile: 'screenSize/isMobile'
    }),

    isSelectedOption() {
      return Object.keys(ACTIVE_SORT_TYPES).includes(this.sortType);
    },

    readableDirectionType() {
      return this.sortDirection === SORT_DIRECTIONS.DESCENDING
        ? this.$t('sort_options_descending')
        : this.$t('sort_options_ascending');
    }
  },

  methods: {
    getReadableSortType(sortType) {
      switch (sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('filter_container_type_score');
        case SORT_TYPES.SQM_DESC:
          return this.$t('sort_options_type_sqm_desc');
        case SORT_TYPES.SQM_ASC:
          return this.$t('sort_options_type_sqm_asc');
        case SORT_TYPES.FLOOR:
          return this.$t('sort_options_type_floor');
        case SORT_TYPES.ROOMS_DESC:
          return this.$t('sort_options_type_num_of_rooms_desc');
        case SORT_TYPES.ROOMS_ASC:
          return this.$t('sort_options_type_num_of_rooms_asc');
        case SORT_TYPES.RENT_DESC:
          return this.$t('sort_options_type_rent_desc');
        case SORT_TYPES.RENT_ASC:
          return this.$t('sort_options_type_rent_asc');
        case SORT_TYPES.POSTAL_AREA:
          return this.$t('sort_options_type_postal_area');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('sort_options_type_interest');
        case SORT_TYPES.SEEN_AT:
          return this.$t('sort_options_type_match_date');
        case SORT_TYPES.ADDRESS:
          return this.$t('sort_options_type_address');
        case SORT_TYPES.TYPE:
          return this.$t('sort_options_type_swaptype');
        // case SORT_TYPES.INTEREST_DATE:
        //   return this.$t('sort_options_type_interest_date');
        default:
          return '';
      }
    },

    getDescription(sortType) {
      switch (sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('sort_options_desc_score');
        case SORT_TYPES.SQM:
          return this.$t('sort_options_desc_sqm');
        case SORT_TYPES.FLOOR:
          return this.$t('sort_options_desc_floor');
        case SORT_TYPES.ROOMS:
          return this.$t('sort_options_desc_num_of_rooms');
        case SORT_TYPES.RENT:
          return this.$t('sort_options_desc_rent');
        case SORT_TYPES.POSTAL_AREA:
          return this.$t('sort_options_desc_postal_area');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('sort_options_desc_interest');
        case SORT_TYPES.SEEN_AT:
          return this.$t('sort_options_desc_match_date');
        case SORT_TYPES.ADDRESS:
          return this.$t('sort_options_desc_address');
        case SORT_TYPES.TYPE:
          return this.$t('sort_options_desc_swaptype');
        // case SORT_TYPES.INTEREST_DATE:
        //   return this.$t('sort_options_desc_interest_date');
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-options {
  padding: 0 0.5rem 0 0.5rem;
  min-height: 400px;

  @media ($desktop) {
    min-height: 400px;
    padding: 0 1rem 0 1rem;
  }

  .action-button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    border-top: 1px solid #ddd;
    & button {
      font-size: 16px;
    }
  }

  .sort-option {
    padding: 6px 16px;
    cursor: pointer;
    user-select: none;
  }

  .title-wrapper {
    display: flex;
  }

  .direction-box {
    padding: 18px 8px;
    text-align: center;
    background-color: $info-gray;

    font-weight: 600;
    font-size: 1.1rem;
  }

  .direction-label {
    color: $transfer-blue;
    text-decoration: underline;
    position: relative;
    margin-right: 16px;
    font-weight: 700;
    cursor: pointer;

    &::after {
      position: absolute;
      right: -14px;
      top: 7px;
      content: '';
      background-image: url('../../assets/svg/shift-blue.svg');
      background-position: center;
      background-size: contain;
      transform: rotate(90deg);
      height: 12px;
      width: 12px;
    }
  }

  .sort-radio {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sort-radio-selected {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: $transfer-blue;
  }

  .sort-title {
    font-weight: 700;
    font-size: 1rem;
  }

  .sort-description {
    font-weight: 600;
    font-size: 0.9rem;
    padding-left: 28px;
  }
}
</style>
