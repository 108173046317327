<template>
  <div class="edit-proposition-wish-container">
    <div class="edit-proposition-wish">
      <BaseBackButton :link="editPropositionPath" class="back-button" />
      <h1 v-if="proposition" class="page-heading">
        {{ $t('edit_wish_title') }}
        {{ proposition.residences[0].streetAddress }}
        <template v-if="proposition.residences[1]">
          & {{ proposition.residences[1].streetAddress }}
        </template>
      </h1>
      <div class="sub-heading">{{ $t('wish_subtitle') }}</div>

      <template v-if="propositionWishes">
        <div class="form-group">
          <BaseInput
            v-model.number="propositionWishes.minRooms"
            :valid="!showErrors || isNumeric(propositionWishes.minRooms)"
            name="minRooms"
            type="number"
            :label="$t('edit_wish_min_rooms_label')"
            :placeholder="$t('edit_wish_min_rooms_label')"
            :suffix="$tc('general_rooms', 1)"
            class="form-group-item"
          />

          <BaseInput
            v-model.number="propositionWishes.minSqm"
            :valid="!showErrors || isNumeric(propositionWishes.minSqm)"
            name="minSqm"
            type="number"
            :label="$t('edit_wish_min_sqm_label')"
            :placeholder="$t('edit_wish_min_sqm_label')"
            :suffix="$t('general_sqm_short')"
            class="form-group-item"
          />

          <BaseInput
            v-model.number="propositionWishes.maxRent"
            :valid="!showErrors || isNumeric(propositionWishes.maxRent)"
            name="maxRent"
            type="number"
            :label="$t('edit_wish_max_rent')"
            :placeholder="$t('edit_wish_max_rent')"
            :suffix="$t('general_currency_per_month_short')"
            class="form-group-item"
          />
        </div>

        <div class="checkbox-list checkbox-list-first">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_wish_require_title') }}
          </h4>
          <p class="checkbox-list-text">{{ $t('edit_wish_require_text') }}</p>

          <BaseCheckbox
            :label="$t('edit_wish_balcony')"
            :value="propositionWishes.balcony"
            :selected="propositionWishes.balcony"
            class="checkbox"
            @change="propositionWishes.balcony = !propositionWishes.balcony"
          />

          <BaseCheckbox
            :label="$t('edit_wish_elevator')"
            :value="propositionWishes.elevator"
            :selected="propositionWishes.elevator"
            class="checkbox"
            @change="propositionWishes.elevator = !propositionWishes.elevator"
          />

          <BaseCheckbox
            :label="$t('edit_wish_not_bottomfloor')"
            :value="propositionWishes.noBottomFloor"
            :selected="propositionWishes.noBottomFloor"
            class="checkbox"
            @change="
              propositionWishes.noBottomFloor = !propositionWishes.noBottomFloor
            "
          />

          <template v-if="$country.isCountry('se')">
            <BaseCheckbox
              :label="$t('edit_wish_becoming_coop')"
              :value="propositionWishes.becomingCooperative"
              :selected="propositionWishes.becomingCooperative"
              class="checkbox"
              @change="
                propositionWishes.becomingCooperative =
                  !propositionWishes.becomingCooperative
              "
            />
          </template>
        </div>

        <div class="checkbox-list">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_wish_wish_title') }}
          </h4>
          <p class="checkbox-list-text" v-html="$t('edit_wish_wish_text')"></p>
          <BaseCheckbox
            :label="$t('edit_wish_fireplace')"
            :value="propositionWishes.fireplace"
            :selected="propositionWishes.fireplace"
            class="checkbox"
            @change="propositionWishes.fireplace = !propositionWishes.fireplace"
          />

          <BaseCheckbox
            :label="$t('edit_wish_bathtub')"
            :value="propositionWishes.bathtub"
            :selected="propositionWishes.bathtub"
            class="checkbox"
            @change="propositionWishes.bathtub = !propositionWishes.bathtub"
          />

          <template v-if="$country.isCountry('se')">
            <BaseCheckbox
              :label="$t('edit_wish_formed_coop')"
              :value="propositionWishes.formedCooperative"
              :selected="propositionWishes.formedCooperative"
              class="checkbox"
              @change="
                propositionWishes.formedCooperative =
                  !propositionWishes.formedCooperative
              "
            />
          </template>
        </div>

        <div class="button-container">
          <BaseButton @click="handleSaveClick">
            <template v-if="isSaving">{{
              $t('edit_user_credentials_loading')
            }}</template>
            <template v-else>{{
              $t('edit_user_credentials_save')
            }}</template></BaseButton
          >
          <br />
          <button
            v-if="
              proposition.wishes.length === 2 && proposition.swapType !== 12
            "
            class="remove-button"
            @click="showRemoveModal = true"
          >
            {{ $t('edit_wish_remove_this_wish') }}
          </button>
        </div>
      </template>
    </div>

    <BaseModal
      v-if="showRemoveModal"
      :title="$t('edit_wish_remove_wish')"
      @close="showRemoveModal = false"
    >
      <div class="remove-modal-content">
        <BaseButton class="warning" @click="handleRemoveWishClick">
          {{ $t('edit_photos_modal_remove') }}
        </BaseButton>
        <BaseButton class="grey outline" @click="showRemoveModal = false">
          {{ $t('edit_photos_modal_close') }}
        </BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';

export default {
  name: 'EditPropositionWish',

  data() {
    return {
      propositionId: null,
      wishIndex: null,
      proposition: null,
      showErrors: false,
      isSaving: false,
      propositionWishes: null,
      showRemoveModal: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('edit_wish_meta_title') || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    },

    editAreasPath() {
      return (
        this.$routes.EDIT_WISH_AREAS +
        '/' +
        this.propositionId +
        '/' +
        this.wishIndex
      );
    },

    currentWish() {
      return this.proposition.wishes[this.wishIndex - 1];
    },

    isValid() {
      return (
        this.isNumeric(this.propositionWishes.maxRent) &&
        this.isNumeric(this.propositionWishes.minRooms) &&
        this.isNumeric(this.propositionWishes.minSqm)
      );
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;
    this.wishIndex = this.$route.params.wishIndex;

    window.scrollTo(0, 0);

    await this.getProposition();

    if (this.wishIndex === 'new') {
      this.createSecondWish();
    } else {
      this.getWishes();
    }
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );
    },

    createSecondWish() {
      this.propositionWishes = {
        maxRent: null,
        minRooms: null,
        minSqm: null,
        noBottomFloor: false,
        bathtub: false,
        balcony: false,
        formedCooperative: false,
        becomingCooperative: false,
        elevator: false,
        fireplace: false
      };

      this.wishIndex = 2;
    },

    isNumeric(input) {
      return input - 0 == input && ('' + input).trim().length > 0;
    },

    handleSaveClick() {
      if (this.isValid) {
        this.updateWishes();
        this.isSaving = true;
      } else {
        this.showErrors = true;
      }
    },

    handleRemoveWishClick() {
      this.showRemoveModal = false;
      this.removeWish();
    },

    async removeWish() {
      await lbApiBeta
        .delete(
          `/api/v2/proposition/${this.propositionId}/wish/${this.wishIndex}`,
          this.propositionWishes
        )
        .then(() => {
          this.setToast({ title: this.$t('edit_wish_toast_removed') });

          setTimeout(() => {
            this.$router.push(this.editPropositionPath);
          }, 1500);
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        });
    },

    async getWishes() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/wish/${this.wishIndex}`
        );

        this.propositionWishes = data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateWishes() {
      await lbApiBeta
        .post(
          `/api/v2/proposition/${this.propositionId}/wish/${this.wishIndex}`,
          this.propositionWishes
        )
        .then(() => {
          this.setToast({ title: this.$t('toast_changes_saved') });

          this.showErrors = false;

          setTimeout(() => {
            if (
              !this.currentWish ||
              this.currentWish.geolocations.length === 0
            ) {
              this.$router.push(this.editAreasPath);
            } else {
              this.$router.push(this.editPropositionPath);
            }
          }, 1500);
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        })
        .then(() => {
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-wish-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-proposition-wish {
  padding: 0 10px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    width: 90%;
    margin: 0 auto;
  }
}

.edit-proposition-wish .back-button {
  margin-left: -5px;
  margin-top: 20px;

  @media ($desktop) {
    margin-left: 0;
  }
}

.page-heading {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;

  @media ($mobile) {
    margin-top: 24px;
    font-size: 26px;
  }
}

.sub-heading {
  font-size: 15px;
  color: #555;
  margin-top: -18px;
  margin-bottom: 28px;
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @media ($desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.floor-checkbox {
  margin-top: -10px;
  margin-bottom: 1rem;
}

.checkbox-list {
  margin: 3rem 0;
}

.checkbox-list-first {
  margin-top: 0;
}

.checkbox-list-text {
  margin-top: -16px;
  margin-bottom: 20px;
}

.checkbox {
  margin-bottom: 1rem;
}

.button-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;

  @media ($desktop) {
    margin-bottom: 50px;
  }
}

.remove-button {
  margin-top: 30px;
  background: none;
  border: 0;
  color: $main-blue;
  font-weight: 600;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.remove-modal-content {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
    white-space: nowrap;
  }
}
</style>
