<template>
  <div
    class="swap-item"
    :style="[{ transform: position ? position : '' }]"
    @click.stop="goToProposition"
  >
    <div class="content-wrapper">
      <div class="image-wrapper">
        <div
          :style="[
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length
              ? {
                  backgroundImage: `url(${$t('url_residences')}/800x800/${
                    swap.propositionId
                  }/${currentShownResidence.pictures[0]})`,
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ]"
          class="image"
        ></div>
      </div>

      <div class="swap-item-body">
        <div>
          <div class="address-info">
            <div class="address">{{ currentShownResidence.streetAddress }}</div>
            <div v-if="is2to1" class="address secondary">
              & &nbsp;
              <span class="underline" @click.stop="switchCurrentShown">{{
                currentSecondaryResidence.streetAddress
              }}</span>
              <div class="shift-icon"></div>
            </div>
          </div>

          <div class="center-info">
            <span class="extra-bold">{{ currentShownResidence.rooms }}</span>
            {{ $t('map_swap_item_rooms') }} &#8729;
            <span class="extra-bold">{{ currentShownResidence.sqm }}</span>
            {{ $t('map_swap_item_sqm') }} &#8729;
            {{ $t('map_swap_item_floor') }}
            <span class="extra-bold">{{ currentShownResidence.floor }}</span>
            <br />
            <span class="extra-bold">{{ currentShownResidence.rent }}</span>
            {{ $t('map_swap_item_cost_per_month') }}
          </div>
        </div>

        <div class="interest-bar">
          <div class="interest-area">
            <InterestIndicators :interests="interests" />
          </div>
          <InterestButtons
            :interested="interests[0]"
            :target-proposition-id="swap.propositionId.toString()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InterestIndicators from './InterestIndicators';
import InterestButtons from './InterestButtons';

export default {
  name: 'MapSwapItem',

  components: {
    InterestIndicators,
    InterestButtons
  },

  props: {
    swap: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      currentResidence: 0
    };
  },

  computed: {
    residence1() {
      return this.swap.residences[0];
    },

    residence2() {
      return this.swap.residences[1];
    },

    interests() {
      return this.swap.details.interests;
    },

    currentShownResidence() {
      if (!this.is2to1) {
        return this.residence1;
      } else {
        return this.swap.residences[this.currentResidence];
      }
    },

    currentSecondaryResidence() {
      if (this.currentResidence === 0) {
        return this.residence2;
      } else {
        return this.residence1;
      }
    },

    is2to1() {
      return this.swap.residences.length > 1;
    }
  },

  methods: {
    goToProposition() {
      this.$router.push({
        query: {
          propositionPageId: this.swap.propositionId,
          type: this.swap.details.type
        }
      });
    },

    switchCurrentShown() {
      if (this.currentResidence === 0) {
        this.currentResidence = 1;
      } else {
        this.currentResidence = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-item {
  margin: 16px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media ($mobile) {
    border-radius: 5px;
    scroll-snap-align: center;
    transform: translateX(-50%);
    position: fixed;
    bottom: 60px;
    margin: 16px auto;
    left: 50%;
  }

  @media ($mobile) and (orientation: landscape) {
    bottom: 45px;
  }

  @media ($desktop) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 2px 4px 12px 0.5px rgba(0, 0, 0, 0.2);
  }

  &-body {
    @media ($desktop) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.content-wrapper {
  display: flex;
  padding: 12px;

  @media ($desktop) {
    padding: 16px;
  }
}

.show-swap-area {
  width: 100%;
  padding: 2px 4px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 8px;
}

.underline {
  text-decoration: underline;
}

.image-wrapper {
  height: 100px;
  width: 150px;
  // margin: 6px 0;
  border-radius: 4px;
  overflow: hidden;

  @media ($desktop) {
    height: 150px;
    min-width: 225px;
  }
}

.image {
  position: relative;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-color: #f7f7f9;

  z-index: 1;

  background-image: url('../../assets/svg/placeholderImage/placeholder-image-medium.svg');
}

.swap-item-body {
  margin: 6px 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.address {
  font-size: 0.8rem;
  font-weight: 700;

  &.secondary {
    font-size: 0.7rem;
    display: flex;
    align-items: center;
  }
}

.shift-icon {
  height: 13px;
  width: 15px;
  margin-left: 6px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/shift.svg');
}

.center-info {
  margin-top: 6px;
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 600;
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 0 0;
}

.interest-area {
  display: flex;
  align-items: center;

  @media ($mobile) {
    flex-direction: column-reverse;
  }
}

.extra-bold {
  font-weight: 700;
}

.action-button {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  color: $transfer-blue;
  padding: 4px 0;
}
</style>
