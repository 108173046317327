const { sha256 } = require('js-sha256');

export function getHash256(input) {
  try {
    return sha256(input);
  } catch (error) {
    console.log('Hash256 failed:', error.message);
    return input;
  }
}

export default {
  getHash256
};
