<template>
  <div class="tab-wrapper">
    <button
      :class="['menu-option', { selected: active }]"
      :style="{
        'background-color': bgColor
      }"
    >
      <transition name="bounce-tab" mode="out-in">
        <p :key="shouldAnimate" class="menu-option-text">
          <slot />
          <span v-if="showCount" class="menu-option-count">({{ count }})</span>
        </p>
      </transition>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TabNavigationItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    shouldAnimate: {
      type: Boolean,
      default: false,
      required: false
    },
    bgColor: {
      type: String,
      default: '#fff',
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  display: flex;
}

.menu-option {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  color: #a1a7ae;
  background-color: #fff;
  text-align: center;
  border: none;
  white-space: nowrap;
  cursor: pointer;
}

.menu-option.selected {
  color: #409fff;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #409fff;
  }
}

.menu-option-text {
  font-weight: 600;
  margin: 0;
}

.menu-option-count {
  display: inline-block;
  margin-left: 1px;
}
</style>
