function show(state, value) {
  state.overlay = {
    show: value.show,
    type: value.type
  };
}

function hide(state) {
  state.overlay = {
    show: false
  };
}

export default {
  hide,
  show
};
