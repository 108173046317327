<template>
  <div class="sticky-container">
    <ThreePartSwapNotification
      v-if="onThreePartSwapLinkClick !== null"
      :proposition-id="propositionId"
      class="swap-notification"
      @onShowSwapButtonPressed="onThreePartSwapLinkClick"
    />

    <div class="sticky-container-top">
      <div class="title-text">{{ interestedText }}</div>

      <div class="interest-wrapper">
        <InterestToggle
          :interested="interested"
          :toggle-interest="markInterest"
          v-on="$listeners"
        />
      </div>
    </div>
    <user-card
      v-if="user && user.userId"
      style="margin-top: 20px"
      :proposition-id="propositionId"
      :user="user"
      :open-contact-modal="openContactModal"
      :primary-proposition-id="primaryPropositionId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InterestToggle from '../../SwapComponents/InterestToggle';
import UserCard from '../UserCard/UserCard';
import ThreePartSwapNotification from '../ThreePartSwapNotification.vue';

export default {
  name: 'PropositionInterestBox',

  components: {
    InterestToggle,
    UserCard,
    ThreePartSwapNotification
  },

  props: {
    isOnboarding: {
      type: Boolean,
      default: false
    },
    interested: {
      type: Boolean,
      default: null
    },
    markInterest: {
      type: Function,
      required: true
    },
    propositionImage: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => {}
    },
    propositionId: {
      type: String,
      required: true
    },
    openContactModal: {
      type: Function,
      required: true
    },
    onThreePartSwapLinkClick: {
      type: Function,
      required: false,
      default: null
    },
    propositionAddress: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    interestedText() {
      const { interested } = this;
      if (interested) return this.$t('proposition_interest_you_are_interested');
      if (interested == null)
        return this.$t('proposition_interest_are_you_interested');
      return this.$t('proposition_interest_you_are_not_interested');
    }
  }
};
</script>
<style lang="scss" scoped>
.swap-notification {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sticky-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  width: 300px;
  height: fit-content;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 50px;
}

.sticky-container-top {
  border-radius: 3px;
  border: 2px solid #589ff8;
  box-shadow: 0 6px 35px rgba(0, 0, 0, 0.09);
  padding: 45px 25px 45px 25px;
}

.title-text {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 28px;
}

.interest-text {
  font-size: 0.85rem;
  font-weight: 600;
  color: $text-secondary;
  line-height: 120%;
  text-align: center;
  margin: 0 0 25px 0;
}

.swap-link {
  cursor: pointer;
  color: $lighter-blue;
  font-weight: 600;
  padding: 3px 5px;
  margin-top: 5px;
  font-size: 0.85rem;
  display: inline-block;
}
</style>
