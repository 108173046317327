<template>
  <div
    :class="content.ReverseBlock ? 'reversed' : ''"
    class="image-and-text-block"
  >
    <img :src="content.Image.formats.small.url" alt="" />
    <div class="text-area editor-content" v-html="content.Richtext"></div>
  </div>
</template>

<script>
export default {
  name: 'ImageAndText',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.image-and-text-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 16px;
  padding-left: 16px;

  @media ($large-screen) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
  }
}

.reversed img {
  @media ($large-screen) {
    order: 2;
  }
}

.text-area {
  display: grid;
  align-content: center;
  padding: 16px 0;

  @media ($large-screen) {
    padding: 32px 0;
    padding-left: 16px;
  }
}

.reversed .text-area {
  @media ($large-screen) {
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
