import { render, staticRenderFns } from "./SwapListSlider.vue?vue&type=template&id=1be9866d&scoped=true"
import script from "./SwapListSlider.vue?vue&type=script&lang=js"
export * from "./SwapListSlider.vue?vue&type=script&lang=js"
import style0 from "./SwapListSlider.vue?vue&type=style&index=0&id=1be9866d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be9866d",
  null
  
)

export default component.exports