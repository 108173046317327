<template>
  <StandardModal
    standard-modal-name="PremiumActionModal"
    :show-header="false"
    :esc-close="true"
    content-height="max-content"
    @close="handleCloseModal"
  >
    <div slot="content" class="premium-action-modal-container">
      <ReverseInterestModalContent
        v-if="premiumModalType === PREMIUM_MODAL_CONTENT_TYPES.REVERSE_INTEREST"
        @close="handleCloseModal"
        @premium-click="handlePremiumClick"
      />
      <InterestMarksContent
        v-if="premiumModalType === PREMIUM_MODAL_CONTENT_TYPES.INTEREST_MARKS"
        @close="handleCloseModal"
        @premium-click="handlePremiumClick"
      />
      <NoInterestMarksContent
        v-if="
          premiumModalType === PREMIUM_MODAL_CONTENT_TYPES.NO_INTEREST_MARKS
        "
        @close="handleCloseModal"
        @premium-click="handlePremiumClick"
      />
    </div>
  </StandardModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StandardModal from '@/components/Modals/Standard';
import { PREMIUM_MODAL_CONTENT_TYPES } from '@/store/modules/ui';
import ReverseInterestModalContent from '@/components/Premium/ReverseInterestModalContent';
import NoInterestMarksContent from '@/components/Premium/NoInterestMarksContent';
import InterestMarksContent from '@/components/Premium/InterestMarksContent';

export default {
  name: 'PremiumActionModal',

  components: {
    StandardModal,
    ReverseInterestModalContent,
    InterestMarksContent,
    NoInterestMarksContent
  },

  props: {
    premiumModalType: {
      type: String,
      default: PREMIUM_MODAL_CONTENT_TYPES.REVERSE_INTEREST,
      required: true
    }
  },

  data() {
    return {
      PREMIUM_MODAL_CONTENT_TYPES
    };
  },

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleCloseModal() {
      this.setGlobalModal(false);
    },

    handlePremiumClick() {
      this.handleCloseModal();
      this.$router.push(
        `${this.$routes.PAYMENT_PROPOSITION}/${this.primaryPropositionId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
