var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"wrapper",on:{"mouseenter":function($event){_vm.isHovered = true},"touchstart":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[(!_vm.isMobile && _vm.imageCount > 1)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered),expression:"isHovered"}],staticClass:"desktop-btn-wrapper"},[_c('div',{staticClass:"desktop-btn left",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goToPrevImage.apply(null, arguments)}}}),_c('div',{staticClass:"desktop-btn right",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goToNextImage.apply(null, arguments)}}})]):_vm._e(),_c('div',{ref:"slider",staticClass:"slides-container"},[_c('div',{staticClass:"image-wrapper"},[(_vm.imageCount > 1)?_c('div',{ref:("image-" + (_vm.imageCount + 1)),class:['image'],style:([
          _vm.loadedImages[_vm.imageCount - 1]
            ? {
                backgroundImage: ("url(" + (_vm.loadedImages[_vm.imageCount - 1]) + ")"),
                opacity: 1,
                maxWidth: (_vm.imgWidth + "px"),
                minWidth: (_vm.imgWidth + "px")
              }
            : ''
        ])}):_vm._e()]),_vm._l((_vm.loadedImages),function(image,index){return _c('div',{key:image + Math.random(),staticClass:"image-wrapper"},[_c('div',{ref:("image-" + (index + 1)),refInFor:true,class:[
          {
            'blurred-background-image': _vm.blurImage && index >= 2
          },
          'image'
        ],style:([
          image
            ? {
                backgroundImage: ("url(" + image + ")"),
                opacity: 1,
                maxWidth: (_vm.imgWidth + "px"),
                minWidth: (_vm.imgWidth + "px")
              }
            : ''
        ])}),(_vm.blurImage && index >= 2)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.blurImage && index >= 2)?_c('div',{staticClass:"blur-msg-wrapper"},[_c('div',{staticClass:"blur-msg-click-container",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goToEditPropositionImages()}}},[_c('BaseIcon',{attrs:{"icon-file":"blur-img-eye","width":48,"height":46}}),_c('div',{staticClass:"blur-msg"},[_c('p',{staticClass:"unblock-text"},[_vm._v(" "+_vm._s(_vm.$t('unlock_blur_msg'))+" ")]),_c('div',{staticClass:"upload-images-text"},[(_vm.isLink)?_c('span',[_c('u',[_vm._v(_vm._s(_vm.$t('proposition_gallery_blur_link_msg')))])]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('proposition_gallery_blur_link_msg'))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('proposition_gallery_blur_msg'))+" ")])])])],1)]):_vm._e()])}),_c('div',{staticClass:"image-wrapper"},[(_vm.imageCount > 1)?_c('div',{ref:"image-0",class:['image'],style:([
          _vm.loadedImages[0]
            ? {
                backgroundImage: ("url(" + (_vm.loadedImages[0]) + ")"),
                opacity: 1,
                maxWidth: (_vm.imgWidth + "px"),
                minWidth: (_vm.imgWidth + "px")
              }
            : ''
        ])}):_vm._e()])],2),(_vm.imageCount > 1)?_c('div',{ref:"dotsContainer",staticClass:"dots-container"},_vm._l((_vm.imageCount),function(index){return _c('div',{key:("index-" + (index - 1)),ref:("dot-" + (index - 1)),refInFor:true,staticClass:"dot",style:([
        index === 1 ? { backgroundColor: 'rgba(255, 255, 255, 0.9)' } : ''
      ])})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }