<template>
  <div>
    <StandardModal
      standard-modal-name="SwapSortModal"
      content-height="max-content"
      :esc-close="true"
      :show-header="true"
      :title="$t('swap_list_sort_title')"
      header-title="Sort Options"
      @close="setGlobalModal(false)"
    >
      <SortOptions
        slot="content"
        @change="sortOption => handleSelectSortType(sortOption)"
        @submit="setGlobalModal(false)"
      />
    </StandardModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StandardModal from '@/components/Modals/Standard';
import SortOptions from '@/components/SwapList/SortOptions';

import {
  SORT_TYPES,
  SORT_DIRECTIONS,
  ACTIVE_SORT_TYPES
} from '@/store/modules/swapList';

export default {
  name: 'SortingModalWrapper',

  components: {
    SortOptions,
    StandardModal
  },

  data() {
    return {
      SORT_TYPES
    };
  },

  computed: {
    ...mapActions({}),
    isSelectedOption() {
      return Object.keys(ACTIVE_SORT_TYPES).includes(this.sortType);
    }
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal',
      setSortType: 'swapList/setSortType',
      setSelectedSortType: 'swapList/setSelectedSortType'
    }),

    getSortDirection(sortType) {
      switch (sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return SORT_DIRECTIONS.DESCENDING;
        case SORT_TYPES.SQM_ASC:
          return SORT_DIRECTIONS.ASCENDING;
        case SORT_TYPES.SQM_DESC:
          return SORT_DIRECTIONS.DESCENDING;
        case SORT_TYPES.ROOMS_ASC:
          return SORT_DIRECTIONS.ASCENDING;
        case SORT_TYPES.ROOMS_DESC:
          return SORT_DIRECTIONS.DESCENDING;
        case SORT_TYPES.RENT_ASC:
          return SORT_DIRECTIONS.ASCENDING;
        case SORT_TYPES.RENT_DESC:
          return SORT_DIRECTIONS.DESCENDING;
        case SORT_TYPES.PERCENTAGE:
          return SORT_DIRECTIONS.DESCENDING;
        // case SORT_TYPES.INTEREST_DATE:
        //   return SORT_DIRECTIONS.DESCENDING;
        default:
          return '';
      }
    },

    handleSelectSortType(sortOption) {
      this.setSelectedSortType({ type: sortOption });
      const sortDirection = this.getSortDirection(sortOption);
      this.setSortType({ type: sortOption, sortDirection });
    }
  }
};
</script>

<style lang="scss" scoped></style>
