<template>
  <div class="premium-no-interest-marks-content">
    <img src="../../assets/svg/searching.svg" alt="Man with magnifying glass" />
    <p class="modal-heading">
      {{ $t('premium_no_interest_marks_modal_heading') }}
    </p>
    <div class="text-container">
      <p>
        {{ $t('premium_no_interest_marks_action_card_text') }}
      </p>
    </div>
    <p class="end-text">
      {{ $t('premium_no_interest_end_text') }}
    </p>
    <BaseButton
      icon-file="arrow-right-alt2-white"
      class="primary-action full-width rounded smaller"
      :icon-last="true"
      @click="$emit('premium-click')"
      >{{ $t('premium_no_interest_marks_modal_btn_text') }}</BaseButton
    >
    <button class="not-ready-text-btn" @click="$emit('close')">
      <p>
        {{ $t('premium_no_interest_marks_action_btn_text') }}
      </p>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NoInterestMarksContent',

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  }
};
</script>

<style lang="scss" scoped>
.premium-no-interest-marks-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  padding: 32px 16px 16px 16px;
  text-align: center;

  @media ($desktop) {
    padding: 32px;
  }

  img {
    margin-top: 0px;
    width: 220px;
    height: 220px;
    min-width: 200px;
    min-height: 200px;

    @media ($desktop) {
      margin-top: 20px;
      width: 269px;
      height: 269px;
    }
  }

  .modal-heading {
    font-size: 20px;
    font-weight: 600;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 330px;

    p {
      margin: 0;
      line-height: 24px;
    }
  }

  .end-text {
    margin: 16px 0 20px 0;
    color: var(--RC-Text-2-485261, #485261);
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    @media ($desktop) {
      margin: 32px;
    }
  }

  .not-ready-text-btn {
    margin: 32px;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    p {
      text-decoration: underline;
      color: #999999;
      font-size: 14px;
      margin: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
  & .button {
    font-size: 18px;
    line-height: 28px;
    max-width: 320px;
    min-width: max-content;
  }
}
</style>
