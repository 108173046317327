import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const REPORT_PROPOSITION_PATHWAYS = {
  INITIAL_1: '1.0',
  OFFENSIVE_1: '1.1',
  IMAGES_1: '1.2',
  IMAGES_2: '1.2.1',
  IMAGES_3: '1.2.2',
  FAULTY_1: '1.3',
  FAULTY_2: '1.3.1',
  FAULTY_3: '1.3.2',
  FAULTY_4: '1.3.3',
  OTHER_1: '1.4',
  OTHER_2: '1.4.1',
  OTHER_3: '1.4.2',
  OTHER_4: '1.4.3',
  OTHER_5: '1.4.4',
  OTHER_6: '1.4.5',
  OTHER_7: '1.4.6',
  SUCCESS: 'success'
};

export const REPORT_BUTTON_TYPES = {
  NEXT: 'next',
  SEND: 'send',
  CLOSE: 'close'
};

export const REPORT_SCREEN_TYPES = {
  FORM: 'form',
  RADIO: 'radio',
  SUCCESS: 'success'
};

const state = {
  formFilled: false,
  allowContact: false,
  textareaReportDescription: '',
  previousScreen: null,
  currentScreen: REPORT_PROPOSITION_PATHWAYS.INITIAL_1,

  OPTION: {
    [REPORT_PROPOSITION_PATHWAYS.INITIAL_1]: {
      selected: null
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_1]: {
      selected: null
    },
    [REPORT_PROPOSITION_PATHWAYS.IMAGES_1]: {
      selected: null
    },
    [REPORT_PROPOSITION_PATHWAYS.FAULTY_1]: {
      selected: null
    }
  },

  SCREENS: {
    [REPORT_PROPOSITION_PATHWAYS.INITIAL_1]: {
      label: 'report_why_want_to_report',
      type: 'radio',
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.NEXT,
      options: [
        {
          id: 1,
          label: 'report_option_offensive'
        },
        {
          id: 2,
          label: 'report_option_images'
        },
        {
          id: 3,
          label: 'report_option_faulty'
        },
        {
          id: 4,
          label: 'report_option_other'
        }
      ]
    },
    [REPORT_PROPOSITION_PATHWAYS.OFFENSIVE_1]: {
      label: 'report_why_offensive',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.IMAGES_1]: {
      label: 'report_wrong_with_images',
      type: REPORT_SCREEN_TYPES.RADIO,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.NEXT,
      options: [
        {
          id: 1,
          label: 'report_option_images_not_related',
          description: 'report_description_images_not_related'
        },
        {
          id: 2,
          label: 'report_option_inappropriate_images',
          description: 'report_description_inappropriate_images'
        }
      ]
    },
    [REPORT_PROPOSITION_PATHWAYS.IMAGES_2]: {
      label: 'report_wrong_with_images',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.IMAGES_3]: {
      label: 'report_wrong_with_images',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.FAULTY_1]: {
      label: 'report_faulty_information',
      type: REPORT_SCREEN_TYPES.RADIO,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.NEXT,
      options: [
        {
          id: 1,
          label: 'report_option_incorrect_residence_info',
          description: 'report_description_incorrect_residence_info'
        },
        {
          id: 2,
          label: 'report_option_not_matching_advertisement',
          description: 'report_description_not_matching_advertisement'
        },
        {
          id: 3,
          label: 'report_option_other_reason'
        }
      ]
    },
    [REPORT_PROPOSITION_PATHWAYS.FAULTY_2]: {
      label: 'report_faulty_information',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.FAULTY_3]: {
      label: 'report_faulty_information',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.FAULTY_4]: {
      label: 'report_faulty_information',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_1]: {
      label: 'report_which_categories_match',
      type: REPORT_SCREEN_TYPES.RADIO,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.NEXT,
      options: [
        {
          id: 1,
          label: 'report_option_not_serious'
        },
        {
          id: 2,
          label: 'report_option_asked_for_money'
        },
        {
          id: 3,
          label: 'report_option_copied_content'
        },
        {
          id: 4,
          label: 'report_option_not_the_owner'
        },
        {
          id: 5,
          label: 'report_option_not_apartment'
        },
        {
          id: 6,
          label: 'report_option_other_reason'
        }
      ]
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_2]: {
      label: 'report_which_categories_match',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_3]: {
      label: '',
      type: REPORT_SCREEN_TYPES.SUCCESS,
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.CLOSE
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_4]: {
      label: '',
      type: REPORT_SCREEN_TYPES.SUCCESS,
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.CLOSE
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_5]: {
      label: '',
      type: REPORT_SCREEN_TYPES.SUCCESS,
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.CLOSE
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_6]: {
      label: '',
      type: REPORT_SCREEN_TYPES.SUCCESS,
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.CLOSE
    },
    [REPORT_PROPOSITION_PATHWAYS.OTHER_7]: {
      label: 'report_which_categories_match',
      type: REPORT_SCREEN_TYPES.FORM,
      showBackButton: true,
      primaryButtonType: REPORT_BUTTON_TYPES.SEND
    },
    [REPORT_PROPOSITION_PATHWAYS.SUCCESS]: {
      label: '',
      type: REPORT_SCREEN_TYPES.SUCCESS,
      showBackButton: false,
      primaryButtonType: REPORT_BUTTON_TYPES.CLOSE
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
