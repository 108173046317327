<template>
  <div class="no-matches">
    <BaseIcon
      icon-file="no-result"
      :width="282"
      :height="239"
      class="no-result-icon"
    />
    <h1 class="no-matches-title">{{ resultTitle }}</h1>
    <p class="no-matches-text">
      {{ resultText }}
    </p>
    <BaseButton :link="$t('path_swap_suggestions', $routeLocale)">
      {{ $t('matches_nores_cta') }}
    </BaseButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NoResult',

  computed: {
    ...mapGetters({
      activeTab: 'matches/activeTab'
    }),

    resultTitle() {
      if (this.activeTab === 'unanswered') {
        return this.$t('matches_nores_unanswered_title');
      } else if (this.activeTab === 'unread') {
        return this.$t('matches_nores_unread_title');
      } else if (this.activeTab === 'updated') {
        return this.$t('matches_nores_updated_title');
      } else if (this.activeTab === 'active') {
        return this.$t('matches_nores_active_title');
      } else {
        return this.$t('matches_nores_inactive_title');
      }
    },

    resultText() {
      if (this.activeTab === 'unanswered') {
        return this.$t('matches_nores_unanswered_body');
      } else if (this.activeTab === 'unread') {
        return this.$t('matches_nores_unread_body');
      } else if (this.activeTab === 'updated') {
        return this.$t('matches_nores_updated_body');
      } else if (this.activeTab === 'active') {
        return this.$t('matches_nores_active_body');
      } else {
        return this.$t('matches_nores_inactive_body');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.no-matches {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
}

.no-result-icon {
  margin-top: 10px;
  margin-bottom: 16px;
}

.no-matches-title {
  color: #213a75;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  padding: 0 40px;
  margin-top: 0;
  margin-bottom: 16px;

  @media ($desktop) {
    margin-bottom: 24px;
  }
}

.no-matches-text {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  padding: 0 16px;

  @media ($desktop) {
    margin-bottom: 32px;
  }
}
</style>
