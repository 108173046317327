<template>
  <div :class="['spacing', content.Spacing]"></div>
</template>

<script>
export default {
  name: 'Spacing',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.spacing {
  width: 100%;
}

.Small {
  height: 8px;
}

.Medium {
  height: 16px;
}

.Large {
  height: 32px;
}
</style>
