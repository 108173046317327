<template>
  <div class="image-block">
    <img :src="content.Image1.formats.small.url" alt="" />
    <img :src="content.Image2.formats.small.url" alt="" />
  </div>
</template>

<script>
export default {
  name: 'TwoImages',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.image-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  @media ($large-screen) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
  }
}
</style>
