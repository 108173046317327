<template>
  <ReportScreenLayout>
    <template #heading>
      <div v-html="$t(formHeadingLabel)"></div>
    </template>
    <template #body>
      <div
        v-if="radioOptions.length > 0 && type === REPORT_SCREEN_TYPES.RADIO"
        class="options-container"
      >
        <div
          v-for="(option, index) in radioOptions"
          :key="index"
          class="option"
        >
          <ReportOption
            :value="option.id"
            :selected="selectedRadioOption"
            @change="handleChange"
            ><div class="option-label" v-html="$t(option.label)"></div>
            <div
              v-if="option.description"
              class="option-description"
              v-html="$t(option.description)"
            />
          </ReportOption>
        </div>
      </div>
      <transition :name="transitionName" mode="out-in">
        <div
          v-if="type === REPORT_SCREEN_TYPES.FORM"
          class="report-describe-wrapper"
        >
          <ReportDescribeScreen />
        </div>
      </transition>
      <transition name="tutorialBounceIn" mode="out-in" appear>
        <div v-if="type === REPORT_SCREEN_TYPES.SUCCESS">
          <Success />
        </div>
      </transition>
    </template>
    <template #footer>
      <div class="cta-buttons-wrapper">
        <BaseButton
          v-if="showBackButton"
          type="button"
          class="back-btn primary grey outline"
          style="min-width: 8rem"
          @click="handleClick($event, 'back', previousScreen)"
          >{{ $t('report_label_back') }}
        </BaseButton>
        <BaseButton
          v-if="primaryButtonType === REPORT_BUTTON_TYPES.NEXT"
          type="button"
          :disabled="!selectedRadioOption"
          :class="primaryButtonClasses"
          @click="
            handleClick(
              $event,
              currentScreen === '1.4' &&
                selectedRadioOption < radioOptions.length
                ? 'send'
                : 'next',
              selectedRadioOption
            )
          "
        >
          {{ $t('report_label_next') }}
        </BaseButton>
        <BaseButton
          v-if="primaryButtonType === REPORT_BUTTON_TYPES.SEND"
          :disabled="!formFilled"
          type="button"
          style="width: 100%"
          :class="primaryButtonClasses"
          @click="handleClick($event, 'send')"
        >
          {{ $t('report_label_send') }}
        </BaseButton>

        <BaseButton
          v-if="primaryButtonType === REPORT_BUTTON_TYPES.CLOSE"
          type="button"
          style="width: 100%"
          class="rounded grey"
          :icon-file="showCloseButtonIcon ? 'search-white' : ''"
          @click="handleClick($event, 'done')"
        >
          {{ $t('report_label_done') }}
        </BaseButton>
      </div>
    </template>
  </ReportScreenLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ReportScreenLayout from './Layout.vue';
import ReportOption from '@/components/Proposition/Report/Partials/Option';
import ReportDescribeScreen from '@/components/Proposition/Report/Screens/Describe';
import { REPORT_BUTTON_TYPES } from '../../../../store/modules/reportProposition';
import { REPORT_SCREEN_TYPES } from '../../../../store/modules/reportProposition';
import Success from '@/components/Proposition/Report/Screens/Success';

export default {
  name: 'ReportScreen',

  components: {
    ReportScreenLayout,
    ReportOption,
    ReportDescribeScreen,
    Success
  },

  props: {
    screenInfo: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    REPORT_BUTTON_TYPES,
    REPORT_SCREEN_TYPES,
    primaryButtonClasses: 'full-width grey larger',
    previousScreenInfo: {},
    transitionName: 'slide-from-right',
    showCloseButtonIcon: false
  }),

  computed: {
    ...mapGetters({
      getScreenInfo: 'reportProposition/getScreenInfo',
      currentScreen: 'reportProposition/currentScreen',
      getOptionState: 'reportProposition/getOptionState',
      previousScreen: 'reportProposition/previousScreen',
      formFilled: 'reportProposition/formFilled',
      SCREENS: 'reportProposition/SCREENS'
    }),
    selectedRadioOption() {
      return this.getOptionState(this.currentScreen)?.selected ?? null;
    },
    formHeadingLabel() {
      return this.screenInfo.label;
    },
    radioOptions() {
      return this.screenInfo.options ? this.screenInfo.options : [];
    },
    primaryButtonType() {
      return this.screenInfo.primaryButtonType;
    },
    type() {
      return this.screenInfo.type;
    },
    showBackButton() {
      return this.screenInfo.showBackButton;
    }
  },

  mounted() {
    setTimeout(() => (this.transitionName = 'slide-in-from-right'), 500);
  },

  methods: {
    ...mapActions({
      setOptionSelected: 'reportProposition/setOptionSelected'
    }),
    handleChange(value) {
      this.setOptionSelected({ option: this.currentScreen, value });
    },
    handleClick(event, type, value = null) {
      let emitType = type;

      const screenInfo = this.getScreenInfo(
        `${this.currentScreen}.${this.selectedRadioOption}`
      );

      if (screenInfo && screenInfo.type === REPORT_SCREEN_TYPES.FORM) {
        emitType = 'next';
      }

      this.$emit(emitType, {
        event,
        value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.options-container {
  display: flex;
  flex-direction: column;
  position: relative;

  & .option {
    user-select: none;

    &:not(:first-child) {
      border-top: 1px solid #ebebeb;
    }
  }
}

.option-label {
  font-size: 15px;
  max-width: 19rem;
  color: #344054;

  @media ($desktop) {
    max-width: initial;
  }
}

.option-description {
  font-size: 15px;
  color: #878b9d;
}

.cta-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .back-btn {
    min-width: 8rem;
    justify-content: center;
    align-items: center;
  }

  .next-btn {
    min-width: 8rem;
    justify-content: center;
    align-items: center;
  }

  .report-describe-wrapper {
    margin-top: 1.5rem;
    width: 100%;
  }
}
</style>
