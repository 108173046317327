<template>
  <BaseInfoModalNew>
    <div class="modal-content">
      <BaseIcon
        :icon-file="'distribution-scoll-bottom'"
        :width="220"
        :height="166"
        class="return-later-icon"
      />
      <h2>{{ $t('return_later_modal_title') }}</h2>
      <p>
        {{ $t('return_later_modal_body') }}
      </p>
      <BaseButton
        class="grey outline smaller full-width modal-button"
        @click="$emit('close-return-later-modal')"
        >{{ $t('return_later_modal_button') }}</BaseButton
      >
    </div>
  </BaseInfoModalNew>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ReturnLaterModal',

  mounted() {
    this.addDisableScroll('return-later-modal');
  },

  destroyed() {
    this.removeDisableScroll('return-later-modal');
  },

  methods: {
    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    })
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.return-later-icon {
  margin-bottom: 16px;
}

h2 {
  margin: 0;
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 12px;
}

p {
  margin: 0;
  font-size: 15px;
  line-height: 135%;
  margin-bottom: 20px;
}

.modal-button {
  margin-bottom: 1px;
}
</style>
