<template>
  <div class="swap-filter-buttons-wrapper">
    <BaseButton
      :disabled="isLoading"
      class="rounded filter-button"
      icon-file="filter-slider-black"
      :icon-size="22"
      @click="handleShowFilterModal($event)"
    >
      {{ $t('swap_list_filter_title') }}
      {{ currentFiltersCountButton }}</BaseButton
    >
    <BaseButton
      :disabled="isLoading"
      class="rounded filter-button"
      icon-file="data-transfer-vertical-black"
      :icon-size="22"
      @click="handleShowSortingModal($event)"
    >
      {{ $t('swap_list_sort_title') }}</BaseButton
    >
    <!-- <div v-if="isLoading" class="spinner-container">
      <BaseSpinner class="spinner-loader" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SwapFilterButtons',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    ...mapGetters({
      showGlobalModal: 'ui/showGlobalModal',
      filterCount: 'swapList/filterCount',
      searchFilters: 'swapList/searchFilters'
    }),

    currentFiltersCountButton() {
      return this.filterCount > 0 ? `(${this.filterCount})` : '';
    }
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleShowFilterModal() {
      this.setGlobalModal('FilterModal');
    },

    handleShowSortingModal() {
      this.setGlobalModal('SortingModal');
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-filter-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 10px;
  margin-bottom: 20px;

  .button {
    width: 35%;
    min-width: min-content;
  }

  .spinner-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 100%;
    min-height: 40px;

    .spinner-loader {
      border-color: rgba(135, 139, 157, 0.5) !important;
      border-top-color: rgb(53, 44, 44) !important;
      height: 25px !important;
      width: 25px !important;
      opacity: 0.25;
    }
  }
}
</style>
