<template>
  <div
    v-if="swapDetails && Object.keys(swapDetails).length > 0"
    class="swap-overview"
  >
    <router-link
      :to="`${$t('path_proposition', $routeLocale)}/${
        userProposition.propositionId
      }`"
      :target="!isMobile && '_blank'"
      class="my-proposition"
    >
      <div
        v-if="
          isTriangle
            ? thirdProposition.housingVisited
            : moveToProposition.housingVisited
        "
        class="visited-banner"
      >
        {{ $t('swap_overview_visited') }}
      </div>

      <div v-if="isUserList" class="label">
        {{ $t('swap_overview_label') }}
        <div class="proposition-type proposition-inline">
          <APill
            :is-active="true"
            :is-extra-small="true"
            :class="[getTypeColor(userProposition, moveToProposition)]"
          >
            {{ getTypeLabel(userProposition, moveToProposition) }}
          </APill>
        </div>
      </div>
      <div
        v-for="(residence, index) in userProposition.residences"
        :key="residence.streetAddress"
        class="address"
      >
        {{ residence.streetAddress }}
        {{ index === 0 && userProposition.residences.length > 1 ? '&' : '' }}
      </div>
      <div v-if="isImpersonated" class="swap-party-info red">
        {{ userProposition.residences[0].rooms }}
        {{ $t('swap_overview_rooms') }} &#8729;
        {{ userProposition.residences[0].sqm }}
        {{ $t('swap_overview_sqm') }}
      </div>

      <div v-if="isImpersonated" class="swap-party-info red">
        {{ getSpacedRent(userProposition.residences[0].rent) }}
        {{ $t('swap_overview_cost_per_month') }}
      </div>
    </router-link>
    <UserActivityDetails
      class="current-user-activity-details"
      :proposition-id="userProposition.propositionId.toString()"
    ></UserActivityDetails>

    <div class="interest-arrows-bar">
      <div
        :class="[
          'arrow up arrow-gray',
          { 'arrow-green': upArrowStatus === true },
          { 'arrow-red': upArrowStatus === false }
        ]"
      />

      <!-- do not remove -->
      <div></div>

      <div
        :class="[
          'arrow down arrow-gray',
          { 'arrow-green': interests[0] === true },
          { 'arrow-red': interests[0] === false }
        ]"
      />
    </div>

    <div class="swap-parties">
      <div
        v-if="isTriangle"
        :class="['swap-party', { 'swap-party-half-size': isTriangle }]"
      >
        <router-link
          :is="thirdProposition.inactivatedAt ? 'div' : 'router-link'"
          class="swap-party-link"
          :to="`${$t('path_proposition', $routeLocale)}/${
            thirdProposition.propositionId
          }`"
          :target="!isMobile && '_blank'"
          @click.native="
            handlePropositionClicked(thirdProposition.propositionId)
          "
          @click.right.native="
            handlePropositionCardRightClick(thirdProposition.propositionId)
          "
        >
          <div v-if="moveToProposition.housingVisited" class="visited-banner">
            {{ $t('swap_overview_visited') }}
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="thirdProposition.isRemoved" class="removed-label">
              <div class="removed-info">{{ $t('swap_overview_feedback') }}</div>
            </div>
          </transition>
          <div class="proposition-type">
            <APill
              :is-active="true"
              :is-extra-small="true"
              :class="[getTypeColor(thirdProposition, userProposition)]"
            >
              {{ getTypeLabel(thirdProposition, userProposition) }}
            </APill>
          </div>
          <div class="swap-party-city">
            {{ thirdProposition.residences[0].postalArea }}
            {{
              thirdProposition.residences.length > 1
                ? `& ${thirdProposition.residences[1].postalArea}`
                : ''
            }}
          </div>

          <div class="swap-party-address">
            {{ thirdProposition.residences[0].streetAddress }}
            <span class="small"
              ><br />{{
                thirdProposition.residences.length > 1
                  ? `& ${thirdProposition.residences[1].streetAddress}`
                  : ''
              }}</span
            >
          </div>

          <div class="swap-party-info">
            <strong>{{ thirdProposition.residences[0].rooms }} </strong
            >{{ $t('swap_overview_rooms') }} &#8729;
            <strong>{{ thirdProposition.residences[0].sqm }} </strong
            >{{ $t('swap_overview_sqm') }}
          </div>

          <div class="swap-party-info">
            <strong>{{
              getSpacedRent(thirdProposition.residences[0].rent)
            }}</strong>
            {{ $t('swap_overview_cost_per_month') }}
          </div>

          <div
            v-if="thirdProposition.inactivatedAt"
            class="swap-party-unavailable-message"
          >
            <BaseIcon
              class="unavailable-message-icon"
              icon-file="icons/blocked-house"
            />
            {{ getUnavailableMessage(thirdProposition) }}
          </div>
          <UserActivityDetails
            v-else
            :swap-style="'three-part'"
            :proposition-id="thirdProposition.propositionId.toString()"
          ></UserActivityDetails>
        </router-link>
        <div v-if="showActionButtons" class="swap-party-action-buttons">
          <button
            class="swap-party-remove-button"
            @click.stop="handleBlockSwapParty"
          >
            {{ $t('swap_overview_remove') }}
          </button>
        </div>
      </div>

      <div v-if="isTriangle" class="triangle-arrow-wrapper">
        <div
          :class="[
            'arrow left arrow-gray',
            { 'arrow-green': interests[1] === true },
            { 'arrow-red': interests[1] === false }
          ]"
        />
      </div>

      <div :class="['swap-party', { 'swap-party-half-size': isTriangle }]">
        <router-link
          :is="moveToProposition.inactivatedAt ? 'div' : 'router-link'"
          class="swap-party-link"
          :to="`${$t('path_proposition', $routeLocale)}/${
            moveToProposition.propositionId
          }`"
          :target="!isMobile && '_blank'"
          @click.native="
            handlePropositionClicked(moveToProposition.propositionId)
          "
          @click.right.native="
            handlePropositionCardRightClick(moveToProposition.propositionId)
          "
        >
          <div v-if="userProposition.housingVisited" class="visited-banner">
            {{ $t('swap_overview_visited') }}
          </div>
          <div class="proposition-type-wrapper">
            <div class="proposition-type">
              <APill
                :is-active="true"
                :is-extra-small="true"
                :class="[
                  getTypeColor(
                    moveToProposition,
                    thirdProposition ? thirdProposition : userProposition
                  )
                ]"
              >
                {{
                  getTypeLabel(
                    moveToProposition,
                    thirdProposition ? thirdProposition : userProposition
                  )
                }}
              </APill>
            </div>
          </div>
          <div class="swap-party-city">
            {{ moveToProposition.residences[0].postalArea }}
            {{
              moveToProposition.residences.length > 1
                ? `& ${moveToProposition.residences[1].postalArea}`
                : ''
            }}
          </div>

          <div class="swap-party-address">
            {{ moveToProposition.residences[0].streetAddress }}
            <span class="small"
              ><br />{{
                moveToProposition.residences.length > 1
                  ? `& ${moveToProposition.residences[1].streetAddress}`
                  : ''
              }}</span
            >
          </div>

          <div class="swap-party-info">
            <strong>{{ moveToProposition.residences[0].rooms }} </strong
            >{{ $t('swap_overview_rooms') }} &#8729;
            <strong>{{ moveToProposition.residences[0].sqm }}</strong>
            {{ $t('swap_overview_sqm') }}
          </div>

          <div class="swap-party-info">
            <strong>{{
              getSpacedRent(moveToProposition.residences[0].rent)
            }}</strong>
            {{ $t('swap_overview_cost_per_month') }}
          </div>

          <div
            v-if="moveToProposition.inactivatedAt"
            class="swap-party-unavailable-message"
          >
            <BaseIcon
              class="unavailable-message-icon"
              icon-file="icons/blocked-house"
            />
            {{ getUnavailableMessage(moveToProposition) }}
          </div>
          <UserActivityDetails
            v-else
            :swap-style="'three-part'"
            :proposition-id="moveToProposition.propositionId.toString()"
          ></UserActivityDetails>
        </router-link>
        <div
          v-if="showActionButtons && isUserList"
          class="swap-party-action-buttons"
        >
          <interest-buttons
            :interested="userInterested"
            :target-proposition-id="moveToProposition.propositionId.toString()"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isTriangle && alternativesCount > 1"
      :class="[
        'swap-alternatives-button',
        { pulse: thirdProposition.isRemoved }
      ]"
      @click.stop="handleShowAltClick"
    >
      <div class="swap-icon" />
      {{ $t('swap_overview_show') }} {{ alternativesCount - 1 }}
      {{ $t('swap_overview_alt_swap') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';
import InterestButtons from './InterestButtons';
import APill from '@/components/atomic/atoms/a-pill';
import events from '@/utils/helpers/events';
import { addRightClickTracker } from '@/utils/helpers/rightClick';
import UserActivityDetails from '../Proposition/UserCard/UserActivityDetails.vue';

export default {
  name: 'SwapOverview',

  components: {
    InterestButtons,
    APill,
    UserActivityDetails
  },

  props: {
    swapDetails: {
      type: Object,
      required: true
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      isUserList: 'swapList/isUserList',
      isImpersonated: 'app/isImpersonated',
      isMobile: 'screenSize/isMobile',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    isTriangle() {
      return this.swapDetails && this.swapDetails.type === 'triangleswap';
    },

    interests() {
      if (!this.swapDetails) return [];

      const interests = this.swapDetails.swapParts.map(sp => sp.interested);
      return interests;
    },
    userInterested() {
      return this.interests[0];
    },

    upArrowStatus() {
      if (this.isTriangle) {
        return this.interests[2];
      } else {
        return this.interests[1];
      }
    },

    userProposition() {
      return this.swapDetails.swapParts[0];
    },
    moveToProposition() {
      return this.swapDetails.swapParts[1];
    },
    thirdProposition() {
      return this.swapDetails.swapParts[2] || undefined;
    },

    alternativesCount() {
      return this.swapDetails.alternatives;
    },

    newPaywallsABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      blockSwapParty: 'swapList/blockSwapParty',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleShowAltClick() {
      if (this.primaryPropositionIsLimited && this.newPaywallsABTest) {
        this.setGlobalModal('AlternativeMatchesLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'alternative-matches-locked',
          src: 'swap-view'
        });
      } else {
        this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP_ALTERNATIVES });
        this.$emit('show-alt-click');
      }
    },

    triggerPropositionClickedEvent(propositionId) {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: 'chat-swapchain',
        propositionId: propositionId
      });
    },

    handlePropositionCardRightClick(propositionId) {
      addRightClickTracker(propositionId, 'chat-swapchain');
    },

    handlePropositionClicked(propositionId) {
      this.triggerPropositionClickedEvent(propositionId);
    },

    handleBlockSwapParty() {
      this.blockSwapParty({
        propositionId: this.thirdProposition.propositionId,
        blocked: true
      });
    },

    getSpacedRent(rent) {
      return rent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    getTypeColor(proposition) {
      if (proposition.swapType === 12) return 'one-to-two';
      else if (proposition.swapType === 11) return 'one-to-one';
      else if (proposition.swapType === 21) return 'two-to-one';

      return 'one-to-one';
    },

    getTypeLabel(proposition) {
      if (proposition.swapType === 12) return this.$t('general_1_for_2');
      else if (proposition.swapType === 21) return this.$t('general_2_for_1');
      else if (proposition.swapType === 11) return this.$t('general_1_for_1');

      return this.$t('general_1_for_1');
    },
    getUnavailableMessage(proposition) {
      if (proposition.inactivatedAt) {
        return this.$t('swap_overview_not_available');
      }
      if (proposition.inSwapProcess) {
        return this.$t('swap_overview_swap_in_process');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.current-user-activity-details {
  margin-top: 0 !important;
  border: 1px solid #ddd;
  border-top: none;
}

.swap-overview {
  display: flex;
  flex-direction: column;
}

.unavailable-message-icon {
  display: inline-block;
  vertical-align: middle;
  margin: 2px;

  @media ($mobile) {
    --icon-width: 15px !important;
    --icon-height: 15px !important;
  }
}

.swap-party-unavailable-message {
  @media ($mobile) {
    font-size: 0.6rem !important;
    padding: 0 !important;
  }
}

.my-proposition {
  position: relative;
  width: 100%;
  border: 1px solid rgba(28, 45, 65, 0.15);
  border-bottom: 1px solid rgba(28, 45, 65, 0.08);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: $text-primary;

  @media ($desktop) {
    padding: 12px 24px;
  }
}

.label {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}
.address {
  font-size: 0.9rem;
  color: $text-secondary;
  font-weight: 600;
}

.interest-arrows-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 100px;

  @media ($mobile) {
    padding: 12px 20px;
  }
}

.arrow {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;

  &-gray {
    background-image: url('../../assets/svg/swap-chain/arrow-gray.svg');
  }
  &-green {
    background-image: url('../../assets/svg/swap-chain/arrow-green.svg');
  }
  &-red {
    background-image: url('../../assets/svg/swap-chain/arrow-red.svg');
  }
}

.triangle-arrow-wrapper {
  align-self: center;
  margin: 0 5px;
}

.up {
  transform: rotate(180deg);
}
.down {
  transform: rotate(0deg);
}
.left {
  transform: rotate(90deg);
}

.swap-parties {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.swap-party {
  border: 1px solid rgba(28, 45, 65, 0.15);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding-top: 8px;
  text-decoration: none;
  color: $text-primary;

  &-remove-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  &-link {
    color: inherit;
    text-decoration: none;
    > * {
      margin: 3px 0;
      padding: 0 11px;
    }
  }

  &-half-size {
    max-width: 48%;
  }

  &-city {
    font-size: 0.8rem;
    color: $text-secondary;
    font-weight: 600;
    margin-top: 6px;
  }

  &-address {
    font-weight: 700;
    font-size: 0.9rem;
  }

  &-info {
    font-size: 0.8rem;
  }

  &-activity-bar {
    background-color: #f8f8fa;
    padding: 3px 11px;
    margin-top: 3px;

    @media ($desktop) {
      padding: 11px;
      margin-top: 7px;
    }
  }

  &-active {
    font-size: 0.7rem;
  }

  &-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    @media ($desktop) {
      padding: 8px;
    }
  }

  &-remove-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $transfer-blue;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
  }

  &-unavailable-message {
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
}

.removed-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.9);
}

.removed-info {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
}

.swap-alternatives-button {
  background-color: $transfer-blue;
  border-radius: 24px;
  padding: 12px;
  color: #fff;
  font-weight: 700;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;

  @media ($mobile) {
    margin: 12px 0;
  }

  &.pulse {
    animation: pulse 1s infinite;
  }
}
.swap-icon {
  height: 18px;
  width: 18px;

  margin-right: 10px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/alternate.svg');
}

.small {
  font-size: 0.8rem;
}

.proposition-type {
  display: inline-block;

  @media ($desktop) {
    float: right;
  }
}

.proposition-inline {
  margin-left: 3px;
}

.one-to-one {
  background-color: $transfer-blue;
  border-color: $transfer-blue;
  color: #fff;
}

.two-to-one {
  background-color: #ed63d2;
  border-color: #ed63d2;
  color: #fff;
}

.one-to-two {
  background-color: #ffc212;
  border-color: #ffc212;
  color: $brand-dark;
}

.red {
  color: red;
}

.visited-banner {
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: 10px;
  background-color: rgb(246, 195, 70);
  color: #fff;
  font-weight: 600;
  font-size: 0.7rem;

  @media ($mobile) {
    height: 45px;
    width: 45px;
    top: -20px;
    font-size: 0.5rem;
  }
}
</style>
