<template>
  <div :class="['button-container', { 'button-hidden': !showButton }]">
    <BaseButton
      :link="content.ButtonLink"
      :is-external="true"
      :class="{ larger: !isMobile }"
      class="sticky-button full-width"
      >{{ content.ButtonText }}</BaseButton
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

export default {
  name: 'StickyButton',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showButton: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  mounted() {
    window.addEventListener('scroll', throttle(this.handleScroll, 200));
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const screenHeight = window.innerHeight;
      this.showButton = scrollPosition > screenHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.button-container {
  display: grid;
  place-items: center;
  position: fixed;
  bottom: calc(16px + env(safe-area-inset-bottom));
  width: calc(100% - 32px);
  left: 16px;
  transition: bottom 0.3s ease-in-out;
  z-index: 1;

  @media ($small-screen) {
    .is-authenticated & {
      bottom: calc(16px + 58px + env(safe-area-inset-bottom));
    }
  }
}

.button-hidden {
  bottom: -48px !important;

  @media ($desktop) {
    bottom: -52px !important;
  }
}

.sticky-button {
  max-width: 1168px;
}
</style>
