<template>
  <div v-if="alternativeThreeParts > 0" class="three-part-notification">
    <BaseIcon
      :icon-file="'tabbar-swap-sug-white'"
      class="three-part-notification-icon"
    />
    <div class="three-part-notification-information">
      {{
        $t('proposition_three_part_notification', {
          address
        })
      }}
      <span class="link" @click="$emit('onShowSwapButtonPressed')">{{
        $t('swap_item_action')
      }}</span>
    </div>
  </div>
</template>

<script>
import { lbApiBeta } from '../../utils/axiosConfig';
import { mapGetters } from 'vuex';

export default {
  name: 'ThreePartSwapNotification',
  props: {
    propositionId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      alternativeThreeParts: 0,
      address: ''
    };
  },
  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },
  watch: {
    propositionId() {
      this.initialize();
    },
    primaryPropositionId() {
      this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (!this.primaryPropositionId || !this.propositionId) {
        console.log(
          'ThreePartSwapNotification this.primaryPropositionId',
          this.primaryPropositionId,
          this.propositionId
        );
        return;
      }

      lbApiBeta
        .get(
          `/api/triangleswap/${this.primaryPropositionId}/${this.propositionId}?showDenied=false`
        )
        .then(res => {
          if (res.data && res.data.alternatives) {
            this.address = res.data.swapParts[1].residences[0].streetAddress;
            this.alternativeThreeParts = res.data.alternatives;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.three-part-notification {
  border-radius: 10px;
  background: #409fff;
  color: white;
  padding: 15px 10px;
  max-width: 600px;
}

.three-part-notification a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.three-part-notification-icon {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  vertical-align: top;
}

.three-part-notification-information {
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 10px;
  font-size: 0.8rem;
  line-height: 18px;
}

.three-part-notification-information a {
  font-weight: bold;
  display: block;
  margin-top: 5px;
}

.link {
  color: #fff;
  font-weight: 700;
  text-decoration: underline;

  font-weight: 700;
  display: block;
  margin-top: 5px;

  cursor: pointer;
}
</style>
