<template>
  <div
    v-outside-click="closeNotificationCenter"
    class="notification-center-container"
  >
    <h2 class="notification-center-heading">
      {{ $t('notifications_title') }}
    </h2>
    <BaseSpinner v-if="isLoading" class="notification-loader"></BaseSpinner>

    <template v-if="fetchNotificationsFailed">
      <div v-if="!isLoading" class="no-notifications">
        <BaseIcon
          icon="bell"
          :width="40"
          :height="40"
          class="no-notifications-icon"
        />
        <h4 class="no-notifications-heading">
          {{ $t('notifications_error_title') }}
        </h4>
        <p class="no-notifications-text">
          {{ $t('notifications_error_text') }}
        </p>
      </div>
    </template>

    <template v-else-if="notificationsCount === 0">
      <div v-if="!isLoading" class="no-notifications">
        <BaseIcon
          icon="bell"
          :width="40"
          :height="40"
          class="no-notifications-icon"
        />
        <h4 class="no-notifications-heading">
          {{ $t('notifications_empty_title') }}
        </h4>
        <p class="no-notifications-text">
          {{ $t('notifications_empty_text') }}
        </p>
      </div>
    </template>

    <template v-else>
      <NotificationsGroup
        :notifications="allNotifications"
        @notificationItemClick="handleNotificationClick"
      >
        <template slot="heading">
          <br />
          <!-- <h4 class="section-header">{{ $t('notifications_last_month') }}</h4> -->
        </template>
      </NotificationsGroup>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import NotificationsGroup from './NotificationsGroup';
import outsideClick from '@/directives/outside-click';
import events from '@/utils/helpers/events';

export default {
  name: 'NotificationCenterContainer',

  components: {
    NotificationsGroup
  },
  directives: {
    outsideClick
  },
  computed: {
    ...mapGetters({
      notificationsCount: 'notificationCenter/getNotificationsCount',
      todaysNotifications: 'notificationCenter/getTodaysNotifications',
      lastWeeksNotifications: 'notificationCenter/getLastWeeksNotifications',
      lastMonthsNotifications: 'notificationCenter/getLastMonthsNotifications',
      allNotifications: 'notificationCenter/getNotifications',
      isLoading: 'notificationCenter/isLoading',
      fetchNotificationsFailed: 'notificationCenter/fetchNotificationsFailed'
    }),

    ...mapState('notificationCenter', ['fetchNotificationsFailed']),

    newPaywallsABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  methods: {
    ...mapActions({
      markAsRead: 'notificationCenter/markAsRead',
      closeNotificationCenter: 'menu/closeNotificationCenter',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    async handleNotificationClick(notification) {
      const notificationForMyVisitors =
        notification.type === 'VIEW_SUMMARY' ||
        notification.type === 'VIEW_SUMMARY_TRIAL';
      if (
        notificationForMyVisitors &&
        this.primaryPropositionIsLimited &&
        this.newPaywallsABTest
      ) {
        this.closeNotificationCenter();

        this.setGlobalModal('MyVisitorsLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'my-visitors-locked',
          src: 'notifications'
        });
      } else {
        this.markAsRead(notification.id);

        if (notification.webUrl) {
          this.$router.replace(notification.webUrl).catch(error => {
            console.error(error);
          });
        }

        this.closeNotificationCenter();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-center-container {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 20px;
  overflow-y: auto;
  user-select: none;
  z-index: 2001;

  @media ($mobile) {
    position: fixed;
    width: 100vw;
    top: 60px;
    bottom: calc(58px + env(safe-area-inset-bottom));
    right: 0;
  }

  @media ($desktop) {
    position: absolute;
    right: -12px;
    top: 55px;
    height: 80vh;
    width: 375px;
    border-radius: 10px;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.notification-center-heading {
  display: inline;
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 28px;
  margin-left: 28px;
}

.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  margin-top: 100px;
}

.no-notifications-icon {
  margin-bottom: 16px;
}

.no-notifications-heading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 12px;
}

.no-notifications-text {
  color: $text-secondary;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
}

.section-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 28px;
}

.notification-loader {
  display: inline-block;
  border-color: #6fb7ff !important;
  border-top-color: white !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: 10px;
}
</style>
