var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready && _vm.firstLoadDone && !_vm.primaryPropositionActive)?_c('NoActiveModal',{attrs:{"payment-page-proposition-id":_vm.primaryPropositionId,"in-free-area":_vm.primaryPropositionFreeArea,"source":"swap-list"}}):_c('div',{staticClass:"swap-list-wrapper",class:{
    'map-mode': _vm.swapListType === _vm.SWAP_LIST_TYPES.MAP
  }},[(_vm.shouldShowTutorial && _vm.hasEnoughSwaps)?_c('ContextualTutorial',{attrs:{"esc-close":_vm.escCloseTut,"show-tutorial":_vm.showTutorial},on:{"completed":_vm.tutorialCompleted}}):_vm._e(),[_c('AccountNotificationModal',{attrs:{"show":_vm.showAccountNotificationModal,"count":_vm.totalSwapCount},on:{"cta-click":_vm.handleNotificationCtaClick,"close":_vm.handleCloseNotification}}),_c('SwapTabNavigation',{attrs:{"tab-bg-color":"transparent"}}),_c('div',{staticClass:"content-wrapper"},[(_vm.isMobile && _vm.swaps && _vm.swaps.length > 0)?_c('div',{staticClass:"floating-container"},[_c('transition',{attrs:{"name":"fade-text","mode":"out-in","appear":""}},[(_vm.showMapButton && _vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('MapButton',{staticClass:"list",on:{"click":_vm.goToMap}}):_vm._e(),(_vm.swapListType === _vm.SWAP_LIST_TYPES.MAP)?_c('ListButton',{staticClass:"map",on:{"click":_vm.goToList}}):_vm._e()],1)],1):_vm._e(),(_vm.isMobile && _vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('MobileSwapHeader',{attrs:{"hidden":_vm.showContextualTutorial}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"desktop-filter-wrapper",class:{ 'map-mode': _vm.swapListType === _vm.SWAP_LIST_TYPES.MAP }},[_c('StickySidebar',[(_vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t('swap_list_search_title'))+" ")]):_vm._e(),(_vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('Search'):_vm._e(),(!_vm.distributedSwapsEnabled)?_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.$t('swap_list_filter_title'))+" ")]):_vm._e(),_c('div',{staticClass:"filter-box"},[_c('Filters')],1)],1)],1):_vm._e(),_c('div',{class:[
          'list-content',
          { 'map-mode': _vm.swapListType === _vm.SWAP_LIST_TYPES.MAP }
        ]},[(!_vm.isMobile && _vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('InfoBanner',{staticClass:"info-banner"}):_vm._e(),(
            !_vm.isMobile &&
            !_vm.shouldShowTutorial &&
            _vm.shouldShowOnboarding &&
            _vm.swapListType !== _vm.SWAP_LIST_TYPES.MAP
          )?_c('div',{staticClass:"onboarding-wrapper",class:{
            'onboarding-fade-grow-animation':
              _vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.COMPLETED
          }},[_c('OnboardingBanner')],1):_vm._e(),(_vm.isMobile)?_c('SwapFilterButtons',{attrs:{"is-loading":_vm.loading}}):_vm._e(),(!_vm.isMobile && _vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('SwapHeader',{attrs:{"show-filters":_vm.swapListType === _vm.SWAP_LIST_TYPES.LIST}}):_vm._e(),(_vm.swapListType === _vm.SWAP_LIST_TYPES.MAP)?_c('div',[_c('MapContainer')],1):(
            !_vm.distributedSwapsEnabled &&
            (_vm.loading || _vm.isMyPropositionsLoading || !_vm.firstLoadDone)
          )?_c('LoaderContainer',{attrs:{"is-mobile":_vm.isMobile,"progress":_vm.progress,"swap-item-mode":_vm.swapItemMode,"current-page-size":_vm.currentPageSize}}):(_vm.distributedSwapsEnabled && !_vm.firstLoadDone)?_c('LoaderContainer',{attrs:{"is-mobile":_vm.isMobile,"progress":_vm.progress,"swap-item-mode":_vm.swapItemMode,"current-page-size":_vm.currentPageSize}}):(
            !_vm.distributedSwapsEnabled &&
            !_vm.loading &&
            !_vm.isMyPropositionsLoading &&
            _vm.swaps &&
            _vm.swaps.length === 0
          )?_c('NoResult'):(
            _vm.distributedSwapsEnabled &&
            !_vm.loading &&
            !_vm.isMyPropositionsLoading &&
            _vm.swaps &&
            _vm.swaps.length === 0
          )?_c('ReturnLaterMessage',{attrs:{"distributed":_vm.postFilterCountWithDiffs,"undistributed":_vm.undistributedCount}}):_c('div',[(
              _vm.isMobile ||
              (!_vm.isMobile && _vm.swapItemMode === _vm.SWAP_ITEM_TYPES.CARDS) ||
              _vm.isUnansweredNewSwapFilterType
            )?_c('SwapContainer',{ref:'swap-container-ref',attrs:{"should-blur-last-swaps":_vm.shouldBlurLastSwaps}}):_c('SwapListContainer',{attrs:{"should-blur-last-swaps":_vm.shouldBlurLastSwaps}}),(
              _vm.distributedSwapsEnabled &&
              _vm.swapItemMode === _vm.SWAP_ITEM_TYPES.CARDS
            )?_c('NextNewSwapButton'):_vm._e(),(_vm.showUpdateWishLink)?_c('div',{staticClass:"adjust-wishes"},[_c('h3',[_vm._v(_vm._s(_vm.$t('swap_container_title')))]),_c('p',{staticClass:"adjust-wishes-action"},[_vm._v(" "+_vm._s(_vm.$t('swap_container_action'))+" ")]),_c('BaseButton',{attrs:{"link":((_vm.$routes.EDIT_PROPOSITION) + "/" + _vm.primaryPropositionId)}},[_vm._v(_vm._s(_vm.$t('swap_container_button')))])],1):_vm._e(),(_vm.distributedSwapsEnabled && _vm.showReturnLaterMessage)?_c('ReturnLaterMessage',{attrs:{"distributed":_vm.postFilterCountWithDiffs,"undistributed":_vm.undistributedCount}}):_vm._e(),(_vm.distributedSwapsEnabled && _vm.showReturnLaterModal)?_c('ReturnLaterModal',{on:{"close-return-later-modal":_vm.handleReturnLaterModalClose}}):_vm._e(),(!_vm.distributedSwapsEnabled && _vm.totalPages > 1)?_c('FlowPagination',{attrs:{"current-page":_vm.currentPage,"max-page":_vm.totalPages,"on-page-click":_vm.handlePageClick,"item-count":_vm.postFilterCountWithDiffs,"current-page-size":_vm.currentPageSize,"on-select-page-size":_vm.handlePageSizeSelected}}):_vm._e(),(_vm.distributedSwapsEnabled && _vm.hasMore)?_c('InfiniteScrollPagination',{ref:'infinite-scroll-ref',attrs:{"on-page-click":function () { return _vm.handlePageClick(_vm.currentPage + 1, 1); },"is-loading":_vm.loading}}):_vm._e()],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }