function show(context, value) {
  context.commit('show', value);
}

function hide(context) {
  context.commit('hide');
}

export default {
  hide,
  show
};
