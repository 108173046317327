var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-bar-content"},[(_vm.showIcon)?_c('span',[_c('BaseIcon',{staticClass:"flash",attrs:{"icon-file":"flash"}})],1):_vm._e(),_c('div',{staticClass:"progress-bar-bg",class:_vm.progress === '60%' || _vm.progress === '100%' ? 'pulsate' : ''},[_c('div',{staticClass:"progress-start",class:_vm.percentage === 0 ? 'right-border-radius' : '',style:({
        width: '0%',
        background: _vm.progress === '100%' && '#32de84'
      })}),_c('div',{class:[
        'progress',
        _vm.shouldAnimate ? 'progress-animation' : '',
        'left-border-radius'
      ],style:({
        'max-width': _vm.percentage + '%',
        background: _vm.progress === '100%'
      })})])])}
var staticRenderFns = []

export { render, staticRenderFns }