<template>
  <div ref="propositionSwitcher" class="proposition-switcher-wrapper">
    <div class="proposition-switcher" @click.exact.stop="toggleSwitcher">
      <div class="inner">
        <p class="text">
          <span v-if="!isMobile"
            >{{ $t('proposition_switcher_label') }}&nbsp;</span
          >
          <span class="bold address">{{ address }}</span>
          <icon
            icon="chevron"
            direction="down"
            color="$transfer-blue"
            :stroke-width="3"
          />
        </p>
      </div>
    </div>
    <proposition-list
      v-if="!isMobile && open && propositions"
      :close="closeList"
      :propositions="activePropositions"
    />
    <StandardModal
      v-if="isMobile && open && propositions"
      standard-modal-name="FilterModalHeader"
      :esc-close="true"
      :show-header="true"
      :title="$t('proposition_switcher_select_ad')"
      @close="closeList"
    >
      <proposition-list
        slot="content"
        :close="closeList"
        :propositions="activePropositions"
      />
    </StandardModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from '../Icons/Icon';
import StandardModal from '@/components/Modals/Standard';
import PropositionList from '../PropositionSwitcher/PropositionList';

export default {
  name: 'SwapListPropositionSwitcher',

  components: {
    Icon,
    StandardModal,
    PropositionList
  },

  data: function () {
    return {
      open: false,
      address: ''
    };
  },

  computed: {
    ...mapGetters({
      propositions: 'myPropositions/propositions',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryProposition: 'myPropositions/primaryProposition',
      loading: 'propositionSwitcher/loading',
      isMobile: 'screenSize/isMobile'
    }),

    activePropositions() {
      if (!this.propositions) return [];
      const active = this.propositions.filter(p => p.active);

      return active;
    }
  },

  watch: {
    propositions() {
      this.getPropositionAddress();
    },
    primaryProposition() {
      this.getPropositionAddress();
    }
  },

  mounted() {
    this.getPropositionAddress();
  },

  methods: {
    toggleSwitcher() {
      this.open ? this.closeList() : this.openList();
    },
    openList() {
      this.open = true;
    },
    closeList() {
      this.open = false;
    },
    getPropositionAddress() {
      const proposition = this.primaryProposition;

      if (!proposition || !proposition.residences) return;

      this.address = `${proposition.residences[0].streetAddress}${
        proposition.residences[1] ? ', ' : ''
      }${
        proposition.residences[1] ? proposition.residences[1].streetAddress : ''
      }`;
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-switcher-wrapper {
  position: relative;
  display: flex;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media ($mobile) {
    justify-content: center;
  }
}

.proposition-switcher {
  display: flex;
  padding: 20px 0;
  width: 100%;
  cursor: pointer;
  position: relative;

  @media ($mobile) {
    border-radius: 8px;
    padding: 20px 0 0 0;

    &-wrapper {
      @include content();
      order: 2;
    }
  }

  @media ($desktop) {
    justify-content: flex-start;
    padding: 0 0 16px 0;
  }
}

.inner {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%; //IE
  user-select: none;

  @media ($desktop) {
    justify-content: flex-start;
  }
}

.text {
  margin: 0;
  padding: 0;
  width: 100%; //IE
  text-align: center; //IE
  font-weight: 600;

  @media ($desktop) {
    text-align: start;
  }
}

.switcher {
  background-color: white;
  border: none;
  color: $transfer-blue;
  cursor: pointer;
  outline: none;
  position: relative;
  white-space: nowrap;

  &:active {
    background-color: white;
  }
}

.bold {
  font-weight: 600;
  margin-right: 8px;
}

.address {
  @media ($desktop) {
    color: $transfer-blue;
    text-decoration: underline;
  }
}
</style>
