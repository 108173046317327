<template>
  <div ref="reversePropositionCard" class="reverse-action-wrapper">
    <div :class="['reverse-action-container', { 'is-boosted': swap.boosted }]">
      <img
        v-if="
          (isMobile &&
            !swap.boosted &&
            currentShownResidence &&
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length > 0) ||
          (!isMobile &&
            currentShownResidence &&
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length > 0)
        "
        :src="
          currentShownResidence &&
          currentShownResidence.pictures &&
          currentShownResidence.pictures.length !== 0
            ? getImageThumbnail(currentShownResidence.pictures[0])
            : currentShownResidence.image || fallbackImage
        "
        class="primary-image"
      />
      <div
        v-else-if="
          isMobile &&
          swap.boosted &&
          currentShownResidence.pictures &&
          currentShownResidence.pictures.length > 0
        "
        :class="['primary-image-container']"
      >
        <img
          :src="
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length !== 0
              ? getImageThumbnail(currentShownResidence.pictures[0])
              : currentShownResidence.image || fallbackImage
          "
          :class="['primary-image']"
        />
      </div>

      <div v-else class="primary-image img-placeholder"></div>

      <!-- RECIDENCE ADDITIONAL IMAGES BLOCK -->
      <div
        v-if="
          isMobile &&
          swap.boosted &&
          currentShownResidence.pictures &&
          currentShownResidence.pictures.length >= 3
        "
        class="secondary-images-container"
      >
        <img :src="getImageThumbnail(currentShownResidence.pictures[1])" />
        <img :src="getImageThumbnail(currentShownResidence.pictures[2])" />
      </div>

      <div
        :class="[
          'reverse-action-text-and-btn-container',
          { 'is-boosted': swap.boosted }
        ]"
      >
        <p class="address">
          {{
            currentShownResidence ? currentShownResidence.streetAddress : '-'
          }}
        </p>
        <p class="info-text">{{ $t('reverse_interest_action_card_text') }}</p>
        <BaseButton
          :class="[
            isMobile
              ? 'grey outline rounded smaller full-width'
              : 'grey outline rounded smaller',
            'reverse-interest-btn'
          ]"
          @click="handleAttemptReverseInterestAction"
        >
          {{ $t('reverse_interest_action_btn_text') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import events from '@/utils/helpers/events';
import icons from '@/utils/icon-constants';
import { resizeImage } from '@/utils/helpers/image';

export default {
  name: 'BaseReverseActionPropositionCard',
  components: {},
  props: {
    swap: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icons: icons,
      currentResidence: 0
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),
    residence1() {
      return this.swap.residences ? this.swap.residences[0] : null;
    },
    is2to1() {
      return this.swap.residences?.length > 1;
    },
    currentShownResidence() {
      if (!this.is2to1) {
        return this.residence1;
      } else {
        return this.swap.residences[this.currentResidence];
      }
    },
    newPremiumPaywallABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    },
    interests() {
      this.swapList.forEach(swap => {
        if (swap.propositionId.toString() === this.swap.propositionId) {
          swap.interests = this.interests;
        }
      });
      return this.swap.details?.interests;
    }
  },
  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),
    handleReverseInterestMark() {
      this.$emit('reverse-no-interest-mark', this.swap.propositionId);
    },
    handleAttemptReverseInterestAction() {
      if (this.primaryPropositionIsLimited && this.newPremiumPaywallABTest) {
        this.setGlobalModal('ReverseInterestActionPaywall');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'reverse-no-interest-action',
          src: 'interest-mark'
        });
        return;
      }

      this.handleReverseInterestMark();
    },
    getImageThumbnail(image, size = '800x800') {
      return resizeImage(image, size);
    }
  }
};
</script>

<style lang="scss" scoped>
.reverse-action-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;

  @media ($desktop) {
    padding: 16px;
    border: 1px solid rgba(28, 45, 65, 0.15);
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    margin-top: 16px;
  }

  .close-btn-container {
    display: flex;
    justify-content: flex-end;

    .close-btn {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
    }
  }

  .reverse-action-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ($desktop) {
      flex-direction: row;
      gap: 15px;
    }

    &.is-boosted {
      @media ($mobile) {
        gap: 0px;
      }
    }

    .primary-image {
      min-width: 100%;
      border-radius: 10px 10px 0px 0px;
      opacity: 0.5;
      // To make opacity work in Safari
      will-change: opacity;

      object-fit: cover;
      aspect-ratio: 2 / 0.9;
      width: fit-content;

      @media ($desktop) {
        min-width: 40%;
        max-width: 40%;
        border-radius: 4px;
        object-fit: cover;
        height: 180px;
        aspect-ratio: 2 / 1.2;
      }

      &.img-placeholder {
        width: 100%;
        background-position: 50%;
        background-color: #f7f7f9;
        background-size: 50%;
        background-image: url('../assets/svg/placeholderImage/placeholder-image-large.svg');
      }
    }

    .secondary-images-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 1px;

      img {
        width: 100%;
        aspect-ratio: 2 / 0.9;
        object-fit: cover;
        opacity: 0.5;
        // To make opacity work in Safari
        will-change: opacity;
      }

      img:first-of-type {
        padding-right: 1px;
      }
    }

    .reverse-action-text-and-btn-container {
      display: flex;
      flex-direction: column;
      gap: 10.8px;
      width: 100%;
      padding: 15px;
      padding-top: 0px;

      &.is-boosted {
        @media ($mobile) {
          gap: 9.8px;
          margin-top: 8px;
        }
      }

      @media ($desktop) {
        width: 70%;
        padding: 0;
      }
      .address {
        margin: 0;
        color: #525252;
        font-weight: 700;

        @media ($desktop) {
          font-size: 17.6px;
        }
      }

      .info-text {
        font-size: 14px;
        margin: 0;
        color: #999999;
      }

      .reverse-interest-btn {
        color: #525252;
        cursor: pointer;

        @media ($desktop) {
          margin-top: 20px;
          width: fit-content;
        }
      }
    }
  }
}
</style>
