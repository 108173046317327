<template>
  <div ref="return-later-wrapper" class="return-later-wrapper">
    <BaseIcon
      :icon-file="'distribution-noleft'"
      :width="258"
      :height="258"
      class="return-later-icon"
    />
    <p>
      {{ $t('return_later_for_more_swaps_message') }}
    </p>
  </div>
</template>

<script>
import events from '@/utils/helpers/events';

export default {
  name: 'ReturnLaterMessage',
  props: {
    distributed: {
      type: [Number],
      default: 0
    },
    undistributed: {
      type: [Number],
      default: 0
    }
  },
  data() {
    return {
      isSeen: false
    };
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (this.isSeen) {
        return;
      }

      const rect = this.$refs['return-later-wrapper'].getBoundingClientRect();
      const inView =
        rect.top <
        (window.innerHeight || document.documentElement.clientHeight);

      if (inView) {
        this.isSeen = true;
        events.emitEvent(events.eventTypes.SEE, 'Return Later Message', {
          distributed: this.distributed,
          undistributed: this.undistributed
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.return-later-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;

  @media ($desktop) {
    margin-top: 32px;
    margin-bottom: 48px;
  }
}

.return-later-icon {
  margin-bottom: 12px;
}

.return-later-wrapper p {
  font-size: 15px;
  line-height: 1.5;
  max-width: 450px;
  margin: 0;
}
</style>
