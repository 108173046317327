<template>
  <label
    :class="[
      'radio-container',
      {
        reverse
      }
    ]"
    :style="{ padding: paddingY > 0 && `${paddingY}px 0` }"
  >
    <input
      type="radio"
      :name="name"
      :value="value"
      :disabled="disabled"
      :checked="checked"
      @change="handleChange"
      @input="handleInput"
    />

    <!-- Slot for label text -->
    <span
      :class="{
        'is-disabled': disabled,
        'is-checked': checked
      }"
    >
      <slot></slot>
    </span>

    <slot name="checkmark" v-bind="{ checked, disabled }">
      <div
        ref="radioCheckmark"
        class="radio-checkmark"
        :class="{
          'is-active': checked,
          'is-disabled': disabled,
          animate: checked
        }"
      ></div>
    </slot>
  </label>
</template>

<script>
export default {
  name: 'BaseRadioButtonNew',

  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [String, Number],
      default: ''
    },
    variant: {
      type: String,
      default: 'default'
    },
    paddingY: {
      type: Number,
      default: 0
    },
    labelMaxWidth: {
      type: String,
      default: ''
    },
    reverse: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    checked() {
      return this.value !== '' && this.selected !== ''
        ? this.value === this.selected
        : false;
    }
  },

  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
    handleInput() {
      this.$emit('input', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.animate {
  transition: background-color 0.4s, border-color 0.4s;
}
.radio-container {
  &:hover > .radio-checkmark {
    background-color: #f1f2f6;
    border: 1px solid #dbdce0;
  }
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  span.is-disabled {
    color: #ebebeb;
  }
  span.is-checked {
    font-weight: 600;
  }

  .radio-checkmark {
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 12px;
    background-color: #ffffff;
    border: 1px solid #dbdce0;
  }

  .radio-checkmark.is-active {
    background-color: #2c3e50;
    border: 1px solid #2c3e50;
  }

  .radio-checkmark.is-active::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: hsl(0, 0%, 100%);
  }

  .radio-checkmark.is-disabled {
    border: 1px solid #eaecf0;
    background-color: #f2f4f7;
    cursor: default;
  }
}

.reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.radio-container input {
  display: none;
}
</style>
