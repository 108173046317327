import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const INTEREST_STATUSES = {
  INTERESTED: 'interested',
  NOT_INTERESTED: 'not_interested'
};

const state = {
  markInterestDone: false,
  noInterestMarksLeft: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
