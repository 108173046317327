<template>
  <div class="list-button-wrapper">
    <BaseButton
      class="rounded filter-button"
      icon-file="list-dark"
      :icon-size="18"
      @click="$emit('click')"
    >
      {{ $t('swaplist_button_list_view') }}</BaseButton
    >
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  name: 'ListButton',

  components: {
    BaseButton
  }
};
</script>

<style lang="scss" scoped>
.list-button-wrapper {
  & .button {
    padding: 10px 24px;
    border: 1px solid var(--RC-Text-1-0B182C, #0b182c);

    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
      0 2px 4px -2px rgba(16, 24, 40, 0.06);
  }
}
</style>
