<template>
  <div class="swap-item block-pulse">
    <div class="swap-item-slider">
      <div class="img-placeholder"></div>
    </div>

    <div v-if="isMobile" class="swap-item-header">
      <div class="profile-picture"></div>
      <div class="address-info">
        <div class="address block">address</div>

        <div class="postal-area block">postalarea</div>
      </div>

      <div class="icon-area">
        <div :class="['favourite-icon block']"></div>
        <div :class="['proposition-type block']">label</div>
      </div>
    </div>

    <div class="swap-item-body">
      <div v-if="!isMobile" class="swap-item-header">
        <!-- <div class="profile-picture"></div> -->

        <div class="address-info">
          <div class="address block">address</div>

          <div class="postal-area block">postalarea</div>
        </div>

        <div class="icon-area">
          <!-- <div :class="['favourite-icon block']"></div> -->
          <!-- <div :class="['proposition-type block']">label</div> -->
        </div>
      </div>

      <div v-if="isMobile">
        <div class="interest-bar">
          <div class="interest-area">
            <div class="interest-progress block">0%</div>
          </div>
        </div>

        <div class="residence-info-bar block">
          <span class="extra-bold">0</span> rum &#8729;
          <span class="extra-bold">0</span> kvm &#8729; Våning

          <span class="extra-bold">0</span>
          &#8729;
          <span class="extra-bold">1000</span>
          kr/månad
        </div>
      </div>

      <div v-else class="desktop-body-center">
        <div class="desktop-body-center-info block">
          <span class="extra-bold">0</span> rum &#8729;
          <span class="extra-bold">0</span> kvm &#8729; Våning
          <span class="extra-bold">{0</span>
          <br />
          <span class="extra-bold">1000</span>
          kr/månad
        </div>

        <div class="interest-bar">
          <div class="interest-area">
            <div class="interest-progress block">0%</div>
          </div>
        </div>
      </div>

      <!-- <div class="amenities-bar">
        <div class="action-button block">actionbtn</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwapItemLoading',

  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-item {
  margin: 16px 0;

  @media ($desktop) {
    display: flex;
    border: 1px solid #ddd;
    border-radius: $standard-radius;
    box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.15);
    padding: 16px;
  }

  &-body {
    @media ($desktop) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.desktop-body-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &-info {
    font-weight: 600;
    font-size: 0.85rem;
    margin-top: 20px;
  }
}

.swap-item-header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon-area {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.proposition-type {
  padding: 5px 8px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.6rem;

  &.pink {
    background-color: rgb(251, 225, 245);
    color: rgb(236, 95, 206);
  }
  &.blue {
    background-color: rgb(218, 231, 245);
    color: $transfer-blue;
  }
  &.yellow {
    color: rgb(246, 197, 46);
    background-color: rgb(254, 248, 232);
  }
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/heart-empty.svg');
  margin: 0 6px;
}

.swap-item-slider {
  height: 180px;
  width: 100%;
  min-width: 100%;
  position: relative;

  @media ($desktop) {
    margin: 0 16px 0 0;
    width: 40%;
    min-width: 25vw;
    max-width: 25vw;
  }
}

.swap-type-banner {
  position: absolute;
  top: 24px;
  left: 0;
  padding: 6px;
  padding-left: 12px;
  background-color: $dark-blue;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  font-size: 0.8rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.img-placeholder {
  height: 100%;
  width: 100%;
  min-width: 100%;
  background-size: contain;
  background-color: #f7f7f9;
  background-position: center;
  background-size: 50%;
  opacity: 0.8;
  background-image: url('../../../assets/svg/placeholderImage/placeholder-image-large.svg');
}

.profile-picture {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: 70%;
  background-color: #ebebeb;
  background-position: center;
  opacity: 0.8;
  background-image: url('../../../assets/img/nopic-square.png');
}

.address-info {
  margin-left: 6px;
}

.address {
  font-size: 1rem;
  font-weight: 700;

  &.secondary {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }
}

.postal-area {
  margin-top: 3px;
  font-size: 0.7rem;
  color: $text-secondary;
  font-weight: 600;
}

.residence-info-bar {
  font-weight: 600;
  font-size: 0.85rem;
  border-bottom: 1px solid #ddd;
  padding: 6px 0;
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 6px 0;
}
.interest-area {
  display: flex;
  align-items: center;
}
.interest-progress {
  margin-left: 3px;
  font-weight: 600;
  color: $text-secondary;
  font-size: 0.8rem;
}

.amenities-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;

  // @media ($desktop) {
  //   border-top: 1px solid #ddd;
  // }
}

.action-button {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  color: $transfer-blue;
}

.extra-bold {
  font-weight: 700;
}
</style>
