import PaymentHistory from '../views/Account/PaymentHistory';
import MyPropositions from '../views/MyPropositions/MyPropositions';
import HandleSubscription from '../views/Subscription/HandleSubscription';
import PageNotFound from '../views/PageNotFound/PageNotFound';
import Proposition from '../views/Proposition/Proposition';
import EditProposition from '../views/MyPropositions/EditProposition';
import EditDescription from '../views/MyPropositions/EditDescription';
import EditPropositionDetails from '../views/MyPropositions/EditPropositionDetails';
import EditPropositionWish from '../views/MyPropositions/EditPropositionWish';
import EditPropositionSettings from '../views/MyPropositions/EditPropositionSettings';
import EditWishAreas from '../views/MyPropositions/EditWishAreas';
import EditPropositionPhotos from '../views/MyPropositions/EditPropositionPhotos';
import PropositionGallery from '../views/Proposition/PropositionGallery';
import Favourites from '../views/MyFavourites/MyFavourites';
import PayProposition from '../views/Payments/Proposition';
import ChangePaymentMethod from '../views/Payments/ChangePaymentMethod';
import ChangeToKlarna from '../views/Subscription/ChangeToKlarna';
import PaymentDoneProposition from '../views/Confirmations/PaymentDoneProposition';
import PaymentDoneVisibility from '../views/Confirmations/PaymentDoneVisibility';
import SubscriptionCancelled from '../views/Confirmations/SubscriptionCancelled';
import SubscriptionOfferAccepted from '../views/Confirmations/SubscriptionOfferAccepted';
import UpdateKlarnaTokenDone from '../views/Confirmations/UpdateKlarnaTokenDone';
import UpdateSubscriptionDone from '../views/Confirmations/UpdateSubscriptionDone';
import AutoSubscriptionDone from '../views/Confirmations/AutoSubscriptionDone';
import SwapList from '../views/SwapList/SwapList';
import AppExtraVisibilityPayment from '../views/Payments/AppExtraVisibility';
import Search from '@/views/Search/Index';
import SearchCoverageUnsubscribe from '@/views/Search/UnsubscribeView';
import BlogCollection from '@/views/Blog/BlogCollection';
import BlogPost from '@/views/Blog/BlogPost';
import EditProfile from '@/views/Account/EditProfile';
import EditUserCredentials from '@/views/Account/EditUserCredentials';
import ChatView from '@/views/Chat/Index';
import SwapDetails from '@/views/SwapDetails/Index';
import MyVisitors from '../views/MyPropositions/MyVisitors';
import LoginView from '../views/Login/LoginView';
import LogoutView from '../views/Logout/LogoutView';
import PendingPayments from '../views/Payments/PendingPayments';
import Swaps from '../views/Swaps/Swaps';
import Help from '../views/ContentPages/Help';
import StaticPage from '../views/ContentPages/StaticPage';
import RichContentPage from '../views/ContentPages/RichContentPage';
import LocalitySitemap from '../views/Sitemap/LocalitySitemap';
import Impressum from '../views/ContentPages/Impressum';
import BlockedPropositions from '../views/MyPropositions/BlockedPropositions';
import Sitemap from '../views/Sitemap/Sitemap';
import Matches from '../views/Matches/Matches';
import CookiePolicy from '../views/CookiePolicy/CookiePolicy';
import BoostSummary from '../views/Boost/BoostSummary';

import routes from './routes.constants';
import StartAnimation from '../components/Abundance/StartAnimation.vue';

const routeTable = [
  {
    path: routes.BLOG_REDIRECT,
    redirect: routes.NEWS
  },
  {
    path: routes.NEWS,
    component: BlogCollection,
    meta: { public: true }
  },
  {
    path: `${routes.NEWS}/:title/:id`,
    component: BlogPost,
    meta: { public: true }
  },
  {
    path: '/content/:slug',
    component: StaticPage,
    meta: { public: true }
  },
  {
    path: '/content/' + routes.GENERAL_TERMS,
    component: StaticPage,
    meta: { public: true }
  },
  {
    path: routes.GENERAL_TERMS,
    redirect: '/content/' + routes.GENERAL_TERMS,
    meta: { public: true }
  },
  {
    path: '/c/:slug',
    component: RichContentPage,
    meta: { public: true }
  },
  {
    path: '/i/:adm1?/:adm2?/:adm3?/:pos?',
    component: LocalitySitemap,
    meta: { public: true }
  },
  {
    path: routes.MESSAGES,
    component: ChatView
  },
  {
    path: `${routes.MESSAGES}/:roomId`,
    component: ChatView
  },
  { path: '/chat/:roomId', redirect: `${routes.MESSAGES}/:roomId` },
  { path: '/chat', redirect: routes.MESSAGES },
  {
    path: `${routes.SWAP_LIST}/:otherUserPropositionId`,
    component: SwapList
  },
  {
    path: routes.SWAP_LIST,
    component: SwapList
  },
  {
    path: routes.SWAPS,
    component: Swaps
  },
  {
    path: routes.PAYMENT_HISTORY,
    component: PaymentHistory
  },
  {
    path: routes.CHANGE_PAYMENT_METHOD,
    component: ChangePaymentMethod
  },
  {
    path: `${routes.PAYMENT_PROPOSITION}/:propositionId`,
    component: PayProposition
  },
  {
    path: `${routes.PAYMENT_PROPOSITION}`,
    component: PayProposition
  },
  {
    path: routes.CHANGE_TO_KLARNA,
    exact: true,
    component: ChangeToKlarna
  },
  {
    path: routes.PAYMENT_DONE_PROPOSITION,
    component: PaymentDoneProposition
  },
  {
    path: routes.PAYMENT_DONE_VISIBILITY,
    component: PaymentDoneVisibility
  },
  {
    path: routes.CHANGE_SUBSCRIPTION_DONE,
    component: UpdateSubscriptionDone
  },
  {
    path: routes.AUTO_SUBSCRIPTION_DONE,
    component: AutoSubscriptionDone
  },
  {
    path: routes.SUBSCRIPTION_CANCELLED,
    component: SubscriptionCancelled
  },
  {
    path: routes.SUBSCRIPTION_OFFER_ACCEPTED,
    component: SubscriptionOfferAccepted
  },
  {
    path: routes.UPDATE_TOKEN_DONE_KLARNA,
    component: UpdateKlarnaTokenDone
  },
  {
    path: routes.FAVOURITES,
    component: Favourites
  },
  {
    path: routes.MY_PROPOSITIONS,
    component: MyPropositions
  },
  {
    path: `${routes.EDIT_WISH_AREAS}/:propositionId/:wishIndex`,
    component: EditWishAreas
  },
  {
    path: `${routes.EDIT_PROP_SETTINGS}/:propositionId`,
    component: EditPropositionSettings
  },
  {
    path: `${routes.EDIT_PROPOSITION_PHOTOS}/:propositionId/:residenceIndex`,
    component: EditPropositionPhotos
  },
  {
    path: `${routes.PROPOSITION}/:id`,
    component: Proposition,
    meta: { public: true }
  },
  {
    path: `${routes.PROPOSITION}/:id/:residenceIndex`,
    component: Proposition,
    meta: { public: true }
  },
  {
    path: `${routes.PROPOSITION_GALLERY}/:propositionId`,
    component: PropositionGallery,
    meta: { transitionName: 'slide-over' }
  },
  {
    path: `${routes.PROPOSITION_GALLERY}/:propositionId/:residenceIndex`,
    component: PropositionGallery,
    meta: { public: true, transitionName: 'slide-over' }
  },
  {
    path: `${routes.PROPOSITION_GALLERY}/:propositionId/:residenceIndex/:imageIndex`,
    component: PropositionGallery,
    meta: { public: true, transitionName: 'slide-over' }
  },
  {
    path: `${routes.HANDLE_SUBSCRIPTION}/:id`,
    component: HandleSubscription
  },
  {
    path: `${routes.APP_EXTRA_VISIBILITY_PAYMENT}/:propositionId`,
    component: AppExtraVisibilityPayment
  },
  {
    path: `${routes.MY_VISITORS}/:propositionId`,
    component: MyVisitors
  },
  {
    path: routes.BLOCKED_PROPOSITIONS,
    component: BlockedPropositions
  },
  {
    path: routes.EDIT_PROFILE,
    component: EditProfile
  },
  {
    path: routes.EDIT_USER_CREDENTIALS,
    component: EditUserCredentials
  },
  {
    path: routes.TRIAL_INFO,
    component: StartAnimation
  },
  {
    path: routes.FREEMIUM_INFO,
    component: StartAnimation
  },
  {
    path: `${routes.EDIT_PROPOSITION}/:propositionId`,
    component: EditProposition
  },
  {
    path: `${routes.EDIT_PROPOSITION_DESCRIPTION}/:propositionId/:residenceIndex`,
    component: EditDescription
  },
  {
    path: `${routes.EDIT_PROPOSITION_DETAILS}/:propositionId/:residenceIndex`,
    component: EditPropositionDetails
  },
  {
    path: `${routes.EDIT_PROPOSITION_WISHES}/:propositionId/:wishIndex`,
    component: EditPropositionWish
  },
  {
    path: routes.OLD_SWAP_CHAIN_MANUAL,
    redirect: `${routes.SWAP_DETAILS}/:pid1/:pid2`
  },
  {
    path: routes.OLD_SWAP_CHAIN_DIRECT,
    redirect: `${routes.SWAP_DETAILS}/:pid1/:pid2`
  },
  {
    path: routes.OLD_SWAP_CHAIN_TRIANGLE,
    redirect: p => {
      const { query } = p;
      if (query.ad3) {
        return `${routes.SWAP_DETAILS}/triangle/:pid1/:pid2/${query.ad3}`;
      }
      return `${routes.SWAP_DETAILS}/triangle/:pid1/:pid2`;
    }
  },
  {
    path: `${routes.SWAP_DETAILS}/:propositionIdAPart/:propositionIdBPart`,
    component: SwapDetails
  },
  {
    path: `${routes.SWAP_DETAILS}/triangle/:propositionIdAPart/:propositionIdBPart/:propositionIdCPart?`,
    component: SwapDetails
  },
  {
    path: routes.LOGIN,
    alias: [routes.LOGIN_RESET_PASSWORD],
    component: LoginView,
    meta: { public: true }
  },
  {
    path: routes.LOGOUT,
    component: LogoutView,
    meta: { public: true }
  },
  {
    path: routes.PAYMENT_PENDING,
    component: PendingPayments
  },
  {
    path: routes.SEARCH,
    component: Search,
    meta: { public: true }
  },
  {
    path: routes.MATCHES,
    component: Matches
  },
  {
    path: `${routes.MATCHES}/:id`,
    component: Matches
  },
  {
    path: routes.HELP,
    component: Help,
    meta: { public: true }
  },
  {
    path: '/impressum',
    component: Impressum,
    meta: { public: true }
  },
  {
    path: '/sitemap*',
    component: Sitemap,
    meta: { public: true }
  },
  {
    path: routes.COOKIE_POLICY,
    component: CookiePolicy,
    meta: { public: true }
  },
  {
    path: '/unsub/sc/:coverageId/:hash',
    redirect: `${routes.SEARCH_COVERAGE_UNSUBSCRIBE}/:coverageId/:hash`,
    meta: { public: true }
  },
  {
    path: `${routes.SEARCH_COVERAGE_UNSUBSCRIBE}/:coverageId/:hash`,
    component: SearchCoverageUnsubscribe,
    meta: { public: true }
  },
  {
    path: routes.BOOST_SUMMARY,
    component: BoostSummary
  },
  {
    path: routes.NOT_FOUND,
    component: PageNotFound,
    meta: { public: true }
  },
  {
    path: '*',
    component: PageNotFound,
    meta: { public: true }
  }
];

export default routeTable;
