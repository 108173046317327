var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"conversationContentWrapper",staticClass:"conversation-content-wrapper",class:[
    _vm.mode,
    {
      'used-on-page': _vm.usedOnPage,
      'used-in-matches': _vm.usedInMatches,
      'height-adjustment': !_vm.isIos
    }
  ],attrs:{"id":"conversation-content-wrapper"}},[(_vm.isLoading || !_vm.interestsInfo.isDoneLoading)?_c('section',{staticClass:"message-loader"},[_c('div',{staticClass:"spinner-wrapper"},[_c('ASpinner',{attrs:{"classes":['loading-spinner']}})],1)]):_vm._e(),(_vm.room && !_vm.standalone)?_c('section',{staticClass:"back-wrapper"},[_c('AAccordion',{attrs:{"hide-borders":true,"close-on-outside-click":true,"active-on-init":_vm.detailsVisible,"watch-active":true,"hide-arrow":true},on:{"change":function($event){_vm.detailsVisible = $event}}},[_c('template',{slot:"title"},[(!_vm.usedInMatches)?_c('router-link',{staticClass:"back-link",class:{ hidden: !_vm.isMobile },attrs:{"to":_vm.$routes.MESSAGES}},[_c('span',{staticClass:"icon-wrapper"},[_c('BaseIcon',{attrs:{"icon-file":'arrow-back'}})],1)]):_c('div',{staticClass:"back-link",class:{ hidden: !_vm.isMobile },on:{"click":_vm.backArrowClicked}},[_c('span',{staticClass:"icon-wrapper"},[_c('BaseIcon',{attrs:{"icon-file":'arrow-back'}})],1)]),_c('p',{staticClass:"text-center back-title title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.titleHtml)}})]),_c('span',{class:[
            'icon-wrapper',
            'swap-icon-wrapper',
            { 'is-open': _vm.detailsVisible }
          ]},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":'chevron-down'}})],1)],1),_c('SwapDetails',{attrs:{"proposition-ids":_vm.currentPropositionIds}})],2)],1):_vm._e(),(_vm.usedInMatches && _vm.inRoom.id == 0 && !_vm.matchIsInactive)?_c('div',{staticClass:"new-match-message empty-area"},[_c('BaseIcon',{staticClass:"chat-icon",attrs:{"icon-file":'chat-icon',"width":88,"height":88}}),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('matches_emptychat_title')))]),_c('p',{staticClass:"paragraph"},[_vm._v(" "+_vm._s(_vm.$t('matches_emptychat_body'))+" ")])],1):_vm._e(),(!_vm.room && !_vm.isLoading && _vm.standalone && _vm.interestsInfo.isDoneLoading)?_c('section',{staticClass:"empty-area"},[(_vm.isFullInterestSwap)?[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('chat_conversation_not_started_title')))]),_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(
          _vm.$t('chat_conversation_not_started_text', {
            names: _vm.propositionsText
          })
        )}})]:(!_vm.isFullInterestSwap && _vm.interestsInfo.isDoneLoading)?[_c('BaseIcon',{staticClass:"chat-icon",attrs:{"icon-file":'chat-icon',"width":88,"height":88}}),_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(
          _vm.$t('chat_conversation_not_started_text_not_all_intresested', {
            names: _vm.propositionsText
          })
        )}})]:_vm._e()],2):_vm._e(),(!_vm.isLoading && _vm.room && _vm.data && _vm.owners)?_c('section',{staticClass:"conversations-wrapper",class:[
      {
        iphone: _vm.isIos,
        'interest-hidden': _vm.inactivatedPropositionExists,
        'no-interest-level-toggle': !_vm.showInterestLevelToggle,
        'is-freemium-chat-unlocked':
          _vm.primaryProposition.isFreemium && !_vm.room.isFreemiumLocked
      },
      [_vm.mode]
    ],attrs:{"id":"conversations-wrapper"}},[(_vm.room.initiatorId || _vm.orderedMessages.length > 0)?_c('p',{staticClass:"text-small text-center text-started"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('chat_conversation_started_text', { date: _vm.roomCreatedText, name: _vm.owners[_vm.room.initiatorId] ? _vm.owners[_vm.room.initiatorId].displayName : _vm.owners[_vm.orderedMessages[0].senderId].displayName }))+" ")])]):_vm._e(),(_vm.data.hasMore && !_vm.isLoadingMore && _vm.interestsInfo.isDoneLoading)?_c('p',{staticClass:"load-more text-link",on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" "+_vm._s(_vm.$t('chat_conversation_show_more'))+" ")]):(_vm.isLoadingMore)?_c('div',{staticClass:"load-more-spinner"},[_c('div',{staticClass:"spinner-small-wrapper"},[_c('ASpinner',{attrs:{"classes":['loading-spinner']}})],1)]):_vm._e(),(_vm.interestsInfo.isDoneLoading)?_c('div',[_vm._l((_vm.orderedMessages),function(message,index2){return _c('Message',{key:index2 + message.id,attrs:{"message":message,"owners":_vm.owners,"user-id":_vm.userId,"is-mobile":_vm.isMobile,"owner-interests":_vm.ownerInterests,"animate":index2 > _vm.initialMessagesLength - 1,"used-in-matches":_vm.usedInMatches}})}),(
          !_vm.isFullInterestSwap && _vm.interestsInfo.isDoneLoading && !_vm.usedInMatches
        )?_c('div',{staticClass:"missing-interest-banner not-standalone",domProps:{"innerHTML":_vm._s(_vm.$t('chat_conversation_start_conversation'))}}):_vm._e()],2):_vm._e()]):_vm._e(),(!_vm.isLoading)?_c('section',{staticClass:"control-wrapper",class:[
      _vm.mode,
      {
        iphone: _vm.isIos
      },
      { 'no-control': !_vm.showInterestLevelToggle }
    ]},[(_vm.showInterestLevelToggle)?_c('div',{staticClass:"interest-wrapper"},[_c('AAccordion',{attrs:{"hide-borders":true,"active-on-init":_vm.interestAccordionActive,"arrow-reverse":true,"close-on-outside-click":true,"locked":false,"watch-active":true},on:{"change":_vm.handleAccordionToggle}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[(
              _vm.interestsInfo.interestLevel === null || _vm.interestAccordionActive
            )?_c('span',{staticClass:"interest-unselected-title"},[_vm._v(" "+_vm._s(_vm.usedInMatches ? _vm.$t('chat_conversation_interest_title_variant') : _vm.$t('chat_conversation_interest_title')))]):_c('ChatInterestSelect',{class:{ 'interest-unselected-title': _vm.interestAccordionActive },attrs:{"color":_vm.currentInterestColor}},[_vm._v(" "+_vm._s(_vm.currentInterestText)+" ")])],1),_c('div',{staticClass:"interest-level-selector-wrapper",class:{ desktop: !_vm.isMobile }},[_c('ChatInterestSelect',{attrs:{"color":"green","selected":_vm.interestsInfo.interested === true &&
              _vm.interestsInfo.interestLevel === 0},on:{"click":function($event){return _vm.handleInterestLevelClick(0)}}},[_vm._v(" "+_vm._s(_vm.$t('chat_interest_select_0'))+" ")]),_c('ChatInterestSelect',{attrs:{"color":"blue","selected":_vm.interestsInfo.interested === true &&
              _vm.interestsInfo.interestLevel === 1},on:{"click":function($event){return _vm.handleInterestLevelClick(1)}}},[_vm._v(" "+_vm._s(_vm.$t('chat_interest_select_1'))+" ")]),_c('ChatInterestSelect',{attrs:{"color":"orange","selected":_vm.interestsInfo.interested === true &&
              _vm.interestsInfo.interestLevel === 2},on:{"click":function($event){return _vm.handleInterestLevelClick(2)}}},[_vm._v(" "+_vm._s(_vm.$t('chat_interest_select_2'))+" ")]),_c('ChatInterestSelect',{attrs:{"color":"red","selected":_vm.interestsInfo.interested === false},on:{"click":function($event){return _vm.handleInterestLevelClick(3)}}},[_vm._v(" "+_vm._s(_vm.$t('chat_interest_select_3'))+" ")])],1)])],1):_vm._e(),(!_vm.isLoading && _vm.interestsInfo.isDoneLoading)?_c('div',{staticClass:"new-message-wrapper",class:[_vm.mode]},[(_vm.showChatIsLockedErrorMsg)?_c('p',{staticClass:"chat-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('freemium_chat_locked_error_msg'))+" ")]):_vm._e(),_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"area-wrapper"},[_c('AArea',{staticClass:"input-area",attrs:{"id":"input-area","disabled":_vm.isSending || _vm.isLoading || _vm.matchIsInactive,"rows":_vm.areaRows},on:{"focus":function($event){return _vm.handleAreaFocus()},"outside":function($event){return _vm.handleAreaBlur($event)}},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1),_c('div',{staticClass:"send-wrapper"},[_c('AButton',{staticClass:"is-rounded is-secondary",attrs:{"is-loading":_vm.isSending,"is-disabled":!_vm.isNewMessageValid || _vm.isSending || _vm.isLoading,"is-small":_vm.isMobile},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v(_vm._s(_vm.$t('chat_conversation_send')))])],1)])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }