<template>
  <div v-if="!isLoading" class="locality-sitemap">
    <div class="page-container">
      <div v-if="geolocation === null" class="link-list">
        <h1>{{ $t('sitemap_areas_in', { area: parentArea }) }}</h1>
        <div v-for="geo of geolocations" :key="geo.id">
          <a
            v-if="locationPath.length > 0"
            :href="`/i/${locationPath.join('/')}/${geo.name}`"
            >{{ geo.name }}</a
          >
          <a v-else :href="`/i/${geo.name}`">{{ geo.name }}</a>
        </div>
      </div>
      <div v-else>
        <SeoLandingPage
          :replacements="contentReplacements"
          :propositions="geolocation.propositions"
          :area-name="geolocation.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SeoLandingPage from '../ContentPages/SeoLandingPage.vue';

export default {
  name: 'LocalitySitemap',

  components: {
    SeoLandingPage
  },

  data() {
    return {
      locationPath: [],
      geolocations: [],
      geolocation: null,
      contentReplacements: null,
      isLoading: true
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('sitemap_areas_in', { area: this.parentArea })
      };
    }
  },

  computed: {
    parentArea() {
      if (this.locationPath.length === 0) {
        return this.$t('sitemap_country_name');
      } else {
        return this.locationPath[this.locationPath.length - 1];
      }
    }
  },

  async created() {
    const adm1 = this.$route.params.adm1;
    const adm2 = this.$route.params.adm2;
    const adm3 = this.$route.params.adm3;
    const pos = this.$route.params.pos;
    const path = [adm1, adm2, adm3, pos].filter(l => !!l);
    window.scrollTo(0, 0);

    try {
      const country = this.$country.getCountry();
      const response = await axios.get(
        `https://feed.lagenhetsbyte.se/i/${country}/${path.join('/')}`
      );

      this.isLoading = false;

      if (response.status !== 200) {
        return;
      }

      this.locationPath = response.data.locationPath;
      this.geolocations = response.data.geolocations;

      if (response.data.geolocation) {
        this.geolocation = {
          ...response.data.geolocation,
          propositions: response.data.propositions
        };
        this.contentReplacements = {
          '{area}': this.geolocation.name
        };
      }
    } catch (error) {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
.locality-sitemap {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 79px;
    padding-bottom: 0;
  }
}

.page-container {
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.link-list {
  max-width: 1168px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  @media ($desktop) {
    margin-top: 50px;
  }

  h1 {
    font-size: 26px;

    @media ($desktop) {
      font-size: 28px;
    }
  }

  a {
    font-size: 18px;
    display: inline-block;
    margin-bottom: 5px;
  }
}
</style>
