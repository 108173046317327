function setMarkInterestDone(state, value) {
  state.markInterestDone = value;
}

function setNoInterestMarksLeft(state, value) {
  state.noInterestMarksLeft = value;
}

export default {
  setMarkInterestDone,
  setNoInterestMarksLeft
};
