<template>
  <div class="interest-buttons">
    <div :class="['buttons-wrapper', { 'blur-content': blurContent }]">
      <div
        :class="[
          'interest-btn not-interested',
          {
            'is-not-interested': interested === false
          },
          { 'reverse-is-shown': reverseIsShown }
        ]"
        @click.prevent.stop="handleNoClick"
      >
        <BaseIcon
          :icon-file="interested === false ? 'close-new-white' : 'close-new'"
          :width="14"
          :height="14"
          class="button-icon"
        />
        {{ $t('interest_button_hide') }}
      </div>
      <div
        :class="[
          'interest-btn interested',
          {
            'is-interested': interested === true,
            'background-fill-rl':
              !noInterestMarksLeft &&
              interested === true &&
              shouldAnimateYesButton
          }
        ]"
        @click.prevent.stop="handleYesClick"
      >
        <div class="button-content">
          <YesClickAnimation
            v-if="!noInterestMarksLeft && interested && shouldAnimateYesButton"
          ></YesClickAnimation>
          <BaseIcon
            :icon-file="interested ? 'icon-check3-white' : 'icon-check3'"
            :width="14"
            :height="14"
            class="button-icon"
          />
          {{ $t('interest_button_interested') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import YesClickAnimation from '@/components/SwapList/Gratification/YesClickAnimation';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import events from '@/utils/helpers/events';

export default {
  name: 'SwapListInterestButtonsFreemium',

  components: {
    YesClickAnimation
  },

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    },
    reverseIsShown: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      SWAP_FILTER_TYPES,
      tutHasMarkedInterest: false,
      shouldAnimateYesButton: false
    };
  },

  computed: {
    ...mapGetters({
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      tutInterestMarks: 'tutorial/tutInterestMarks',
      noInterestMarksLeft: 'interests/noInterestMarksLeft',
      swaps: 'swapList/swaps',
      swapFilterType: 'swapList/swapFilterType',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },
    isTutorialFirstLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        this.tutInterestMarks === 1
      );
    },

    newPremiumPaywallABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  methods: {
    ...mapActions({
      markInterest: 'interests/markInterest',
      setGlobalModal: 'ui/setGlobalModal',
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks',
      unmarkFavourite: 'favourites/unmarkFavourite'
    }),

    handleNoClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }
      this.$emit('no-interest', this.$event);

      if (
        this.swapFilterType === SWAP_FILTER_TYPES.REMOVED &&
        this.interested &&
        !this.newPremiumPaywallABTest
      ) {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: false,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      }

      this.markInterest({
        targetPropositionId: this.targetPropositionId,
        skipRequest: true,
        interested: false,
        where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
      });
      if (this.swapFilterType === SWAP_FILTER_TYPES.FAVOURITES) {
        this.unmarkFavourite({ propositionId: this.targetPropositionId });
      }
    },

    handleYesClick() {
      if (
        this.primaryPropositionIsLimited &&
        this.swapFilterType === SWAP_FILTER_TYPES.REMOVED &&
        this.newPremiumPaywallABTest
      ) {
        this.setGlobalModal('ReverseInterestActionPaywall');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'reverse-no-interest-action',
          src: 'interest-mark'
        });
        return;
      }

      this.shouldAnimateYesButton = true;

      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (this.interested) {
        if (this.primaryPropositionIsLimited && this.newPremiumPaywallABTest) {
          this.setGlobalModal('ReverseInterestActionPaywall');
          events.emitEvent(events.eventTypes.SEE, 'Paywall', {
            trigger: 'reverse-no-interest-action',
            src: 'interest-mark'
          });
          this.shouldAnimateYesButton = false;

          return;
        }

        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: null,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      } else {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: true,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes background-fill {
  0% {
    background-position: -100% 0;
  }
  33%,
  100% {
    background-position: 0 0;
  }
}

.interest-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  // margin-top: 10px;
}

.buttons-wrapper {
  display: flex;

  .interest-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    font-size: 0.75rem;
    font-weight: 600;
    // text-transform: uppercase;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    border-radius: 3px;
    color: $text-primary;

    &.reverse-is-shown {
      pointer-events: none;
    }

    .button-content {
      display: flex;
      position: relative;
      align-items: center;
    }
  }

  .background-fill-rl {
    background-size: 200% 100%;
    background-image: linear-gradient(to left, white 50%, $transfer-green 50%);
    color: white;
    animation: background-fill 2.5s ease forwards;
  }

  .button-icon {
    margin-right: 3px;
  }

  .not-interested {
    border-radius: 3px 0px 0px 3px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: none;
  }

  .interested {
    border-radius: 0px 3px 3px 0px;
    border: 1px solid #e8e8e8;
  }

  .is-interested {
    background-image: linear-gradient(
      to left,
      $transfer-green 50%,
      $transfer-green 50%
    );
    border: 1px solid $transfer-green;
    color: white;
  }

  .is-not-interested {
    background-color: $sweet-red;
    border-color: $sweet-red;
    color: white;
  }
}

.blur-content {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}
</style>
