<template>
  <div class="interest-buttons">
    <div class="buttons-wrapper">
      <div
        :class="[
          'interest-button not-interested',
          { 'is-not-interested': interested === false }
        ]"
        @click="$emit('interested', false)"
      >
        {{ $t('interest_button_hide') }}
      </div>
      <div
        :class="[
          'interest-button interested',
          { 'is-interested': interested === true }
        ]"
        @click="$emit('interested', true)"
      >
        <BaseIcon
          :icon-file="interested ? 'icon-check3-white' : 'icon-check3'"
          :width="14"
          :height="14"
          class="button-icon"
        />
        {{ $t('interest_button_interested') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInterestButtonsFreemium',

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.interest-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  // margin-top: 10px;
}

.buttons-wrapper {
  display: flex;
}

.interest-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  font-size: 0.75rem;
  font-weight: 600;
  // text-transform: uppercase;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  border-radius: 3px;
  color: $text-primary;
}

.button-icon {
  margin-right: 3px;
}

.not-interested {
  border-radius: 3px 0px 0px 3px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-right: none;
}

.interested {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #e8e8e8;
}

.hide-icon {
  margin-right: 4px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.is-interested {
  background-color: $transfer-green;
  border: 1px solid $transfer-green;
  color: white;
}

.is-not-interested {
  color: $sweet-red;
}
</style>
