<template>
  <article v-if="ad" class="wrapper" :class="[{ toned: toned }, mode]">
    <section class="image" :class="mode">
      <div class="image-extra-wrapper" :class="mode">
        <section
          v-if="index === 0 && mode.endsWith('mobile')"
          class="extra"
          :class="mode"
        >
          <MLike
            :item="item"
            @click="$emit('like', $event)"
            @done="$emit('likeDone', $event)"
          ></MLike>
          <div class="no-icon-spacing" :class="mode"></div>

          <MSearchListItemAdExtra
            :ad="ad"
            :item="item"
            :mode="mode"
          ></MSearchListItemAdExtra>
        </section>

        <MListImage
          :url="image"
          :mode="mode"
          :position="adPosition"
          :class="mode"
          :is-placeholder-img="!ad.image"
        />
      </div>
    </section>

    <section class="wrapper-content" :class="mode">
      <section class="list-content" :class="mode">
        <header class="col-2" :class="mode">
          <div>
            <h3 class="title" :class="mode">
              {{ ad.streetAddress }}
              <BaseIcon
                v-if="item.verified"
                icon="verified"
                :width="14"
                :height="14"
                class="verified-badge"
              />
            </h3>
            <p class="text-light" :class="mode">
              {{ ad.locality }}
            </p>
          </div>
          <p v-if="mode === 'list-mobile'" class="text-light">
            {{ item.propositionNo }}
          </p>

          <MSearchListItemAdExtra
            v-if="mode.endsWith('list-desktop')"
            :ad="ad"
            :item="item"
            :mode="mode"
          ></MSearchListItemAdExtra>
        </header>

        <div class="full-details-wrapper" :class="mode">
          <div class="full-details" :class="mode">
            <div
              class="details"
              :class="mode"
              :style="{ order: isCardMode ? '1' : '2' }"
            >
              <div class="col list-item" :class="mode">
                <strong>{{ ad.rooms }}</strong>
                {{ $tc('general_rooms', Number(ad.rooms)) }}
                <span><ADotSeparator /></span>
              </div>

              <div class="col list-item" :class="mode">
                <strong>{{ ad.sqm }}</strong> m<sup>2</sup>
                <span v-if="mode.endsWith('desktop')"><ADotSeparator /></span>
              </div>

              <div
                v-if="mode.endsWith('desktop')"
                class="col list-item"
                :class="mode"
              >
                <span>{{ $t('general_floor_short') }} </span>
                <strong>{{ floorReadable }}</strong>
              </div>
            </div>

            <div
              class="col"
              :class="mode"
              :style="{
                order: isCardMode ? '4' : '1'
              }"
            >
              <strong>{{ ad.rent | formatCurrency(false) }}</strong>
              &nbsp;{{ $t('general_currency_per_month_short') }}
            </div>
          </div>
        </div>

        <div v-if="mode === 'card-desktop'" class="extra-row" :class="mode">
          <MSearchListItemAdExtra
            v-if="mode.endsWith('card-desktop')"
            :ad="ad"
            :item="item"
            :mode="mode"
          ></MSearchListItemAdExtra>
          <MLike
            v-if="mode === 'card-desktop'"
            :item="item"
            @click="$emit('like', $event)"
          ></MLike>
        </div>
      </section>

      <section v-if="index === 0 && !isCardMode" class="extra" :class="mode">
        <MLike :item="item" @click="$emit('like', $event)"></MLike>
        <div class="no-icon-spacing" :class="mode"></div>
      </section>
    </section>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatCurrency } from '@/filters/currency';

import MListImage from '@/components/atomic/molecules/m-list-image';
import MLike from '@/components/atomic/molecules/m-like';
import MSearchListItemAdExtra from '@/components/atomic/molecules/m-search-list-item-ad-extra';
import ADotSeparator from '../atoms/a-dot-separator';

export default {
  name: 'MSearchListItemAd',

  components: {
    MListImage,
    ADotSeparator,
    MLike,
    MSearchListItemAdExtra
  },

  filters: {
    formatCurrency
  },

  props: {
    ad: {
      type: Object,
      default: null
    },

    item: {
      type: Object,
      default: null
    },

    toned: {
      type: Boolean,
      default: false
    },

    index: {
      type: Number,
      default: 0
    },

    mode: {
      type: String,
      default: 'list-desktop'
      // Modes: list-desktop, card-desktop, list-mobile, card-mobile,
    }
  },

  data() {
    return {};
  },
  computed: {
    isListMode() {
      return this.mode.includes('list-');
    },

    isCardMode() {
      return this.mode.includes('mobile') || this.mode == 'card-desktop';
    },

    adPosition() {
      if (Object.keys(this.item.residences).length === 1) return null;
      else if (this.index === 0) return 'upper';
      else return 'lower';
    },

    image() {
      if (!this.ad.image) return null;

      const imageUrl = `${this.$t(
        'url_residences',
        this.$routeLocale
      )}/800x800/${this.item.propositionId}/${this.ad.image}`;

      return imageUrl;
    },

    floorReadable() {
      if (this.ad.floor === 0)
        return this.$t('general_ground_floor_short', this.$routeLocale);
      if (this.ad.floor === 999)
        return this.$t('general_attic_short', this.$routeLocale);

      return this.ad.floor;
    },

    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite'
    })
  }
};
</script>

<style lang="scss" scoped>
.col-2 {
  flex-grow: 2;
}

.col-2.list-desktop {
  flex-grow: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toned {
  background-color: #f8f8fa;
}

section {
  margin: 0;
  padding: 0;
}

.wrapper-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.wrapper-content.card-desktop {
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.wrapper-content.card-mobile {
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: stretch;

  height: 100%;
  position: relative;

  padding: 15px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 12px;
  position: relative;
}

.wrapper.card-desktop,
.wrapper.card-mobile {
  font-size: 14px;
}

.wrapper.card-mobile {
  flex-direction: column;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 13px;
  font-weight: 700;
  color: $brand-dark;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.verified-badge {
  margin-left: 4px;
}

.title.list-desktop {
  margin-bottom: 0;
}

.title.card-desktop {
  font-size: 16px;
}

.title.card-mobile {
  font-size: 16px;
}

.text-light {
  font-style: normal;
  font-weight: 600;
  color: $brand-light;
  font-size: 12px;
}

.text-light.lighter {
  font-style: normal;
  font-weight: 400;
  color: $brand-light;
  opacity: 0.5;
}

.mgr-7 {
  margin-right: 7px;
}

.image-extra-wrapper.card-desktop {
  display: flex;
  flex-direction: column-reverse;
}

.extra {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.extra-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.no-icon-spacing.col.list-desktop {
  width: 24px;
}

.extra.list-mobile {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 10px;
}

.extra.card-desktop,
.extra.list-mobile {
  min-width: 70px;
}

.extra.card-desktop {
  position: absolute;
  z-index: 1;
  right: 0;
  padding: 10px 10px 0px 5px;
  width: 50%;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.extra.card-mobile {
  width: 100%;
  position: absolute;
  top: 0;
  padding: 10px;
  z-index: 1;
}

.extra.card-desktop,
.extra.card-mobile {
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.extra.list-desktop {
  justify-content: center;
}

.list-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.list-content.list-desktop {
  display: grid;
  grid-template-columns: 170px 1fr;
}

.list-content.card-mobile,
.list-content.list-mobile,
.list-content.card-desktop {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.list-content.list-mobile {
  max-width: 200px;
}

.list-content.card-mobile {
  flex-direction: row;
  align-content: flex-end;
}

.list-item.card-mobile,
.list-item.list-mobile,
.list-item.card-desktop {
  display: inline-block;
}

.mgt-2.card-mobile,
.mgt-2.list-mobile,
.mgt-2.card-desktop {
  margin-top: 2px;
}

.mgt-10.card-mobile,
.mgt-10.list-mobile .mgt-10.card-desktop {
  margin-top: 10px;
}

.details.list-desktop {
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.details.card-desktop {
  margin-bottom: 4px;
}

.image.list-mobile {
  height: 100px;
  width: 100px;
}

.image.list-desktop {
  height: 80px;
  width: 80px;
}

.image.card-desktop {
  height: 170px;
  width: 100%;
}

.image.card-mobile {
  height: 200px;
  width: 100%;
}

.floor.list-mobile,
.floor.card-mobile {
  display: none;
}

.full-details.card-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.full-details > * {
  margin-top: 2px;
}

.full-details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.full-details-wrapper.card-desktop {
  align-items: flex-start;
}

.full-details-wrapper.list-desktop {
  align-items: center;
  padding-left: 10px;
  font-size: 13px;
}

.full-details-wrapper.list-desktop,
.full-details-wrapper.list-mobile,
.full-details-wrapper.card-desktop {
  flex-grow: 3;
}

.full-details-wrapper.list-mobile {
  flex-direction: column;
  justify-content: flex-end;
}

p {
  margin: 0;
}

.mgr-20 {
  margin-right: 20px;
}

.mgr-10 {
  margin-right: 10px;
}
</style>
