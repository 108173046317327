<template>
  <div :class="['card', { isSelected }]">
    <div class="body">
      <div
        class="photos"
        :class="{
          'one-photo': match.propositions.length === 2,
          'two-photos': match.propositions.length === 3
        }"
      >
        <BaseImage
          v-if="match.propositions.length === 3"
          :key="match.propositions.propositionId"
          :url="match.propositions[1].image"
          :is-placeholder-small="true"
        />
        <BaseImage
          :key="match.propositions[0].propositionId"
          :url="match.propositions[0].image"
          :is-placeholder-small="true"
        />
      </div>

      <div class="adress-and-message-container">
        <div
          :class="[
            'address',
            { 'card-has-badge': match.unreadCount > 0 || match.isNew }
          ]"
        >
          <div class="part-b">{{ match.propositions[0].streetAddress }}</div>
          <div v-if="match.propositions.length === 3" class="part-c">
            & {{ match.propositions[1].streetAddress }}
          </div>
        </div>

        <div
          v-if="match.lastMessage"
          :class="[
            'last-message',
            { 'last-message--inactive-card': swapIsInactive }
          ]"
        >
          <template v-if="match.lastMessage.senderName === 'SYSTEM'">
            <div class="name-and-date">
              {{ JSON.parse(match.lastMessage.message).displayName }} –
              {{ customDate(match.lastMessage.sentAt) }}
            </div>
            <div
              class="message message-system"
              v-html="
                truncateText(
                  convertSystemChatMessageWithoutAddress(
                    match.lastMessage.message
                  ),
                  40
                )
              "
            ></div>
          </template>
          <template v-else>
            <div class="name-and-date">
              {{ match.lastMessage.senderName }} –
              {{ customDate(match.lastMessage.sentAt) }}
            </div>
            <div
              class="message"
              v-html="
                match.lastMessage.message
                  ? truncateText(match.lastMessage.message, 40)
                  : ''
              "
            ></div>
          </template>
        </div>
        <div v-else class="last-message">
          <div class="name-and-date">
            {{ customDate(match.createdAt) }}
          </div>
          <div class="message">{{ $t('matches_card_new_match_msg') }}</div>
        </div>
      </div>

      <div v-if="match.unreadCount > 0" class="new-badge">
        <BaseIcon icon-file="unread-mail" :width="12" :height="12" />
        {{ match.unreadCount }}
      </div>

      <div v-else-if="match.isNew" class="new-badge new-badge-match">
        <div class="dot"></div>
        {{ $t('matches_card_new_badge') }}
      </div>

      <div
        v-if="!match.chatRoom.isFreemiumLocked"
        class="chat-badge chat-started-badge"
      >
        {{ $t('matches_card_conversation_started') }}
      </div>
    </div>

    <div class="footer">
      <!-- <div class="countdown">
        <BaseIcon icon-file="watch" :width="12" :height="12" class="icon" />
        5d 11h
      </div> -->

      <RouterLink
        v-if="partBPropositionActive"
        :to="{
          query: {
            propositionPageId: partBPropositionId
          }
        }"
        class="swap-link"
        @click.native.stop
        >{{ $t('matches_card_show_prop') }}
        <BaseIcon
          :icon-file="'chevron-right-grey'"
          :width="12"
          :height="12"
          class="icon"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { truncateText } from '@/utils/helpers/string.js';
import { todayYesterdayDate } from '@/utils/helpers/date.js';
import { convertSystemChatMessageWithoutAddress } from '@/utils/systemMessageConverter';
import { mapGetters } from 'vuex';

export default {
  name: 'MatchCard',

  props: {
    match: {
      type: Object,
      required: true
    },
    selectedMatch: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      activeTab: 'matches/activeTab'
    }),

    partBPropositionId() {
      return this.match.propositions[0].propositionId;
    },

    partBPropositionActive() {
      return this.match.propositions[0].inactivatedAt === null;
    },

    isSelected() {
      return this.selectedMatch?.id === this.match?.id;
    },

    swapIsInactive() {
      let isInactivated = false;
      if (this.match.lastMessage.senderName === 'SYSTEM') {
        const parsedContent = JSON.parse(this.match.lastMessage.message);
        isInactivated = parsedContent.type === 'INTEREST_LEVEL_3';
      }

      return this.activeTab === 'inactive' || isInactivated;
    }
  },

  methods: {
    customDate: todayYesterdayDate,
    truncateText,
    convertSystemChatMessageWithoutAddress
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  padding: 10px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  margin-bottom: 16px;
  user-select: none;
  cursor: pointer;

  @media ($desktop) {
    margin-bottom: 24px;
    padding: 12px;
    padding-bottom: 10px;
  }

  &.isSelected {
    border-color: #5c94f9;
  }
}

.body {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.photos {
  position: relative;
  margin: 3px;
  margin-left: 1px;
  margin-top: 10px;
  aspect-ratio: 1/1;
}

.photos.one-photo {
  margin-top: 6px;
}

.photos img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}

.photos.two-photos img:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.photos.two-photos img:last-child {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 85%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.photos.one-photo img {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.adress-and-message-container {
  margin-left: 6px;
}

.address {
  margin-bottom: 14px;

  &.card-has-badge {
    padding-right: 70px;
  }

  .part-b {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px;

    @media ($large-screen) {
      font-size: 18px;
    }
  }

  .part-c {
    font-size: 12px;
    color: #878b9d;
    font-weight: 700;

    @media ($large-screen) {
      font-size: 14px;
    }
  }
}

.last-message {
  border-radius: 8px;
  background: #f0f5fa;
  padding: 9px 10px;

  .name-and-date {
    color: #2c3e50;
    opacity: 0.75;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .message {
    color: #2c3e50;
    font-size: 12px;
    line-height: 1;
  }

  .message-system:first-letter {
    text-transform: capitalize;
  }
}

.last-message--inactive-card {
  background-color: #fce4e4;
}

.new-badge,
.chat-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  padding: 3px 10px 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background-color: #ecfdf3;
  font-size: 12px;
  font-weight: 600;
  color: #027a48;

  &.chat-badge {
    bottom: 10px;
    top: auto;
    right: auto;
    left: 10px;
  }

  @media ($desktop) {
    top: 12px;
    right: 12px;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #12b76a;
  }
}

.new-badge-match,
.chat-started-badge {
  color: #026aa2;
  background-color: #f0f9ff;
  font-size: 11px;
  padding: 4px 10px 4px 8px;

  .dot {
    background-color: #0ba5ec;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebebeb;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 0;
}

.countdown {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  color: #344054;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 16px;
  background-color: #f0f5fa;

  .icon {
    margin-right: 4px;
  }
}

.swap-link {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  padding: 3px 10px;
  color: #344054;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 16px;
  background-color: #f0f5fa;

  &:hover,
  &:focus {
    background-color: #e2e8ee;
  }

  .icon {
    margin-left: 2px;
    margin-right: -4px;
  }
}
</style>
