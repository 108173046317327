<template>
  <div class="current-subscription">
    <div class="proposition-image block"></div>
    <div class="subscription-details">
      <div class="details-wrapper">
        <h2 class="details-title block">blocktext</h2>
        <div class="subscription-info">
          <span class="block"
            >blocktext blocktext blocktext blocktext blocktext blocktext
            blocktext blocktext.</span
          >
          <br />
          <span class="block">blocktext blocktext</span>
        </div>
      </div>

      <div class="button-wrapper">
        <secondary-button class="block" :style="buttonStyle"
          >Block</secondary-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryButton from '../../Buttons/Secondary';

export default {
  name: 'CurrentSubscriptionLoader',

  components: {
    SecondaryButton
  },

  data() {
    return {
      buttonStyle: {
        borderRadius: '3px',
        color: 'black',
        textTransform: 'uppercase',
        fontWeight: 600,
        marginTop: '20px'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@keyframes load {
  from {
    opacity: 0.6;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 0.6;
  }
}

.current-subscription {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  animation: 1.5s load infinite;

  @media ($desktop) {
    border: 1px solid rgba(0, 0, 0, 0.17);
  }

  @media ($mobile) {
    flex-direction: column;
    width: 100%;
  }
}

.details-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 5px;
}

.proposition-image {
  height: 170px;
  width: 300px;
  background-position: center;
  background-size: contain;
  background-color: #f7f7f9;
  background-image: url('../../../assets/svg/placeholderImage/placeholder-image-large.svg');
  opacity: 0.7;
  border-radius: 5px;

  @media ($mobile) {
    width: 100%;
    height: 25vh;
    margin-bottom: 14px;
  }

  @media ($desktop) {
    margin-right: 12px;
  }
}

.subscription-details {
  display: flex;
  height: 170px;
  flex-direction: column;
  justify-content: space-between;
}

.subscription-info {
  font-weight: 600;
  font-size: 0.9rem;

  @media ($desktop) {
    line-height: 24px;
  }
}

.block {
  display: block;
  position: relative;

  &::after {
    background-color: #8e8d8f;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50;
  }
}
</style>
