import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const OVERLAY_TYPES = {
  BLUR: 'blur',
  GRADIENT: 'gradient'
};

const state = {
  overlay: { show: false, type: OVERLAY_TYPES.BLUR }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
