function showOverlay(state) {
  return state.overlay.show;
}

function overlayState(state) {
  return state.overlay;
}

export default {
  showOverlay,
  overlayState
};
