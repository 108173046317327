<template>
  <div>
    <StandardModal
      v-if="filterModalOpen && isMobile"
      standard-modal-name="FilterMapModal"
      :title="$t('map_container_filter')"
      :close-function="closeFilterModal"
      :action-function="resetFilters"
      :action-title="$t('map_container_clear')"
      :with-border="true"
    >
      <Filters slot="content" :close-filter-modal="closeFilterModal" />
    </StandardModal>

    <div class="map-container">
      <div
        v-if="!isMobile"
        class="close-btn"
        @click="() => setSwapListType({ type: SWAP_LIST_TYPES.LIST })"
      >
        <div class="close-btn-icon" />
        <div class="close-btn-label">{{ $t('map_container_close_map') }}</div>
      </div>
      <!-- mobile filter -->
      <div v-if="isMobile" class="filter-action">
        <BaseButton
          class="filter-button-floating rounded filter-button"
          icon-file="filter-slider-black"
          :icon-size="22"
          @click="handleShowFilterModal($event)"
        >
          {{ $t('map_container_filter') }} {{ filterCountButton }}</BaseButton
        >
      </div>

      <SwapMap :proposition-id="primaryPropositionId" :scroll-enabled="true" />

      <MapSwapItem
        v-if="activeSwap"
        :key="`${activeSwap.propositionId}${activeSwap.details.type}`"
        :swap="activeSwap"
        :position="position"
        :show-swap-details="handleShowSwapDetails"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import throttle from 'lodash/throttle';
import { SWAP_LIST_TYPES } from '@/store/modules/swapList';
import SwapMap from '../Maps/SwapMap';
import MapSwapItem from './MapSwapItem';
import StandardModal from '@/components/Modals/Standard';
import Filters from './Filter/Filters';
import BaseButton from '@/components/BaseButton';
export default {
  name: 'MapContainer',

  components: {
    SwapMap,
    MapSwapItem,
    Filters,
    StandardModal,
    BaseButton
  },

  data() {
    return {
      SWAP_LIST_TYPES,
      filterModalOpen: false,
      autoScrolling: false,
      manualScrolling: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      mapSwapDetails: 'swapMap/swapDetails',
      filterCount: 'swapList/filterCount',
      activeSwap: 'swapMap/activeSwap',
      position: 'swapMap/position',
      swapView: 'swapList/swapView',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    filterCountButton() {
      return this.filterCount > 0 ? `(${this.filterCount})` : '';
    }
  },
  watch: {
    mapSwapDetails(newDetails, oldDetails) {
      let isSame = true;
      if (!oldDetails || !newDetails) {
        isSame = false;
      } else {
        newDetails.forEach((swap, index) => {
          if (!oldDetails[index]) {
            isSame = false;
            return;
          } else {
            if (swap.propositionId !== oldDetails[index].propositionId) {
              isSame = false;
              return;
            }
          }
        });
      }
      if (isSame) {
        return;
      }
      this.setActiveSwap({ propositionId: null });
    }
  },
  mounted() {
    if (this.isMobile) {
      this.hideMobileTabBar();

      this.handleScroll = throttle(this.checkScroll, 50);
    }
  },

  beforeDestroy() {
    if (this.isMobile) {
      this.showMobileTabBar();
    }
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      closeSwapDetails: 'swapList/closeSwapDetails',
      setActiveSwap: 'swapMap/setActiveSwap',
      setSwapListType: 'swapList/setSwapListType',
      resetFilters: 'swapList/resetFilters',
      hideMobileTabBar: 'menu/hideMobileTabBar',
      showMobileTabBar: 'menu/showMobileTabBar',
      setGlobalModal: 'ui/setGlobalModal'
    }),
    checkScroll(e) {
      if (this.autoScrolling || !this.isMobile) return;
      const { target } = e;

      const { scrollLeft, clientWidth: widthPerImage } = target;

      if (!scrollLeft || !widthPerImage) return;

      const swapDisplayed = Math.round(scrollLeft / widthPerImage);
      if (
        this.mapSwapDetails &&
        this.mapSwapDetails[swapDisplayed] &&
        this.activeSwap === this.mapSwapDetails[swapDisplayed].propositionId
      )
        return;

      if (this.mapSwapDetails && this.mapSwapDetails[swapDisplayed]) {
        this.setActiveSwap({
          propositionId: this.mapSwapDetails[swapDisplayed].propositionId
        });
      }

      this.manualScrolling = true;
      setTimeout(() => (this.manualScrolling = false), 50);
    },
    handleShowSwapDetails({ propositionId, type }) {
      this.fetchSwapDetails({ propositionId, type });
    },
    handleShowFilterModal() {
      this.setGlobalModal('FilterModal');
    },
    closeFilterModal() {
      this.filterModalOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  border-radius: 5px;
  position: relative;

  .filter-button-floating {
    &.button {
      max-width: 170px;
      border: 1px solid var(--RC-Text-1-0B182C, #0b182c);

      box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
        0 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
  }

  @media ($mobile) {
    position: fixed;
    top: 60px;
    left: 0;
    height: 100vh;
    height: calc(100vh - 60px);
    width: 100vw;
  }

  @media ($desktop) {
    height: calc(100vh - #{$desktop-menu-height} - 1px);
    width: calc(100vw - 336px);
  }
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  padding: 8px;
  font-size: 0.8rem;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  box-shadow: $standard-shadow;
  z-index: 2;
  align-items: center;

  @media ($mobile) {
    top: 10px;
    left: 120px;
  }

  &-icon {
    height: 18px;
    width: 18px;
    margin-top: -4px;
    margin-bottom: -4px;
    margin-left: -2px;
    margin-right: 3px;
    background-position: center;
    background-size: contain;
    background-image: url('../../assets/svg/icon-close-black.svg');
  }

  &-label {
    font-weight: 600;
    font-size: 0.8rem;
  }
}

.desktop-swap-wrapper {
  position: absolute;
  bottom: 20px;
  left: 5%;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}

.swap {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.filter-action {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 2;
}
</style>
