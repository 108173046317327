var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['tab-bar__wrapper', _vm.stickyTabBar ? '' : 'is-not-sticky']},[_c('router-link',{staticClass:"tab-bar__item",attrs:{"to":_vm.$routes.SWAP_LIST},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":isActive ? 'tabbar-swap-sug-active' : 'tabbar-swap-sug'}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_swap_suggestions'))+" "),_c('BadgeCount',{staticClass:"absolute",attrs:{"notifications":_vm.notifications && _vm.notifications.newSwaps}})],1)]}}])}),(_vm.matchesViewABTest)?_c('router-link',{staticClass:"tab-bar__item",attrs:{"to":_vm.$routes.MATCHES},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var isActive = ref.isActive;
return [_c('div',{staticClass:"router-link-container"},[(_vm.showFullSwapNotification)?_c('FullSwapNotification',{on:{"onAnimationEnd":_vm.handleNotificationAnimEnd}}):_vm._e(),_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":isActive ? 'tabbar-swaps-active' : 'tabbar-swaps'}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_matches'))+" "),_c('BadgeCount',{staticClass:"absolute",attrs:{"notifications":_vm.notifications && _vm.notifications.unreadAndUpdatedMatches}})],1)],1)]}}],null,false,1299006791)}):_c('router-link',{staticClass:"tab-bar__item",attrs:{"to":_vm.$routes.SWAPS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":isActive ? 'tabbar-swaps-active' : 'tabbar-swaps'}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_swaps'))+" "),_c('BadgeCount',{staticClass:"absolute",attrs:{"notifications":_vm.swapInterestCountsUnseenTotal}}),_c('transition',{attrs:{"name":"plus-one"}},[(_vm.showSwapAddedBadge)?_c('div',{staticClass:"swap-added-icon"},[_vm._v("+1")]):_vm._e()])],1)]}}])}),(!_vm.primaryPropositionIsPremiumOrFree)?_c('router-link',{staticClass:"tab-bar__item premium",attrs:{"to":_vm.$routes.PAYMENT_PROPOSITION + '/' + _vm.primaryPropositionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":isActive ? 'premium-active' : 'premium',"width":27,"height":27}}),_vm._v(" Premium ")],1)]}}],null,false,3174790164)}):_vm._e(),(
      _vm.primaryPropositionIsPremiumOrFree &&
      _vm.$country.isFeatureEnabled('PAYMENT')
    )?_c('span',{staticClass:"tab-bar__item premium",on:{"click":_vm.handleBoostClick}},[_c('BaseIcon',{class:['tab-bar__item__icon', { 'has-boost': _vm.hasBoost }],attrs:{"icon-file":_vm.hasBoost ? 'has-boost' : 'boost',"width":27,"height":27}}),_vm._v(" Boost ")],1):_vm._e(),(_vm.matchesViewABTest || !_vm.$country.isFeatureEnabled('PAYMENT'))?_c('router-link',{staticClass:"tab-bar__item",attrs:{"to":_vm.$routes.MY_PROPOSITIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var route = ref.route;
    var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon":isActive ? 'my-prop-active' : 'my-prop'}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_my_ads'))+" ")],1)]}}],null,false,4021069882)}):_vm._e(),(!_vm.matchesViewABTest)?_c('router-link',{staticClass:"tab-bar__item",attrs:{"to":_vm.$routes.MESSAGES},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var route = ref.route;
    var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":route},nativeOn:{"click":function($event){return _vm.itemClick(isActive)}}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":isActive ? 'tabbar-chat-active' : 'tabbar-chat'}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_messages'))+" "),_c('BadgeCount',{staticClass:"absolute",attrs:{"notifications":_vm.unreadChatMessages}})],1)]}}],null,false,3876162601)}):_vm._e(),_c('div',{staticClass:"tab-bar__item",on:{"click":_vm.toggleMenu}},[_c('BaseIcon',{staticClass:"tab-bar__item__icon",attrs:{"icon-file":"tabbar-hamburger"}}),_vm._v(" "+_vm._s(_vm.$t('tab_bar_more'))+" "),(_vm.matchesViewABTest && _vm.notifications.unreadMessages > 0)?_c('NotificationDot',{staticClass:"notification-dot"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }