<template>
  <transition name="overlay" appear>
    <div
      :class="['dark-overlay', 'overlay-variant-' + overlayState.type]"
      @click.self="handleOverlayClick"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Overlay',

  computed: {
    ...mapGetters({
      currentModal: 'ui/currentModal',
      overlayState: 'overlay/overlayState'
    })
  },

  methods: {
    ...mapActions({
      closeMenu: 'menu/closeMenu',
      closeCancelModal: 'handleSubscription/closeCancelModal'
    }),

    handleOverlayClick() {
      this.closeMenu();

      // Global event for modals and such
      this.$root.$emit('dark-overlay-closing');
      // Global event for nested standard modals
      this.$root.$emit(this.currentModal);
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-variant-blur {
  background: rgba(16, 24, 40, 0.4);
  backdrop-filter: blur(8px);
}

.overlay-variant-gradient {
  background: linear-gradient(167deg, rgba(64, 159, 255, 0) 0%, #409fff 30%);

  @media ($desktop) {
    background: linear-gradient(
      167deg,
      rgba(64, 159, 255, 0) 0%,
      #409fff 92.1%
    );
  }
}

.dark-overlay {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;

  &--modal {
    z-index: $modal-z-index;
  }
}

.overlay-enter-active,
.overlay-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.overlay-enter,
.overlay-leave-active {
  opacity: 0;
}
</style>
