<template>
  <div class="cancel-step-wrapper">
    <spinner v-if="cancelAccountLoading" />
    <h2 class="title larger">{{ $t('confirmation_cancel_account_title') }}</h2>

    <p class="subtitle">{{ $t('confirmation_cancel_account_subtitle') }}</p>
  </div>
</template>

<script>
import Spinner from '../../Loading/Spinner';

export default {
  name: 'ConfirmationCancelAccount',

  components: {
    Spinner
  },

  props: {
    cancelAccountLoading: {
      type: Boolean,
      required: true
    },
    isDeleteAccount: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 800px;
  margin: 0 auto;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.larger {
  font-size: 1.4rem;

  @media ($desktop) {
    font-size: 2rem;
  }
}

.subtitle {
  color: $text-secondary;
  font-weight: 600;
  max-width: 490px;
  text-align: center;

  font-size: 1rem;
  line-height: 24px;
}
</style>
