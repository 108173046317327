var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap-tab-navigation-wrapper"},[_c('div',{staticClass:"swap-tab-nav"},[_c('TabNavigation',{attrs:{"bg-color":_vm.tabBgColor}},[_c('div',{staticClass:"swap-tab-item-wrapper"},[_c('TabNavigationItem',{attrs:{"count":_vm.unansweredSwaps,"active":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED,"show-count":_vm.unansweredSwaps !== 0,"should-animate":_vm.animateUnAnsweredTab,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return (function () {
              _vm.resetFilters({ onPageLoad: true });

              _vm.setSwapType({
                swapFilterType: _vm.SWAP_FILTER_TYPES.UNANSWERED,
                update: true
              });
            }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('swap_list_tabs_unanswered'))+" ")])],1),_c('div',{class:['swap-tab-item-wrapper', { disabled: _vm.answeredSwaps === 0 }]},[_c('TabNavigationItem',{attrs:{"count":_vm.answeredSwaps ? _vm.answeredSwaps : 0,"active":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ANSWERED,"show-count":_vm.answeredSwaps !== 0,"should-animate":_vm.animateAnsweredTab,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return (function () {
              if (_vm.answeredSwaps === 0) {
                return;
              }
              // preventing modals in DK and if user has premium
              if (!_vm.$country.isCountry('dk') && _vm.primaryPropositionIsLimited) {
                _vm.trackAnsweredTabClicks();
              }
              _vm.resetFilters({ onPageLoad: true });

              _vm.setSwapType({
                swapFilterType: _vm.SWAP_FILTER_TYPES.ANSWERED,
                update: true
              });
            }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('swap_list_tabs_answered'))+" ")])],1),_c('div',{class:['swap-tab-item-wrapper', { disabled: _vm.removedSwaps === 0 }]},[_c('TabNavigationItem',{attrs:{"count":_vm.removedSwaps ? _vm.removedSwaps : 0,"active":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.REMOVED,"show-count":_vm.removedSwaps !== 0,"should-animate":_vm.animateRemovedTab,"bg-color":_vm.tabBgColor},nativeOn:{"click":function($event){return (function () {
              if (_vm.removedSwaps === 0) {
                return;
              }
              _vm.resetFilters({ onPageLoad: true });

              _vm.setSwapType({
                swapFilterType: _vm.SWAP_FILTER_TYPES.REMOVED,
                update: true
              });
            }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('swap_list_tabs_removed'))+" ")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }