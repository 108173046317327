<template>
  <div ref="wrapper" class="wrapper">
    <BaseModal
      v-if="showConversionModal"
      :title="$t('proposition_conversion_modal_title')"
      @close="toggleConversionModal"
    >
      <ConversionModalContent />
    </BaseModal>
    <PropositionGalleryCTA v-if="!hasAccess" />

    <component
      :is="imagesWrapperTag"
      ref="slider"
      class="slides-container"
      :to="imageUrl"
      @click="handleImageClick()"
    >
      <!-- last image, carousel back to last -->
      <div class="image-wrapper">
        <div
          v-if="imageCount > 1"
          class="image"
          :style="[
            images[imageCount - 1].fileName
              ? {
                  backgroundImage: `url(${images[imageCount - 1].fileName})`,
                  opacity: 1
                }
              : ''
          ]"
        />
      </div>

      <div
        v-for="(image, index) in images"
        :key="image + Math.random()"
        class="image-wrapper"
      >
        <div
          :style="[
            image.fileName
              ? {
                  backgroundImage: `url(${image.fileName})`,
                  opacity: 1
                }
              : ''
          ]"
          :class="{
            'blurred-background-image': blurImages && index >= 2
          }"
          class="image"
        />
        <div v-if="blurImages && index >= 2" class="overlay"></div>
        <div v-if="blurImages && index >= 2" class="blur-msg-wrapper">
          <div
            class="blur-msg-click-container"
            @click.stop.prevent="goToEditPropositionImages()"
          >
            <BaseIcon icon-file="blur-img-eye" :width="48" :height="46" />

            <div class="blur-msg">
              <p class="unblock-text">
                {{ $t('unlock_blur_msg') }}
              </p>
              <div class="upload-images-text">
                <span
                  ><u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
                </span>
                <span>
                  {{ $t('proposition_gallery_blur_msg') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- first image, carousel back to first -->
      <div class="image-wrapper">
        <div
          v-if="imageCount > 1"
          class="image"
          :style="[
            images[0].fileName
              ? {
                  backgroundImage: `url(${images[0].fileName})`,
                  opacity: 1
                }
              : ''
          ]"
        />
      </div>
    </component>

    <div v-if="imageCount > 1" ref="dotsContainer" class="dots-container">
      <div
        v-for="(image, index) in images"
        :key="image + Math.random()"
        :ref="`dot-${index}`"
        class="dot"
        :style="[
          index === 0 ? { backgroundColor: 'rgba(255, 255, 255, 0.9)' } : ''
        ]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseModal from '@/components/BaseModal';
import ConversionModalContent from '@/components/Proposition/ConversionModalContent';
import PropositionGalleryCTA from '@/components/PropositionGalleryCTA';

export default {
  name: 'TouchSlider',
  components: {
    BaseModal,
    ConversionModalContent,
    PropositionGalleryCTA
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    lockScroll: {
      type: Function,
      required: true
    },
    unlockScroll: {
      type: Function,
      required: true
    },
    residenceIndex: {
      type: Number,
      required: true
    },
    propositionId: {
      type: String,
      required: true
    },
    inModal: {
      type: Boolean,
      default: false
    },
    proposition: {
      type: Object,
      required: true
    },
    blurImages: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageModalOpen: false,
      currentX: 0,
      startTouch: 0,
      initialTouch: 0,
      lastMovePosition: 0,
      showConversionModal: false
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      allowAccess: 'app/allowAccess',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    imageUrl() {
      if (this.hasAccess) {
        const index = this.residenceIndex === 0 ? 1 : 2;
        return `${this.$routes.PROPOSITION_GALLERY}/${this.propositionId}/${index}`;
      }

      return '';
    },

    imagesWrapperTag() {
      return this.inModal || !this.hasAccess ? 'div' : 'router-link';
    },

    hasAccess() {
      return this.allowAccess || this.isAuthenticated;
    },

    maxX() {
      const { imageCount } = this;
      const { wrapper } = this.$refs;

      const { clientWidth: wrapperWidth } = wrapper;

      let maxWidth = wrapperWidth * (imageCount + 1);

      return maxWidth;
    },

    imageCount() {
      return this.images.length;
    },

    directionVelocity() {
      // 30% shorter swipes required
      const { initialTouch, lastMovePosition, screenWidth } = this;

      const extraTravel = screenWidth * 0.3;

      return initialTouch - lastMovePosition > 0 ? extraTravel : -extraTravel;
    },

    screenWidth() {
      const { wrapper } = this.$refs;

      const { clientWidth: screenWidth } = wrapper;

      return screenWidth;
    },

    currentActive() {
      const { currentX, directionVelocity, screenWidth } = this;

      let currentActive = Math.round(
        (currentX + directionVelocity) / screenWidth
      );

      return currentActive;
    },

    currentActiveX() {
      const { screenWidth, currentActive } = this;

      return screenWidth * currentActive;
    }
  },

  watch: {
    residenceIndex() {
      this.resetSlide();
    }
  },

  mounted() {
    this.createSlider();
  },

  methods: {
    handleImageClick() {
      if (!this.hasAccess) {
        this.showConversionModal = true;
      }

      if (this.inModal) {
        this.$emit('open-gallery');
      }
    },

    goToClosestImage() {
      const { imageCount } = this;
      if (imageCount <= 1) {
        return;
      }

      const { currentActiveX } = this;

      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;

      if (slider) {
        slider.style.transition = 'all 0.2s ease-in-out';
      }

      if (slider) {
        slider.style.transform = `translateX(${-currentActiveX}px)`;
      }

      this.startTouch = currentActiveX;
      this.currentX = currentActiveX;
      this.unlockScroll();
      this.handleDotSlide();

      this.checkIfShouldCarousel();
    },

    handleDotSlide() {
      const dotsContainer = this.$refs.dotsContainer;
      const { currentActive, screenWidth, imageCount } = this;

      let transitionLength;

      if (currentActive === 0) {
        transitionLength = screenWidth / 2 - imageCount * 11;
      } else if (currentActive === imageCount + 1) {
        transitionLength = screenWidth / 2;
      } else {
        transitionLength = screenWidth / 2 - currentActive * 11;
      }

      if (dotsContainer) {
        dotsContainer.style.transform = `translateX(${transitionLength}px)`;
        dotsContainer.style.transition = `all 0.2s ease-in-out`;
      }
    },

    checkIfShouldCarousel() {
      const { currentActive, imageCount, screenWidth } = this;
      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;

      if (currentActive > imageCount) {
        setTimeout(() => {
          if (slider) slider.style.transition = '';
          if (slider) slider.style.transform = `translateX(${-screenWidth}px)`;
          this.startTouch = screenWidth;
          this.currentX = screenWidth;
        }, 200);
      }

      if (currentActive === 0) {
        setTimeout(() => {
          if (slider) slider.style.transition = '';
          if (slider)
            slider.style.transform = `translateX(${-(
              screenWidth * imageCount
            )}px)`;
          this.startTouch = screenWidth * imageCount;
          this.currentX = screenWidth * imageCount;
        }, 200);
      }
    },

    handleDots() {
      const { imageCount, currentActive } = this;

      let realCurrentActive = currentActive - 1;

      if (currentActive === imageCount + 1) {
        realCurrentActive = 0;
      } else if (currentActive === 0) {
        realCurrentActive = imageCount - 1;
      }

      for (let i = 0; i < imageCount; i++) {
        if (this.$refs[`dot-${i}`] && this.$refs[`dot-${i}`][0]) {
          this.$refs[`dot-${i}`][0].style.backgroundColor =
            'rgba(255, 255, 255, 0.3)';
        }
      }

      if (this.$refs[`dot-${realCurrentActive}`]) {
        if (this.$refs[`dot-${realCurrentActive}`][0]) {
          this.$refs[`dot-${realCurrentActive}`][0].style.backgroundColor =
            'rgba(255, 255, 255, 0.9)';
        }
      }
    },

    handleSlideStart(event) {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      this.lockScroll();
      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;
      if (slider) slider.style.transition = '';
      const startPosition = event.touches[0].screenX;
      this.initialTouch = startPosition;
      this.startTouch = startPosition + this.currentX;
    },

    resetSlide() {
      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;
      const { imageCount, screenWidth } = this;
      slider.style.transition = '';

      if (imageCount <= 1) {
        if (slider) slider.style.transform = `translateX(0px)`;
        this.currentX = 0;
        this.startTouch = 0;
        this.initialTouch = 0;
        this.lastMovePosition = 0;
      } else {
        slider.style.transform = `translateX(${-screenWidth}px)`;
        this.currentX = screenWidth;
        this.startTouch = screenWidth;
        this.initialTouch = screenWidth;
        this.lastMovePosition = screenWidth;
      }

      setTimeout(() => {
        this.handleDotSlide();
      }, 1000);
    },

    handleSlide(event) {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const currentPosition = event.touches[0].screenX;
      const move = this.startTouch - currentPosition;
      this.lastMovePosition = currentPosition;

      if (move <= 0 || move >= this.maxX) return;
      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;

      this.currentX = move;

      this.handleDots();

      if (slider) slider.style.transform = `translateX(${-move}px)`;
    },

    createSlider() {
      const slider =
        this.imagesWrapperTag === 'div'
          ? this.$refs.slider
          : this.$refs.slider.$el;
      const dotsContainer = this.$refs.dotsContainer;

      const {
        handleSlide,
        imageCount,
        goToClosestImage,
        handleSlideStart,
        screenWidth
      } = this;

      if (dotsContainer) {
        dotsContainer.style.transform = `translateX(${screenWidth / 2}px)`;
      }

      if (imageCount <= 1) {
        if (slider) slider.style.transform = `translateX(${0}px)`;
        this.startTouch = 0;
        this.currentX = 0;
      } else {
        if (slider) {
          slider.style.transform = `translateX(${-screenWidth}px)`;
        }

        this.startTouch = screenWidth;
        this.currentX = screenWidth;
      }

      if (slider) slider.addEventListener('touchstart', handleSlideStart);
      if (slider) slider.addEventListener('touchcancel', goToClosestImage);
      if (slider) slider.addEventListener('touchmove', handleSlide);
      if (slider) slider.addEventListener('touchend', goToClosestImage);
    },

    toggleConversionModal() {
      this.showConversionModal = !this.showConversionModal;
    },

    goToEditPropositionImages() {
      this.$router.push({
        path: `${this.$t('path_edit_proposition_photos', this.$routeLocale)}/${
          this.primaryPropositionId
        }/1`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.slides-container {
  height: 100%;
  display: flex;
}

.image {
  position: relative;
  height: 100%;
  min-width: 100vw;
  max-width: 100vw;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  // background-size: contain;
  z-index: 1;
}

.blurred-background-image {
  background-position: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(30px);
  -webkit-filter: blur(30px); /* Safari 6.0 - 9.0 */
  -moz-filter: blur(30px);
  -o-filter: blur(30px);
  -ms-filter: blur(30px);
  background-size: cover;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.dots-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.dot {
  height: 7px;
  width: 7px;
  min-height: 7px;
  min-width: 7px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 2px;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  transition: all 0.2s ease-in-out;

  &.active {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.blur-msg-wrapper {
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .blur-msg-click-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
    margin: 0 40px;
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .blur-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 18px;
    .unblock-text {
      font-weight: 600;
    }
    .upload-images-text {
      color: #fff;
      line-height: 150%;
    }
  }
}

.image-wrapper {
  position: relative;
}
</style>
