import { lbAdmin2Api } from '@/utils/axiosConfig';

function setCurrentScreen(context, value) {
  context.commit('setCurrentScreen', value);
}

function setPreviousScreen(context, value) {
  context.commit('setPreviousScreen', value);
}

function setOptionSelected(context, { option, value }) {
  context.commit('setOptionSelected', { option, value });
}

function setAllowContact(context, value) {
  context.commit('setAllowContact', value);
}

function setBlockProposition(context, value) {
  context.commit('setBlockProposition', value);
}

function setTextareaReportDescription(context, value) {
  context.commit('setTextareaReportDescription', value);
}

function setFormFilled(context, value) {
  context.commit('setFormFilled', value);
}

function resetSelected(context) {
  context.commit('resetSelected');
}

async function sendReportDataToSlack(_context, value) {
  const {
    userId,
    userAdminUrl,
    route,
    locale,
    propositionUrl,
    propositionId,
    saveData
  } = value;
  const { allowContact, reportDescription, takenPathWay } = saveData;
  try {
    await lbAdmin2Api.post('/api/report-proposition', {
      userId,
      userAdminUrl,
      route,
      locale,
      propositionUrl,
      propositionId,
      allowContact,
      reportDescription,
      takenPathWay
    });
  } catch (e) {
    window.Logger.error(e);
  }
}

export default {
  setCurrentScreen,
  setPreviousScreen,
  setOptionSelected,
  setAllowContact,
  setBlockProposition,
  setTextareaReportDescription,
  setFormFilled,
  resetSelected,
  sendReportDataToSlack
};
