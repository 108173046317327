import { render, staticRenderFns } from "./PropositionInterestBox.vue?vue&type=template&id=57d41fce&scoped=true"
import script from "./PropositionInterestBox.vue?vue&type=script&lang=js"
export * from "./PropositionInterestBox.vue?vue&type=script&lang=js"
import style0 from "./PropositionInterestBox.vue?vue&type=style&index=0&id=57d41fce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d41fce",
  null
  
)

export default component.exports